import { useState, useEffect } from 'react'
import useAxiosPrivate from '../useAxiosPrivate'
import axios from 'axios'
import { APP_URL } from '../../constants'

const useGetLanguageList = () => {
  const axiosPrivate = useAxiosPrivate()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const controller = new AbortController()
  let apiParams = {}

  const getLanguageList = async (apiParams) => {
    try {
      const response = await axios.get(APP_URL + '/json/languages.json', apiParams, { signal: controller.signal })
      setData(response.data)
      setLoading(false)
      return response.data
    } catch (err) {
      setError(error)
      setLoading(false)
    } finally {
      controller.abort()
    }
  }

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return { data, loading, error, getLanguageList }
}

export default useGetLanguageList
