import { useParams } from 'react-router-dom'

const Tags = () => {
  const { module } = useParams()
  console.log(module)

  return <h1>Tags</h1>
}

export default Tags
