import {fGetHMFormat2, } from '../../utils';
//---------------------------------------------------------

export default function TimeZoneSelect2({
  tzOptions, setTimeZone, value, disabled = false, required = false,
  className, filterCountryCode=null, elStyle=null
}) {

  //const modifiedTimezoneOptions = fGetArrTimeZone(filterCountryCode);
  if(!elStyle) {
    elStyle = { marginLeft: '10px', marginRight: '20px', maxWidth: 400 };
  }

  return (
    <div className="row">
      <select
        className={className} style={elStyle}
        disabled={disabled} required={required}
        value={value}
        onChange={(e) => { setTimeZone(e.target.value) }}
        name='selTz'
        id='selTz'
      >
        <option>Select a Timezone</option>
        {tzOptions.map((timezone, index) => (
          <option key={'tz2_'+timezone.tzId}
            value={fGetHMFormat2(timezone.utcOffsetMinute)}
            //value={fGetHMFormat2(timezone.offset)}
            //value={timezone.utcOffsetMinute}
          >
            {/*//UTC {fGetHMFormat2(timezone.offset)} {timezone.name}*/}
            {/*'UTC '+(timezone.utcOffsetMinute / 60)+':00  '+timezone.title*/}
            UTC {fGetHMFormat2(timezone.utcOffsetMinute)} {timezone.title}
          </option>
        ))}
      </select>
    </div>
  )
};