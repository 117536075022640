import { memo } from 'react'
import { MdOutlineEmail, MdPhone } from 'react-icons/md'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { StatusTextInsurance, StatusTextInsurance2 } from '../../components/status/Status'
import { AWS_BUCKET, AWS_BUCKET_PROFILES, AWS_BUCKET_SERVICES } from '../../constants'

function PatientListData({ limit, pagenum, list = [], showModalAppointment, showModalSubscription }) {
  //console.log('pList', list);
  // var paginatedlist = new List('users', options);
  function handleNewVisit(patient) {
    showModalAppointment(patient)
  }
  function handleSubscriptionDetails(patient) {
    showModalSubscription(patient)
  }
  function formatLongtxt(string = '') {
    if (string?.length > 20) {
      return string.substring(0, 20) + '...'
    }
    return string
  }

  let paginatedlist = []
  var j = 0
  var k = 0
  for (var i in list) {
    // console.log("list",list)
    // console.log(paginatedlist,limit, pagenum,(pagenum*limit)-limit,i,j,list[i])
    // console.log(parseInt(i)===((pagenum*limit)-limit),i,(pagenum*limit)-limit)
    // console.log("Page "+(pagenum))
    if (parseInt(i) === pagenum * limit - limit) {
      for (var k = 0; k < limit; k++) {
        if (list[parseInt(i) + k]) {
          paginatedlist.push(list[parseInt(i) + k])
        }
      }
    }
    j = j + 1
  }
  return paginatedlist.map((item, index) => (
    <tr key={index}>
      <td>
        <Link
          to={'profile/' + item.patient_id}
          state={{
            selectedUser: item,
          }}
        >
          <div className="row">
            <div className="col">
              <img src={item.picture} alt="" className="thumb-sm rounded-circle mr-2" style={{ objectFit: 'cover' }} />
              {item.first_name} {item.middle_name} {item.last_name}
            </div>
          </div>
        </Link>
      </td>
      <td>
        <a target="_blank" href={`mailto:${item.email}`}>
          <MdOutlineEmail /> {item.email}
        </a>
      </td>
      <td>
        <a target="_blank" href={`tel:${item.contact_info}`}>
          <MdPhone /> {formatLongtxt(item.contact_info)}
        </a>
      </td>
      <td>
        <button
          className="btn"
          title="Subscription Details"
          onClick={() => {
            handleSubscriptionDetails(item)
          }}
        >
          <span className="badge badge-md badge-soft-purple">{item.subscription_status}</span>
          <br />
          <span className="badge badge-md badge-soft-purple">{item.subscription_workflow}</span>
        </button>
      </td>
      <td>
        <Link
          to={'profile/' + item.patient_id + '#insurance'}
          state={{
            selectedUser: item,
          }}
        >
          {/*<StatusTextInsurance status={item.with_insurance || 0} />*/}
          <StatusTextInsurance status={item?.insurance_status.toLowerCase() === 'insured' ? 1 : 0} />
          <br />
          <span className="badge badge-md badge-soft-purple">{item.insurance_workflow}</span>
        </Link>
      </td>
      <td>
        <button
          className="btn btn-outline-purple btn-round"
          title="Book an Appointment"
          style={{ fontSize: 11 }}
          onClick={() => {
            handleNewVisit(item)
          }}
        >
          <i className="far fa-clock"></i> Book an Appointment
        </button>
      </td>
      {/* //Action!!
       <td>
        <Link
          to='profile/edit'
          state={{
            selectedUser: item,
          }}
          className='mr-2'
        >
          <i className='fas fa-edit text-info font-16'></i>
        </Link>
        <Link
          to='profile/delete'
          state={{
            selectedUser: item,
          }}
        >
          <i className='fas fa-trash-alt text-danger font-16'></i>
        </Link>
      </td> */}
    </tr>
  ))
}

export default PatientListData
