import * as yup from 'yup';

export const RegisterSchema = yup
  .object({
    FirstName: yup
      .string()
      .required('required_error')
      .min(2, 'name_min_length_error')
      .max(50, 'name_max_length_error')
      .matches(/^[A-Za-z\s]+$/, 'name_format_error'),
    MiddleName: yup.string(),
    // .required('required_error')
    // .min(2, 'name_min_length_error')
    // .max(50, 'name_max_length_error')
    // .matches(/^[A-Za-z\s]+$/, 'name_format_error'),
    LastName: yup
      .string()
      .required('required_error')
      .min(2, 'name_min_length_error')
      .max(50, 'name_max_length_error')
      .matches(/^[A-Za-z\s]+$/, 'name_format_error'),
    Email: yup.string().email('email_error').required('required_error').max(255, 'email_max_length_error').min(6, 'email_min_length_error'),
    Password: yup
      .string()
      .required('required_error')
      .min(8, 'password_min_length_error')
      .max(50, 'password_max_length_error')
      .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-={}|;:'\",.<>?]).{8,}$/, 'password_format_error'),
    ConfirmPassword: yup
      .string()
      .oneOf([yup.ref('Password')], 'confirm_password_error')
      .required('required_error'),
    Terms: yup.boolean().oneOf([true], 'terms_error').required('terms_error'),
  })
  .required();

export default RegisterSchema;
