import * as yup from 'yup'

export const ProviderProfileSchema = yup
  .object({
    provider_name: yup
      .string()
      .required('required_error')
      .min(2, 'name_min_length_error')
      .max(50, 'name_max_length_error')
      .matches(/^[A-Za-z\s]+$/, 'name_format_error'),
  })
  .required()

export default ProviderProfileSchema
