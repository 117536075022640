import React, { useEffect, useState } from 'react'
import { AWS_BUCKET } from './../constants'
import { Rating } from 'react-simple-star-rating'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import { TableTitle } from '../components/table/Tables'
import { useTranslation } from 'react-i18next'

export default function About() {
  const { t: about } = useTranslation('about')

  // const { auth } = useAuth()
  // const axiosPrivate = useAxiosPrivate()
  // const navigate = useNavigate()

  return (
    // <div
    //   className='account-body vw-100'

    // >
    <div className="container">
      <div className="col vh-100 ">
        <h1>{about('about')}</h1>
        <div className="col">
          {/* <div className='visitCompleteCont'> */}
          <div className="row">
            <div className="card p-2 m-2 col-md-3">
              <b>{about('app_name')}</b>
              <br />
              <div className="align-self-center">
                <b>
                  {about('version')} {process.env.REACT_APP_APP_VERSION}
                </b>{' '}
                <br />[{process.env.REACT_APP_APP_VERSION}] - {process.env.REACT_APP_APP_RELEASE_DATE} <br />
              </div>
            </div>
            <div className="card p-2 m-2 col-md-3">
              <b>{about('privacy')}</b>
              <br />
              <div className="align-self-center">
                <b>
                  <a href="https://niuhealth.com/privacy-policy/" target="_blank" >
                    {about('click_here')}
                  </a>
                </b>{' '}
              </div>
            </div>
            <div className="card p-2 m-2 col-md-3">
              <b>{about('drcoco_ai')}</b>
              <br />
              <div className="align-self-center">
                <b>{about('version')} 0.0.2-5</b> <br />
                [0.0.2-5] - {process.env.REACT_APP_APP_RELEASE_DATE} <br />
              </div>
            </div>
            <div className="card p-2 m-2 col-md-3">
              <b>{about('niu_social')}</b>
              <br />
              <div className="align-self-center">
                <b>{about('version')} 0.0.2-1</b> <br />
                [0.0.2-1] - {process.env.REACT_APP_APP_RELEASE_DATE} <br />
              </div>
            </div>

            {/*
                        <div className="card p-2 m-2 col-md-3">
                            <b>Provider & Telehealth Platform</b><br />
                            <div className='align-self-center'>
                                <b> Version 1.0.0 (health-lives)</b><br />
                                [1.0.0] - 2023-09-10<br />
                                <b>Added</b>  <br />
                                - Everything<br />
                                <b>Fixed</b> <br />
                                - Nothing yet<br />
                                <b>Changed</b><br />
                                - Upgraded nothing yet<br />
                                <b>Removed</b> <br />
                                - Nothing yet<br />
                            </div>
                        </div>
                        <div className="card p-2 m-2 col-md-3">
                            <b>Marketplace Platform</b><br />
                            <div className='align-self-center'>
                                <b>Version 1.0.0 (shopping-lives)</b><br />
                                [1.0.0.0] - 2023-09-10<br />
                                <b>Added</b><br />
                                - Everything<br />
                                <b>Fixed</b><br />
                                - Nothing yet<br />
                                <b>Changed</b><br />
                                - Upgraded nothing yet<br />
                                <b>Removed</b><br />
                                - Nothing yet<br />
                                <br />
                            </div>
                        </div>
                        <div className="card p-2 m-2 col-md-3">
                            <b>Niu Social Platform</b><br />
                            <div className='align-self-center'>
                                <b>Version 0.0.5 (buzz-alpha)</b><br />
                                [0.0.5] - 2023-09-10<br />
                                <b>Added</b><br />
                                - TBD<br />
                                <b>Fixed</b><br />
                                - TBD<br />
                                <b>Changed</b><br />
                                - TBD<br />
                                <b>Removed</b><br />
                                - TBD<br />
                            </div>
                        </div>
                        <div className="card p-2 m-2 col-md-3">
                            <b>Niu Coin Platform</b><br />
                            Version 0.0.0 (coin-poc) <br />
                        </div>
                        <div className="card p-2 m-2 col-md-3">
                            <b>Personal Health Platform</b><br />
                            Version 0.0.0 (phr-poc)<br />
                        </div>
                        */}
          </div>
        </div>
      </div>
    </div>
    // </div>
    // </div>
  )
}
