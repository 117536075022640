import * as yup from 'yup';

export const ResetPasswordSchema = yup
  .object({
    Password: yup
      .string()
      .required('required_error')
      .min(8, 'password_min_length_error')
      .max(50, 'password_max_length_error')
      .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-={}|;:'\",.<>?]).{8,}$/, 'password_format_error'),
    ConfirmPassword: yup
      .string()
      .required('required_error')
      .oneOf([yup.ref('Password')], 'confirm_password_error'),
  })
  .required();

export default ResetPasswordSchema;
