import { useState } from 'react'
import { useEffect } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import useAuth from '../../../hooks/useAuth'
import useLogout from '../../../hooks/useLogout'
import { useTranslation } from 'react-i18next'
import { emailStartsWithAdminFunc, redirectToNiuSocial } from '../../../utils'
import { USERTYPE } from '../../../constants'
import { useLogoutMutation } from 'v2/src/lib/services/auth/authApi';

function SideNav({ openSideNav, openSideIcons, removePfp }) {
  const logout = useLogout()
  const { auth, setAuth } = useAuth()
  const navigate = useNavigate()
  const [openSideNav_delayed, setOpenSideNav_delayed] = useState(openSideNav)
  const { t: provider_common } = useTranslation('provider_common')
  const [fLogout, { isLoading: isSigningOut}] = useLogoutMutation();

  async function logoutCurrentUser() {
    //const logOutResp = await fLogout().unwrap();console.log('logOutResp', logOutResp);
    //await axiosPrivate.post('signOut', { Email: auth.email }).then((res) => {
    await axiosPrivate.post('signOut', { Email: auth.email }).then((res) => {
      console.log(res)
      // const { StatusCode, Data: data = [], Message } = res.data;
    })
    //const logOutResp = await fLogout().unwrap();
  }

  function handleLogout(e) {
    e.preventDefault()
    Swal.fire({
      icon: 'question',
      html: `${provider_common('logout_confirmation')}`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: provider_common('ok'),
      cancelButtonText: provider_common('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        logout()
        logoutCurrentUser()
        navigate('/login', { replace: true })
      } else {
        return
      }
    })
  }

  useEffect(() => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    const handleClick = async (event) => {
      //console.log(openSideNav)
      if (openSideNav === true) {
        await delay(400)
        setOpenSideNav_delayed(openSideNav)
      } else {
        setOpenSideNav_delayed(openSideNav)
      }
    }
    handleClick()
  }, [openSideNav])

  return (
    <div
      className="left-sidenav"
      // style={{ display: openSideNav ? 'block' : 'none', marginTop: '70px' }}
      style={{
        height: 'auto',
        display: openSideIcons ? 'block' : 'none',
        marginTop: '70px',
        padding: '0px',
        minWidth: '80px',
        pxmarginLeft: '-26zpx',
        width: openSideNav ? '320px' : '78px',
        zIndex: 10,
        position: removePfp ? 'fixed' : 'static',
      }}
    >
      {
        <ul className="metismenu left-sidenav-menu">
          <li>
            <NavLink to="/provider">
              <i className="ti-bar-chart"></i>
              {openSideNav_delayed ? <span>{provider_common('dashboard')}</span> : <span className="d-inline d-sm-none">{provider_common('dashboard')}</span>}
            </NavLink>
          </li>
          <li>
            <NavLink to="visit-requests">
              <i className="far fa-newspaper"></i>
              {openSideNav_delayed ? <span>{provider_common('appointment_requests')}</span> : <span className="d-inline d-sm-none">{provider_common('appointment_requests')}</span>}
            </NavLink>
          </li>
          <li>
            <NavLink to="visits">
              <i className="fas fa-clock"></i>
              {openSideNav_delayed ? <span>{provider_common('appointments')}</span> : <span className="d-inline d-sm-none">{provider_common('appointments')}</span>}
            </NavLink>
          </li>
          <li>
            <NavLink to="patient">
              <i className="fas fa-user-friends"></i>
              {openSideNav_delayed ? <span>{provider_common('patients')}</span> : <span className="d-inline d-sm-none">{provider_common('patients')}</span>}
            </NavLink>
          </li>
          <li>
            <NavLink to="ratings">
              <i className="far fa-star"></i>
              {openSideNav_delayed ? <span>{provider_common('ratings')}</span> : <span className="d-inline d-sm-none">{provider_common('ratings')}</span>}
            </NavLink>
          </li>
          <li>
            <NavLink to="clinics">
              <i className="fas fa-medkit"></i>
              {openSideNav_delayed ? <span>{provider_common('clinics')}</span> : <span className="d-inline d-sm-none">{provider_common('clinics')}</span>}
            </NavLink>
          </li>
          <li>
            <NavLink to="services">
              <i className="fas fa-dolly"></i>
              {openSideNav_delayed ? <span>{provider_common('services')}</span> : <span className="d-inline d-sm-none">{provider_common('services')}</span>}
            </NavLink>
          </li>
          {auth.userType === USERTYPE.provider && emailStartsWithAdminFunc(auth.email) && (
            <>
              <li>
                <NavLink to="translations">
                  <i className="fas fa-language"></i>
                  {openSideNav_delayed ? <span>{provider_common('translations')}</span> : <span className="d-inline d-sm-none">{provider_common('translations')}</span>}
                </NavLink>
              </li>
              <li>
                <NavLink to="#" onClick={redirectToNiuSocial}>
                  <i className="fas fa-user"></i>
                  {openSideNav_delayed ? <span>My Social</span> : <span className="d-inline d-sm-none">My Social</span>}
                </NavLink>
              </li>
            </>
          )}
        </ul>
      }

      <div className="logoutDiv row" style={{ marginLeft: -18 }}>
        <ul className="metismenu left-sidenav-menu">
          <li>
            {
              <NavLink onClick={handleLogout.bind(this)}>
                <i className="fas fa-sign-out-alt"></i>
                {openSideNav_delayed ? <span>{provider_common('logout')}</span> : <span className="d-inline d-sm-none">{provider_common('logout')}</span>}
              </NavLink>
            }
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SideNav
