import { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ProfileForm from 'v2/src/pages/profile/ProfileForm';
import IdDocumentForm from 'v2/src/pages/profile/IdDocumentForm';
import InsuranceDocumentForm from 'v2/src/pages/profile/InsuranceDocumentForm';
import { aSGetAuthUserData, rSetLogOut } from 'v2/src/lib/services/auth/authSlice';
//---------------------------------------------------------

export default function ProfileN() {
  //const fDispatch = useDispatch();
  //const fNavigate = useNavigate();
  let aUData = useSelector(aSGetAuthUserData);
  console.log('aUData', {aUData });
  //---------------------------------------------------------

  return (
    <>
      <ProfileForm />
      <IdDocumentForm />
      <InsuranceDocumentForm />
    </>
  );
};
