import React, { useEffect, useState } from 'react'
import { AWS_BUCKET } from '../../../constants'
import { Rating } from 'react-simple-star-rating'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function Success() {
  const [rating, setRating] = useState(3)
  const { t: patient_marketplace } = useTranslation('patient_marketplace')

  const handleRating = (rate) => {
    setRating(rate)
  }
  return (
    <div
      className="account-body visitsuccess vw-100"
      style={{
        backgroundImage: `url(${AWS_BUCKET}/assets/images/11b-05.jpg)`,
      }}
    >
      <div className="container">
        <div className="row vh-100 ">
          <div className="col-12 align-self-center">
            <div className="visitCompleteCont">
              <div className="card auth-card shadow-lg">
                <div className="card-body">
                  <div className="media">
                    <div className="media-body align-self-center">
                      <h2 className="mt-0 mb-1 text-success">{patient_marketplace('payment_success')}</h2>
                      <h3 className="mt-0 mb-1 ">{patient_marketplace('thank_you_for_choosing')}</h3>
                    </div>
                  </div>

                  <div className="form-group mb-0 row ">
                    <div className="col-12 mt-2 visit_success_btn">
                      <Link to="..">
                        <button className="btn btn-gradient-success btn-round waves-effect waves-light" type="button">
                          {patient_marketplace('go_back_marketplace')}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
