import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Footer from '../../components/Footer'
import RingLoading from '../../components/lottie/RingLoading'
import { APP_URL } from '../../constants'
import useAuth from '../../hooks/useAuth'
import useGetPatientDetails from '../../hooks/api/useGetPatientDetails'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

function PatientIndexPage() {
  const navigate = useNavigate()
  const { auth, setAuth } = useAuth()
  const { t: patient_virtual_visit } = useTranslation('patient_virtual_visit')

  const [allowVirtualVisit, setAllowVirtualVisit] = useState(false)
  const { getPatientDetails, data: patientDetails, loading: patientDetailsLoading } = useGetPatientDetails()

  useEffect(() => {
    getPatientDetails({ apiParams: { Email: auth.email || Cookies.get('email') }, refetch: true })
  }, [])

  useEffect(() => {
    if (patientDetails.Status) {
      setAuth({ ...auth, ...patientDetails.Data[0] })
      //console.log('asasa', patientDetails.Data[0]);

      /*If the patient haven't uploaded any ID then show the message in popup and redirect to the profile page*/
      let totalUploadedDocuments = patientDetails.Data[0].documents.length
      if(totalUploadedDocuments <= 0 || !patientDetails.Data[0]?.address_line_1 ||
        !patientDetails.Data[0]?.address_line_1?.length
      ) {
        Swal.fire({ icon: 'info', html: 'Please complete your registration to continue.' }).then(() => {
          navigate('/patient/profile', { replace: true })
        })
      } else if(parseInt(patientDetails.Data[0].subscription_plan) > 0 ||
        patientDetails.Data[0].insurance_status == 'pending' ||
        patientDetails.Data[0].insurance_status == 'active'
      ) {
        /*If the user is subscribed or if the insurance is pending/active then allow the virtual visit*/
        setAllowVirtualVisit(true)
      } else {
        Swal.fire({
          html:
            `${patient_virtual_visit('please_upload_msg')} <a href='` +
            APP_URL +
            `/patient/insurance'>${patient_virtual_visit('insurance')}</a> ${patient_virtual_visit('or')} <a href='` +
            APP_URL +
            `/patient/subscription'>${patient_virtual_visit('subscribe')}.</a>`,
        })
      }
    }
  }, [patientDetails])

  const handleVirtualVisitButtonClick = () => {
    if(!auth || auth.first_name === 'name' || !auth.address_line_1 ||
      !auth.address_line_1?.length
    ) {
      Swal.fire({ icon: 'info', html: patient_virtual_visit('complete_registration') }).then(() => {
        navigate('/patient/profile', { replace: true })
      })
    } else if (allowVirtualVisit) {
      navigate('/virtualvisit')
    } else {
      Swal.fire({
        html:
          `${patient_virtual_visit('access_virtual_visit_msg')} <a href='` +
          APP_URL +
          `/patient/insurance'>${patient_virtual_visit('insurance')}</a> ${patient_virtual_visit('or')} <a href='` +
          APP_URL +
          `/patient/subscription'>${patient_virtual_visit('subscribe')}.</a>`,
      })
    }
  }

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row figmaFirstBox">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h2>{patient_virtual_visit('welcome')}</h2>
                  <h4 style={{ marginTop: '40px' }}>{patient_virtual_visit('reminder_text')}</h4>
                  <ol className="telehealth">
                    <li>{patient_virtual_visit('only_allowed_in_hawai')}</li>
                    <li>{patient_virtual_visit('offer_consultation_no_prescription')}</li>
                    <li>{patient_virtual_visit('offer_consultation_with_prescription')}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row figmaFirstBox">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h2>{patient_virtual_visit('start_virtual_visit')}</h2>
                  <h4 style={{ marginTop: '40px' }}>{patient_virtual_visit('how_telehealth_works')}</h4>
                  <ol className="telehealth">
                    <li>{patient_virtual_visit('connected_to_internet')}</li>
                    <li>{patient_virtual_visit('click_below_button')}</li>
                    <li>{patient_virtual_visit('reason')}</li>
                    <li>{patient_virtual_visit('enjoy_video')}</li>
                  </ol>

                  <p style={{ marginTop: '40px' }}>
                    <button
                      disabled={patientDetailsLoading}
                      type="button"
                      className={`btn ${allowVirtualVisit ? 'btn-success' : 'btn-outline-success'}  btn-round waves-effect waves-light figmaBigButton`}
                      onClick={handleVirtualVisitButtonClick}
                    >
                      {patientDetailsLoading ? (
                        <div className="row d-flex align-items-center m-0">
                          <RingLoading size={30} />
                          <b>{patient_virtual_visit('loading')}</b>
                        </div>
                      ) : (
                        patient_virtual_visit('start_virtual_visit')
                      )}
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default PatientIndexPage
