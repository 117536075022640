//import { createSlice, current } from "@reduxjs/toolkit";
import { nHApi } from 'v2/src/lib/services/public/nHApi';
import { USER_TYPES, DEF_COUNTRY_ID } from 'v2/src/config/appConfig';
//---------------------------------------------------------

export const geoApi = nHApi.injectEndpoints({ endpoints: builder => ({
  timezones: builder.query({
    query: (params) => {
      let countryId =  params?.countryId || DEF_COUNTRY_ID;

      return { url: '/timezones',
        params: { country_id: countryId, per_page: 20 }
      };
    },
    keepUnusedDataFor: 6000,
  }),
  countries: builder.query({
    query: (params) => {
      return '/countries';
    },
    keepUnusedDataFor: 6000
  }),
  provinces: builder.query({
    query: (params) => {
      let countryId =  params?.countryId || DEF_COUNTRY_ID;

      return { url: '/states',
        params: {country_id: countryId, per_page: 100}
      };
    },
    keepUnusedDataFor: 6000,
  }),
  provincesMult: builder.query({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      let page = 1, pgGT = 0, apiMultResp;//console.log('_arg', _arg);
      const loopMax = 5;//arg?.maxPage || 5;
      const filter = _arg?.filter || null;
      const allRec = [];
      const countryId = _arg?.countryId || DEF_COUNTRY_ID;
      const _fetchData = async(page=1, aArgs=null) => {
        let sDResp, aUrl = '/states?country_id='+countryId;
        aUrl += '&page='+page;
        if(aArgs !== null) {
          //aUrl += '&filter='+JSON.stringify(argFilter);
        }
        let sResp = await fetchWithBQ(aUrl);
        //await _queryApi.dispatch(api.endpoints.states.initiate(page));
        //if(fResp?.error) throw fResp.error;
        //sResp = sResp?.data;
        if(sResp?.error) { sResp = sResp?.error; }
        else if(sResp?.data) {
          //sDResp = {};
          sDResp = {data: sResp.data};
          if(sResp?.meta) {
            sDResp['meta'] = sResp?.meta;
          }
        }//console.log('sDResp', sDResp);

        return sDResp;
      };

      const dApiResp = await _fetchData(page);//, filter);
      if(dApiResp?.data.length) {
        allRec.push(...dApiResp?.data);
        //const currPage = dApiResp?.pagination?.page?.current;
        pgGT = dApiResp?.pagination?.page?.total;

        if(page < pgGT) {
          page++; let dApiNext;
          for(let cnt=page; cnt<=loopMax && cnt<=pgGT; cnt++) {
            dApiNext = await _fetchData(cnt);//, filter);
            if(dApiNext?.data.length) {
              allRec.push(...dApiNext?.data);
            } else { break; }
          }
        }
      }

      if(dApiResp?.data) {
        apiMultResp = { data: allRec };
        if(dApiResp?.meta) {
          apiMultResp['pagination'] = dApiResp?.meta;
        }
      }//console.log('apiStatesMultResp '+pgGT, apiMultResp);
      return {data: apiMultResp};
    },
  }),

}), });

export const { useTimezonesQuery, useCountriesQuery,
  useProvincesQuery, //useProvincesMultQuery
} = geoApi;
//---------------------------------------------------------
