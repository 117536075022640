import { useState, useEffect } from 'react'
import useAxiosPrivate from '../useAxiosPrivate'

const useGetServices = () => {
  const axiosPrivate = useAxiosPrivate()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const controller = new AbortController()
  let apiParams = {}

  const getServiceList = async (page, search, selectedCategories, priceRangeMin, priceRangeMax, starFilter) => {
    if (page) {
      apiParams['Page'] = page
    }
    if (search) {
      apiParams['Search'] = search
    }
    if (Object.keys(selectedCategories).length) {
      apiParams['Filter[category][]'] = Object.keys(selectedCategories)
    }
    if (priceRangeMin) {
      apiParams['Filter[min_price]'] = priceRangeMin
    }
    if (priceRangeMax) {
      apiParams['Filter[max_price]'] = priceRangeMax
    }
    if (starFilter && starFilter.some((element) => element !== false && element !== undefined)) {
      apiParams['Filter[ratings][]'] = starFilter.filter((element) => element !== false)
    }
    try {
      const response = await axiosPrivate.get(
        'services',
        {
          params: apiParams,
        },
        { signal: controller.signal }
      )
      setData(response.data)
      setLoading(false)
      return response.data
    } catch (err) {
      setError(error)
      setLoading(false)
    } finally {
      controller.abort()
    }
  }

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return { data, loading, error, getServiceList }
}

export default useGetServices
