import { useEffect, useState } from 'react'
import useAxiosPrivate from '../useAxiosPrivate'
import { useDispatch } from 'react-redux'
//import { updatePatientState } from '../../redux/slice/patient'
import { updatePatientState } from 'v2/src/lib/services/patient/patientSlice';
import { useSelector } from 'react-redux'

const useGetPatientDetails = () => {
  const patientStateData = useSelector((state) => state.patient)

  const axiosPrivate = useAxiosPrivate()
  const controller = new AbortController()
  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const getPatientDetails = async (props) => {
    const { apiParams = {}, refetch = false } = { ...props }
    try {
      if (Object.keys(patientStateData).length > 0 && refetch == false) {
        setData(patientStateData)
        setLoading(false)
        return patientStateData
      } else {
        const response = await axiosPrivate.post('getPatientDetails', apiParams, { signal: controller.signal })
        if (response.data.Status) {
          dispatch(updatePatientState(response.data))
        }
        setData(response.data)
        setLoading(false)
        return response.data
      }
    } catch (error) {
      setError(error)
      setLoading(false)
    } finally {
      controller.abort()
    }
  }

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return { data, loading, error, getPatientDetails }
}

export default useGetPatientDetails
