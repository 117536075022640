//import imgLogo from '../assets/images/nu-health-logo.png'
//import imgLogo from '../assets/images/nu-health-logo50.png';
import imgLogo from '../assets/images/niuHealthLogo.svg';

function SideNavLogo({ style }) {
  return (
    <img src={imgLogo} alt='logo-small' className='logo-sm' style={style} />
  );
}

export default SideNavLogo;
