import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import UploadImage from '../../../components/form/UploadImage'
import Multiselect from 'multiselect-react-dropdown'
import Swal from 'sweetalert2'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

export const StyleWrapper = styled.div`
  .optionListContainer {
    position: sticky;
  }
`
function ManageServices() {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const { action, id } = useParams()
  const { state } = useLocation()
  const controller = new AbortController()
  const { t: provider_services } = useTranslation('provider_services')
  const endPoint = action == 'new' ? 'createService' : 'updateService'

  const [categoryList, setCategoryList] = useState([{ id: '', name: '' }])
  const [clinicList, setClinicList] = useState([{ name: '', id: '' }])
  const [selectedClinicList, setSelectedClinicList] = useState([{ name: '', id: '' }])

  const [images, setImages] = useState([{ path: 'services/Default.png', file: {} }])
  const [imagepreview, setImagePreview] = useState(true)

  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState('1')

  const [service, setService] = useState({
    service_name: '',
    service_description: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    image5: '',
    category: '1',
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()

  const onSubmit = async (data) => {
    const formData = new FormData()
    formData.append('Email', auth.email)

    if (images.length > 0 && images[0].path !== 'services/Default.png') {
      for (let index = 0; index < images.length; index++) {
        if (images[index].file) {
          formData.append('Image' + parseInt(index + 1), images[index].file)
        }
      }
    }

    if (action === 'new') {
      formData.append('ServiceName', data.name)
      formData.append('CategoryID', selectedCategory)
      formData.append('CostPrice', data.rate)
      formData.append('ServiceDescription', data.description)
      formData.append('ClinicIDs', [...service.clinic_ids])
      formData.append('Status', Boolean(data.active) * 1)
    } else if (action === 'update') {
      formData.append('ServiceID', id)

      formData.append('ServiceName', service.service_name)
      formData.append('Category', service.category)
      formData.append('Price', service.cost_price)
      formData.append('Description', service.service_description)
      formData.append('ClinicIDs', [...service.clinic_ids])
      formData.append('Status', Boolean(service.status) * 1)

      formData.append('Name', service.service_name)
    }

    await axiosPrivate
      .post(endPoint, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data

        if (Status) {
          Swal.fire(Message)
          navigate('/provider/services')
        } else {
          throw new Error(Message)
        }
      })
      .catch((err) => {
        alert(err.message || 'Creating new services failed.')
      })
  }

  async function getServiceCategories() {
    await axiosPrivate
      .post(
        'getServiceCategories',
        { Email: auth.email },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Data, Message } = res.data
        if (Status) {
          setCategoryList(
            Data.map((item) => {
              return { id: item.id, name: item.name }
            })
          )
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  async function getServiceDetails(clinicList) {
    await axiosPrivate
      .post(
        'providerGetService',
        { Email: auth.email, ServiceID: id },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data

        if (Status) {
          const serviceClinics = data.clinic_ids.split(',')
          setService({ ...data, clinic_ids: serviceClinics })
          const clinic_list_temp = clinicList
            .map((item, index) => {
              for (let i = 0; i < serviceClinics.length; i++) {
                if (item.id === serviceClinics[i]) {
                  return { name: item.name, id: item.id }
                }
              }
            })
            .filter((item) => {
              if (item) {
                return item
              }
            })

          setSelectedClinicList(clinic_list_temp)

          var imagetemp = []
          if (data.image1 !== 'Default.png') {
            imagetemp.push({ path: data.image1 })
          }
          if (data.image2 !== 'Default.png') {
            imagetemp.push({ path: data.image2 })
          }
          if (data.image3 !== 'Default.png') {
            imagetemp.push({ path: data.image3 })
          }
          if (data.image4 !== 'Default.png') {
            imagetemp.push({ path: data.image4 })
          }
          if (data.image5 !== 'Default.png') {
            imagetemp.push({ path: data.image5 })
          }
          setImages(imagetemp)

          setIsLoading(false)
        } else {
          throw new Error(Message)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  async function getClinicList() {
    await axiosPrivate
      .post(
        'getClinics',
        { Email: auth.email },
        {
          Accept: 'application/json',
          headers: { 'Content-Type': 'multipart/form-data' },
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data

        if (Status) {
          const apiClinicList = data.map((item) => {
            return { name: item.clinic_name, id: item.clinic_id }
          })
          setClinicList(apiClinicList)
          if (action === 'update') {
            getServiceDetails(apiClinicList)
          }

          setIsLoading(false)
        } else {
          throw new Error(Message)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    let isMounted = true

    getClinicList()
    getServiceCategories()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="float-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{provider_services('niu_health')}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/provider/services">{provider_services('services')}</Link>
                </li>
                <li className="breadcrumb-item active">
                  {action === 'new'
                    ? provider_services('create_service', { action: 'Create' })
                    : action === 'update'
                    ? provider_services('create_service', { action: 'Update' })
                    : provider_services('create_service', { action: 'Manage' })}{' '}
                  Service
                </li>
              </ol>
            </div>
            <h4 className="page-title">{provider_services('services')}</h4>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="mt-0 header-title">
                  {action === 'new' ? provider_services('create_service', { action: 'Create New' }) : provider_services('create_service', { action: 'Update' })} Service
                </h4>
                <p className="text-muted mb-3">{provider_services('service_info')}</p>

                <div className="row" style={{ marginBottom: '30px' }}>
                  <div className="col-md-12">
                    <label className="mb-3">{provider_services('service_name')}</label>
                    {action === 'update' ? (
                      <>
                        <input
                          required
                          className="form-control"
                          type="text"
                          value={service.service_name}
                          onChange={(e) => {
                            setService({ ...service, service_name: e.target.value })
                          }}
                        />
                      </>
                    ) : (
                      <input
                        required
                        className="form-control"
                        type="text"
                        {...register('name', {
                          value: state?.selectedService?.name,
                        })}
                      />
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: '30px' }}>
                  <div className="col-md-6">
                    <label htmlFor="example-text-input" className="col-form-label text-right">
                      {provider_services('category')}
                    </label>
                    {action === 'new' ? (
                      <Multiselect
                        style={{ zIndex: 3 }}
                        options={categoryList}
                        selectedValues={[]}
                        onSelect={(selectedItem) => {
                          setService({ ...service, category: selectedItem[0].id })
                          setSelectedCategory(selectedItem[0].id)
                        }}
                        onRemove={(selectedItem) => {
                          setService({ ...service, category: selectedItem })
                          setSelectedCategory(selectedItem)
                        }}
                        isObject={true}
                        singleSelect={true}
                        displayValue="name"
                      />
                    ) : (
                      <>
                        {isLoading && service.service_name != '' ? (
                          <></>
                        ) : (
                          <Multiselect
                            style={{ zIndex: 3 }}
                            options={categoryList}
                            selectedValues={[categoryList[parseInt(service.category) - 1]]}
                            onSelect={(selectedItem) => {
                              console.log(selectedItem, 'newservice', { ...service, category: selectedItem[0].id })
                              setService({ ...service, category: selectedItem[0].id })
                              setSelectedCategory(selectedItem[0].id)
                            }}
                            onRemove={(selectedItem) => {
                              setService({ ...service, category: selectedItem })
                              setSelectedCategory(selectedItem)
                            }}
                            isObject={true}
                            singleSelect={true}
                            displayValue="name"
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="example-text-input" className="col-form-label text-right">
                      {provider_services('price_rate')}
                    </label>
                    {action === 'update' ? (
                      <input
                        required
                        value={service.cost_price}
                        className="form-control"
                        type="number"
                        step={'0.01'}
                        onChange={(e) => {
                          setService({ ...service, cost_price: e.target.value })
                        }}
                      />
                    ) : (
                      <input
                        required
                        value={service.cost_price}
                        className="form-control"
                        type="number"
                        step={'0.01'}
                        {...register('rate', {
                          value: state?.selectedService?.rate,
                        })}
                      />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="message">{provider_services('description')}</label>
                          {action === 'update' ? (
                            <>
                              <textarea
                                required
                                className="form-control"
                                rows="5"
                                value={service.service_description}
                                onChange={(e) => {
                                  setService({ ...service, service_description: e.target.value })
                                }}
                              ></textarea>
                            </>
                          ) : (
                            <textarea
                              required
                              className="form-control"
                              rows="5"
                              {...register('description', {
                                value: service.service_description,
                              })}
                            ></textarea>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">
                        {provider_services('activity')} {service.status === '1'}
                      </label>
                      <div className="custom-control custom-switch switch-success">
                        {action === 'new' ? (
                          <input id="customSwitchSuccess" type="checkbox" className="custom-control-input" {...register('active')} />
                        ) : (
                          <input
                            id="customSwitchSuccess"
                            type="checkbox"
                            className="custom-control-input"
                            checked={Boolean(service.status)}
                            onChange={(e) => {
                              setService({ ...service, status: e.target.value })
                            }}
                          />
                        )}

                        <label className="custom-control-label" htmlFor="customSwitchSuccess">
                          {provider_services('active')}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-lg-6">
                    <label htmlFor="exampleFormControlSelect2">{provider_services('clinic_availability')}</label>
                    <br />
                    {isLoading ? (
                      <></>
                    ) : (
                      <StyleWrapper>
                        <Multiselect
                          style={{ zIndex: 3, width: '100%' }}
                          options={clinicList}
                          selectedValues={action === 'new' ? [] : selectedClinicList}
                          onSelect={(selectedList, selectedItem) => {
                            var selected_ID_List = selectedList.map((clinic) => {
                              return clinic.id
                            })
                            setService({ ...service, clinic_ids: selected_ID_List })
                          }}
                          onRemove={(selectedList, selectedItem) => {
                            var selected_ID_List = selectedList.map((clinic) => {
                              return clinic.id
                            })

                            setService({ ...service, clinic_ids: selected_ID_List })
                          }}
                          isObject={true}
                          showCheckbox={true}
                          displayValue="name"
                        />
                      </StyleWrapper>
                    )}
                  </div>
                </div>

                <div className="row" style={{ marginTop: '40px' }}>
                  <div className="col-lg-12">
                    <label htmlFor="exampleFormControlSelect2">{provider_services('service_image')}</label>
                    <div className="row">
                      {images.map((image, index) => (
                        <UploadImage
                          id={index}
                          images={images}
                          setImages={setImages}
                          previewImage={image}
                          formData={service}
                          setFormData={setService}
                          imagepreview={imagepreview}
                          setImagePreview={setImagePreview}
                          action={action}
                        />
                      ))}
                      {images.length <= 5 && images[images.length - 1]?.path != 'services/Default.png' ? (
                        <button
                          className="btn btn-gradient-success waves-effect waves-light"
                          height="150px"
                          onClick={(e) => {
                            e.preventDefault()
                            if (images.length <= 4) {
                              setImages([...images, { path: 'services/Default.png' }])
                            }
                          }}
                        >
                          +
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: '40px' }}>
                  <div className="col-lg-12">
                    {isSuccess ? (
                      <div className="alert alert-success" role="alert">
                        {provider_services('file_successfully_uploaded')}
                      </div>
                    ) : null}
                    {!isSuccess ? (
                      <>
                        <button type="submit" className={`btn btn-gradient-success waves-effect waves-light ${isSubmitting ? 'disabled' : ''}`}>
                          {isSubmitting ? provider_services('please_wait') : provider_services('save')}
                        </button>
                        <button type="button" className="btn btn-gradient-info waves-effect waves-light" onClick={() => navigate(-1)} style={{ marginLeft: '10px' }}>
                          {provider_services('cancel')}
                        </button>
                      </>
                    ) : (
                      <button onClick={() => navigate(-1)} type="button" className="btn btn-gradient-info waves-effect waves-light">
                        {provider_services('cancel')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ManageServices
