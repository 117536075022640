import * as yup from 'yup';
import { parsePhoneNumber, isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

const dummyDOB = '1920-01-01';
export const PatientProfileSchema = yup
  .object({
    FirstName: yup
      .string()
      .required('required_error')
      .min(2, 'name_min_length_error')
      .max(50, 'name_max_length_error')
      .matches(/^[A-Za-z\s]+$/, 'name_format_error'),
    MiddleName: yup.string(),
    // .required('required_error')
    // .min(2, 'name_min_length_error')
    // .max(50, 'name_max_length_error')
    // .matches(/^[A-Za-z\s]+$/, 'name_format_error'),
    LastName: yup
      .string()
      .required('required_error')
      .min(2, 'name_min_length_error')
      .max(50, 'name_max_length_error')
      .matches(/^[A-Za-z\s]+$/, 'name_format_error'),
    ContactInfo: yup
      .string()
      .required('required_error')
      .test('is-valid-phone-number', 'mobile_error', (value) => {
        if (!value) return false // if no value, fail validation
        //return isPossiblePhoneNumber(value);
        return isValidPhoneNumber(value) // validate phone number using react-phone-number-input
      }),
    Address1: yup.string().required('required_error').max(255, 'address_max_length_error'),
    Address2: yup.string().max(255, 'address_max_length_error'),
    CountryID: yup.string().required('required_error'),
    Province: yup.string().required('required_error'),
    City: yup.string().required('required_error'),
    ZIPCode: yup
      .string()
      .required('required_error')
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'zipcode_error'),
    LocalTimeZone: yup.string().required('required_error'),
    DateOfBirth: yup
      .string()
      .required('required_error')
      .test('is-not-dummy-date', 'required_error', function (value) {
        return value !== dummyDOB;
      })
      .test('is-not-in-future', 'no_future_dob', function (value) {
        const isValidDate = /^(\d{4})-(\d{2})-(\d{2})$/.test(value)
        if (!isValidDate) return false
        const selectedDate = new Date(value)
        const currentDate = new Date()
        return selectedDate <= currentDate
      })
      .test('is-at-least-11-years-old', 'min_11_years_old', function (value) {
        const isValidDate = /^(\d{4})-(\d{2})-(\d{2})$/.test(value)
        if (!isValidDate) return false
        const selectedDate = new Date(value)
        const currentDate = new Date()
        const ageDifferenceInYears = currentDate.getFullYear() - selectedDate.getFullYear()
        return ageDifferenceInYears >= 11
      }),
  })
  .required()

export default PatientProfileSchema
