//
//---------------------------------------------------------

export const fReverseStr = (str) => { return str.split('').reverse().join(''); };
export const fBase64Decode = (str) => { return atob(str); };
export const fEscapeRegExp = (strToEscape) => {
  return strToEscape.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
};
export const fStrReplaceAll = (str, strWith, checkCase=false) => {
  /*/Regex may lead to infinite loop (say when strWith=='')..
  //string.replace(/quick/gi, '\\$&'); //'<b>$&</b>');
  'This iS IIS'.replace(/is/ig, 'as');
  //const strEsc = fEscapeRegExp(str);
  //const regStr = new RegExp(strEsc, 'gi');
  //return this.replace(regStr, strWith);

  if(checkCase) {
    //
  } else {
    //return this.split(str).join(strWith);
  }*/
};
export const fGetTrimmedText = (txt='', maxLen=30) => {
  return txt?.length > maxLen ? txt.substr(0, maxLen-2) + '..' : txt
};
export const fGetUrlFileName = (url) => {
  //new URL(url).pathname.split('/').pop();
  return url.split('#')[0].split('?')[0].split('/').pop();
};
export const fGetFileExt = (fName) => {
  return fName?.length ? fName.split('.').pop() : null;
};

