import moment from 'moment'
import moment_tz from 'moment-timezone'
import { fGetArrTimeZone } from '../../utils';
//---------------------------------------------------------

function HMFormat(minutes) {
  let offsetoperator = ''
  if (minutes > 0) {
    offsetoperator = '+'
  } else if (minutes < 0) {
    offsetoperator = '-'
    minutes = minutes * -1
  }
  var hours = Math.floor(minutes / 60)
  // Getting the minutes.
  var min = minutes % 60
  let dig = ''
  if (min < 10) {
    dig = '0'
  }
  return offsetoperator + hours + ':' + dig + min
}

function hourFormat(minutes) {
  return HMFormat(minutes * -1)
}

function TimeZoneSelect({ setTimeZone, value, disabled = false, required = false, className, filterCountryCode=null, elStyle=null }) {
  const modifiedTimezoneOptions = fGetArrTimeZone(filterCountryCode);
  //console.log('modifiedTimezoneOptions', modifiedTimezoneOptions);
  if(!elStyle) {
    elStyle = { marginLeft: '10px', marginRight: '20px', maxWidth: 400 };
  }

  return (
    <div className="row">
      <select
        className={className}
        disabled={disabled}
        required={required}
        value={value}
        style={elStyle}
        onChange={(e) => {
          setTimeZone(e.target.value)
        }}
        name='selTz'
        id='selTz'
      >
        <option>Select a Timezone</option>
        {modifiedTimezoneOptions.map((timezone, index) => (
          <option key={index} value={hourFormat(timezone.offset)}>
            UTC {hourFormat(timezone.offset)} {timezone.name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default TimeZoneSelect
