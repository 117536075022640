import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
//import { persistReducer } from "redux-persist";
//import storage from "redux-persist/lib/storage";
//import patientReducer from './slice/patient'
//import patientReducer from 'redux/slice/patient';
import patientReducer from 'v2/src/lib/services/patient/patientSlice';
//import { userApi } from './api/User'
import nHAppReducer, { nHApi, //slNameReducer
} from 'v2/src/lib/services/public/nHApi';
import { authApi } from 'v2/src/lib/services/auth/authApi';
import { nHDocumentApi } from 'v2/src/lib/services/nHDocumentApi';
import { geoApi } from 'v2/src/lib/services/geoApi';
import { profileApi } from 'v2/src/lib/services/profileApi';
import { addressApi } from 'v2/src/lib/services/addressApi';
//import { userApi } from 'v2/src/lib/services/auth/authApi';
import authReducer from 'v2/src/lib/services/auth/authSlice';
//---------------------------------------------------------

const appStore = configureStore({
  reducer: {
    [nHApi.reducerPath]: nHApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [nHDocumentApi.reducerPath]: nHDocumentApi.reducer,
    [geoApi.reducerPath]: geoApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [addressApi.reducerPath]: addressApi.reducer,
    auth: authReducer,
    nHDocumentApi: nHDocumentApi,
    geoApi: geoApi,
    profileApi: profileApi,
    addressApi: addressApi,
    patient: patientReducer,
  },
  middleware: (gDM) => gDM().concat([
      nHApi.middleware,
      authApi.middleware,
      nHDocumentApi.middleware,
      geoApi.middleware,
      profileApi.middleware,
      addressApi.middleware,
      //userApi.middleware,
  ]),
});

setupListeners(appStore.dispatch);
//export const persistor = persistStore(appStore);
export default appStore;
