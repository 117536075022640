//import { createSlice, current } from "@reduxjs/toolkit";
import { nHApi } from 'v2/src/lib/services/public/nHApi';
import { USER_TYPES } from 'v2/src/config/appConfig';
//---------------------------------------------------------
/*Primary Data
{{url}}/profile/10?relations[]=addresses 

PhoneNum by User/Update

{{url}}/profile/{{profile_id}}
profilePic, gender etc

{{url}}/address/4

document
*/

export const addressApi = nHApi.injectEndpoints({ endpoints: builder => ({
  profileAddressList: builder.query({
    query: (params) => {
      //{{url}}/addresses?profile_id=3
      let profileId =  params?.profileId;
      let aUrl = '/addresses/?profile_id=' + profileId;

      return { url: aUrl};
    },
  }),
  addressDetails: builder.query({
    query: (params) => {
      let aUrl = '/address/' + params?.addressId;

      return { url: aUrl};
    },
  }),
  addProfileAddress: builder.mutation({
    query: (params) => {
      return {
        //profile_id, address_line_1, address_line_2, country, state, city,
        //zipcode, lat, long, is_primary
        url: '/address',
        method: 'POST',
        body: params,
        //validateStaus: (response, result) =>
        //  response.status === 200 && !result.isError,
      }
    },
  }),
  setAddress: builder.mutation({
    query: (params) => {
      return {
        //address_line_1, address_line_2, country, state, city,
        //zipcode, lat, long, is_primary
        url: '/address/' + params.addressId,
        method: 'POST',
        body: params,
      }
    },
  }),
  deleteAddress: builder.mutation({
    query: (params) => {
      return {
        url: '/address/'+params.addressId,
        method: 'DELETE',
      };
    },
  }),

}), });

export const {
  useProfileAddressListQuery, useAddressDetailsQuery,
  useAddProfileAddressMutation, useSetAddressMutation,
  useDeleteAddressMutation
} = addressApi;
//---------------------------------------------------------

