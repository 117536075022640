//import { createSlice, current } from "@reduxjs/toolkit";
import { nHApi } from 'v2/src/lib/services/public/nHApi';
import { USER_TYPES } from 'v2/src/config/appConfig';
//---------------------------------------------------------
/*Primary Data
{{url}}/profile/10?relations[]=addresses 

PhoneNum by User/Update

{{url}}/profile/{{profile_id}}
profilePic, gender etc

{{url}}/address/4

document
*/

export const profileApi = nHApi.injectEndpoints({ endpoints: builder => ({
  userProfile: builder.query({
    query: (params) => {
      let userId =  params?.userId;
      if(!userId) {
        //userId = this.state.
      }
      let arrRelations = params.arrRelations ||
        [ 'user', 'role', 'language', //'provider', 'patient',
          'addresses',
        ];
      let relationQS = '?';
      arrRelations.forEach(pRel => {
        relationQS += 'relations[]='+pRel+',';
      });
      let aUrl = '/profile/' + params.profileId + relationQS;

      return { url: aUrl};
      //return { url: aUrl, params: { v1, v2 },};
    },
  }),
  userDetails: builder.query({
    query: (params) => {
      let userId =  params?.userId;
      if(!userId) {
        //userId = this.state.
      }
      let aUrl = '/profile/' + userId + '?relations[]=addresses';

      return { url: aUrl};
      //return { url: aUrl, params: { v1, v2 },};
    },
  }),
  setUser: builder.mutation({
    query: (params) => {
      return {
        //first_name, middle_name, last_name, phone
        url: '/user/' + userId,
        method: 'POST',
        body: params,
        //validateStaus: (response, result) =>
        //  response.status === 200 && !result.isError,
      }
    },
  }),
  userProfileList: builder.query({
    query: (params) => {
      //profiles?user_id=2&relations[]=role&relations[]=provider&relations[]=addresses
      if(params?.profileId) {
        userRole = params?.profileId;
      }
      let aUrl = '/profiles';

      return { url: aUrl, params: params};
    },
  }),
  userProfileDetail: builder.query({
    query: (params) => {
      //profile/10?relations[]=language&relations[]=provider&relations[]=patient&relations[]=addresses
      const  profileId = params?.profileId;
      let aUrl = '/profiles/'+profileId;

      return { url: aUrl, params: params};
    },
  }),
  setUserProfile: builder.mutation({
    query: (params) => {
      return {
        //profile_pic, date_of_birth, gender, timezone
        url: '/profile/' + params.profileId,
        method: 'POST',
        body: params,
        //validateStaus: (response, result) =>
        //  response.status === 200 && !result.isError,
      }
    },
  }),

}), });

export const {
  useLazyUserDetailsQuery,
  useUserProfileQuery, useLazyUserProfileQuery, useSetUserMutation,
  useUserProfileListQuery, useUserProfileDetailQuery,
  useSetUserProfileMutation
} = profileApi;
//---------------------------------------------------------

