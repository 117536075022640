export const APP_ENV = process.env.REACT_APP_APP_ENV
//export const APP_URL = process.env.PUBLIC_URL;//Useless as it is Blank
export const APP_BASE_URL = process.env.REACT_APP_URL;
export const APP_NAME = 'NIU-Health';
export const NH_API_BASE_URL = process.env.REACT_APP_API_URL;
export const NH_API_BASE_URL_V2 = process.env.REACT_APP_API_URL_V2;
export const NS_API_BASE_URL = process.env.REACT_APP_NIU_SA_API_URL;
export const NS_APP_BASE_URL = process.env.REACT_APP_NIU_SOCIAL_FE_URL;

//export const NH_ASSETS_BASE_URL = process.env.REACT_APP_AWS_BUCKET;
export const NH_ASSETS_BASE_URL = process.env.PUBLIC_URL;

export const USER_TYPES = {
  provider: 'provider', patient: 'patient',
  //support:'support', driver: 'driver', admin: 'admin',
};
export const DEF_PROFILE_PIC_URL = '';
export const NS_DEF_ORG_ID = process.env.REACT_APP_SA_DEF_ORG_ID;
export const NS_DEF_ORG_GROUP_ID = process.env.REACT_APP_SA_DEF_ORG_GROUP_ID;
export const DEF_LANGUAGE_CODE = 'en-US';//'en'//'es'
export const DEF_COUNTRY_ID = 1;//232;//USA
export const DEF_COUNTRY_CODE = 'USA';//US
export const DEF_COUNTRY_NAME = 'United States'; //of America
//DEF_TZ 'Pacific\/Honolulu'
export const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

export const FLG_USE_SWAL = false;
