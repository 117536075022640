import useAuth from './useAuth'
import Cookies from 'js-cookie'

const useLogout = () => {
  const { setAuth } = useAuth()

  const logout = async () => {
    setAuth({})
    Cookies.remove('email')
    Cookies.remove('name')
    Cookies.remove('accessToken')
    Cookies.remove('userId')
    Cookies.remove('userType')
    Cookies.remove('isLoggedIn')
  }
  return logout
}

export default useLogout
