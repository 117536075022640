import { useState } from 'react'
import useAuth from '../../hooks/useAuth'

const cardCss = { height: '90%', minHeight: '20%'};
const greetUsrCss = {
  //textWrap: 'nowrap',
  whiteSpace:'nowrap', overflow: 'hidden',
  fontSize: '2.5vw',
  //fontSize: '2rem',
  //fontSize: '130%',
  //fontSize: '3.0cqmin'
};
function WelcomeCard() {
  const { auth } = useAuth()
  const [greeting, setGreeting] = useState('Aloha ')
  return (
    <div className='card' style={cardCss}>
      <div className='card-body' style={{height: '100%'}}>
        <div className='jumbotron mb-0 bg-light' style={{height: 'inherit',}}>
          <h1 className='display-4' style={greetUsrCss}>
            {greeting}
            {auth?.name}!
          </h1>
          <p className='lead'>Have a nice day at work!</p>
          {/* <hr className="my-4" /> */}
        </div>
      </div>
    </div>
  )
}

export default WelcomeCard
