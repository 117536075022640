import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
//import { APP_URL, AWS_BUCKET, AWS_BUCKET_SERVICES } from 'constants';
import { fGetAUserInf, } from 'v2/src/lib/appUtils';
import { InsuranceStatusText } from './appProfileFx';
import { LottieSpinner } from 'v2/src/core';
//import { StatusTextInsurance2, StatusTextInsurance3 } from 'components/status/Status';
import CardItem from 'components/cards/Card';
import imgLogo from 'assets/images/niuHealthLogo.svg';
import {
  useNHDocumentTypesQuery, useUserDocumentListQuery,
  useAddUserDocumentMutation, useDeleteUserDocumentMutation,
} from 'v2/src/lib/services/nHDocumentApi';
//---------------------------------------------------------

export default function InsuranceDocumentList({ flgAsComponent = false }) {
  let aUData = fGetAUserInf();//console.log('aUData', aUData);
  const fNavigate = useNavigate();

  const { t: patient_insurance } = useTranslation('patient_insurance');
  const [stPgMsg, setStPgMsg] = useState('');
  const [stProcessing, setStProcessing] = useState(false);
  const [stPgHasErr, setStPgHasErr] = useState(false);
  //const [patientID, setPatientID] = useState('')
  const [stHasNoInsurance, setStHasNoInsurance] = useState(0);

  const gDocType = 'insurance';
  const arrValidDocTypeId = [];
  //const aUserEMail = aUData.eMail;
  const aUserId = aUData?.userIdNH;
  const aUserProfileId = aUData?.details?.profileId;
  //---------------------------------------------------------

  const [fDelUserDocument] = useDeleteUserDocumentMutation();

  let objNHDocTypesData = [];
  const { data: nHDocTypesApiData, //refetch: fRefetchDocTypes,
    isLoading: nHDocTypesWait, isFetching: nHDocTypesWait2,
    isError: nHDocTypesErr,//isSuccess: nHDocTypesOK,
  } = useNHDocumentTypesQuery(
    { userType: aUData?.userType, category: gDocType },
    //{refetchOnMountOrArgChange: true}
  );
  if (nHDocTypesApiData?.data?.length) {//.filter(dT=>dT?.id<7) || [];
    objNHDocTypesData = nHDocTypesApiData?.data || [];
    objNHDocTypesData.forEach(dTI => {
      dTI?.category === gDocType &&
        arrValidDocTypeId.push(dTI.id);
    });
  }
  objNHDocTypesData && console.log('objNHDocTypesData', objNHDocTypesData);

  let objUserInsDocsData = [];
  const { data: userInsDocsApiData, //refetch: fRefetchUserInsDocs,
    isLoading: userInsDocsWait, isFetching: userInsDocsWait2,
    isError: userInsDocsErr, error: userInsDocsErrData, isSuccess: userInsDocsOK,
  } = useUserDocumentListQuery({ profileId: aUserProfileId },
    { skip: aUserProfileId === null, refetchOnMountOrArgChange: true }
  );
  if (userInsDocsErrData && userInsDocsErrData?.status === false &&
    userInsDocsErrData?.message?.toLowerCase() === 'no documents found'
  ) {
    objUserInsDocsData = [];
  } else if (userInsDocsApiData?.data?.length) {
    objUserInsDocsData = userInsDocsApiData?.data.filter(uD =>
      arrValidDocTypeId.includes(uD.document_type_id));
  } console.log('userInsDocsErrData', userInsDocsErrData);
  objUserInsDocsData && console.log('userInsDocsData_' + userInsDocsErr, objUserInsDocsData);

  const isPageLoading = (
    //nHDocTypesWait || nHDocTypesWait2 ||
    userInsDocsWait || userInsDocsWait2
  );
  const fResetProcessing = (flgClearPgMsg = false) => {
    setStProcessing(false);
    //if(flgClearPgMsg) {
    setStPgMsg(''); setStPgHasErr(false);
    //}
  };
  //---------------------------------------------------------

  const fSetNoInsurance = (e) => {
    const chkB = e.target;//document.getElementById('chkNoInsurance');
    if (chkB) {
      chkB?.checked ? setStHasNoInsurance(1) : setStHasNoInsurance(0);
    }
  };

  if (stHasNoInsurance === 1) {
    Swal.fire({
      //icon: 'info', type: 'warning',
      //text: "Let's have subscription",
      imageUrl: imgLogo, imageHeight: 100,
      //html: '<div className="d-flex justify-content-center"><LottieSpinner size={200} /></div>',
      html: `${patient_insurance('msg_subscription_redirect')}`,
      showConfirmButton: false, showCancelButton: false,
      //allowOutsideClick: false
      //timer: 1500,
    });//.then();
    setTimeout(() => {
      setStHasNoInsurance(2);
      Swal.close();
      fNavigate('/patient/subscription');//, { replace: true });
    }, 3000);
  }
  const pgCtnCss = flgAsComponent ? {
    //maxHeight: '22rem'. //minHeight: '70vh',
    width: '100%',
    position: 'relative',
    padding: '0 15px 60px 15px',
  } : {};

  const loaderJsx = (
    <div className="d-flex justify-content-center">
      <LottieSpinner size={200} />
    </div>
  );
  //---------------------------------------------------------  

  return (//style={{marginTop: '-70px'}}
    <div id='ctnProffInsDoc'>
      {/* {(list.length===0)?
      <div className='row figmaFirstBox'>
        <div className='col-sm-12'>
          <div role='alert'
            className='alert alert-warning alert-warning-shadow mb-0 alert-dismissible fade show'                
          >
            <button type='button' className='close'
              data-dismiss='alert' aria-label='Close'
            >
              <span aria-hidden='true'>
                <i className='mdi mdi-close'></i>
              </span>
            </button>
            You currently have no active insurance documents submitted. Please upload a 
            document. If you don’t have insurance, please subscribe to
            <a href=''>our monthly plan</a>
          </div>
        </div>
      </div>:null
      } */}

      {/*<h4 className="header-title mt-0 mb-3">{patient_insurance('insurance_folders')}</h4>*/}

      <div className="file-box-content">
        {objUserInsDocsData?.length === 0 ? (
          <>
            <CardItem>
              {isPageLoading ? (
                <div className="d-flex justify-content-center">
                  <LottieSpinner size={200} />
                </div>
              ) : (
                <>
                  {patient_insurance('no_insurance_folders')}<br />
                  {patient_insurance('msg_upload_insurance')}
                </>
              )}
            </CardItem>
          </>
        ) : (<></>)}
        {objUserInsDocsData?.map((uDoc, idx) => (
          <Link // className="btn-success waves"
            key={idx}
            style={{ background: 'none', marginLeft: '2px' }}
            to={`/patient/insurance/folders/${uDoc.id}`}
            state={{ data: uDoc }}
          // onClick={() => Swal.fire(
          //   {
          //     html: `Front Page<br>
          //    <img src='${AWS_BUCKET_SERVICES}insurance/${aUserId}/${uDoc.BucketName}/${uDoc.FrontImage}' />`
          //   }
          // )}
          >
            <div className="file-box">
              {/* <i className='dripicons-download file-download-icon'></i> */}

              <div className="text-center">
                <img width={'51px'} height={'66px'} style={{ objectFit: 'cover' }}
                  src={uDoc?.file_url} />
                <i className={uDoc?.status == '1' ?
                  'far fa-folder text-success ml-3' :
                  uDoc?.status == '2' ?
                    'far fa-folder text-danger ml-3' :
                    'far fa-folder text-purple ml-3'} />
                <h6 className="text-truncate">{uDoc.id}</h6>
                {/* <StatusTextInsurance3 status={uDoc.archive}/><br/> */}
                <InsuranceStatusText status={uDoc?.status} />
                <br />
                {/*<div className='virtualvisitbadge btn-round btn-purple m-1 p-1'>
                  {uDoc.status==="0" ?
                    "For Approval" :
                    (uDoc.status==="1") ?
                    "Approved":"Rejected"
                  }<br/>
                </div>*/}
                <small className="text-muted">
                  {uDoc?.status_updated_at &&
                    'Uploaded ' +
                    moment(uDoc?.status_updated_at.replace(/-/g, '/').slice(0, 19)).
                      format('hh:mm a MM/DD/YY')
                  }{/* 06 March 2022 / 5MB */}
                </small>
              </div>
            </div>
          </Link>
        ))}

        {/*<div className='file-box'>
          <a href='#' className='download-icon-link'>
            <i className='dripicons-download file-download-icon' />
          </a>
          <div className='text-center'>
            <i className='far fa-file-code text-danger' />
            <h6 className='text-truncate'>Insurance.pdf</h6>
            <small className='text-muted'>15 March 2022 / 8MB</small>
          </div>
        </div>*/}
      </div>

      <div className='padS dFlexC'>
        <div className='xdInFlex'>
          {/*<Link to="../profile">
          <button type="button"
            className="btn btn-success btn-round waves-effect waves-light mt-2 m-2">
            {patient_insurance('manage_id')}
          </button>
        </Link>*/}
          {objUserInsDocsData?.length === 0 && (
            <span className="checkbox checkbox-success">
              <input id="chkNoInsurance" type="checkbox" checked={stHasNoInsurance > 0}
                onChange={(e) => fSetNoInsurance(e)} />
              <label htmlFor="chkNoInsurance">
                {patient_insurance('has_no_insurance')}
              </label>
            </span>
          )}
        </div>

        <div className='dxInFlex'>
          <Link to={'/patient/insurance/upload'}>
            <button type="button" className="float-right btn btn-success btn-round waves-effect waves-light mt-2 m-2">
              {patient_insurance('new_insurance')}
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
};
