import { useEffect } from 'react'
import TableCard from '../../../../components/table/Tables'
import { AWS_BUCKET_SERVICES } from '../../../../constants'
import { NavLink } from 'react-router-dom'
import useAuth from '../../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import useGetModuleList from '../../../../hooks/api/useGetModuleList'
import useDeleteModule from '../../../../hooks/api/useDeleteModule'

const Modules = (props) => {
  const { auth } = useAuth()
  const { t: provider_modules } = useTranslation('provider_modules')

  const { data: moduleList, getModuleList, loading: moduleListLoading } = useGetModuleList()
  const { data: deleteModuleData, deleteModule, loading: deleteModuleLoading } = useDeleteModule()

  /*Get the list of modules*/
  useEffect(() => {
    getModuleList({ Email: auth.email || Cookies.get('email') })
  }, [])

  /*Delete a languages*/
  const deleteLanguageHandler = (ModId) => {
    Swal.fire({
      title: `${provider_modules('delete_module')}`,
      text: `${provider_modules('confirm_delete_module')}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `${provider_modules('delete')}`,
      cancelButtonText: `${provider_modules('cancel')}`,
    }).then((result) => {
      if (result.value) {
        deleteModule({ apiParams: { Email: auth.email || Cookies.get('email') }, ModId })
      }
    })
  }

  useEffect(() => {
    if (deleteModuleData.Status) {
      getModuleList({ Email: auth.email || Cookies.get('email') })
    }
  }, [deleteModuleData.Status])

  return (
    <div className="container-fluid pt-5">
      <div className="tab-content detail-list" id="pills-tabContent">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="translations_tab" data-toggle="pill" href="#translations">
                      Translations
                    </a>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/provider/languages" className="nav-link">
                      Languages
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-pane fade show active" id="translations">
          <div className="row">
            <div className="col-md-12 mb-3">
              <NavLink to={'add'} className="btn btn-success waves-effect waves-light float-right pl-5 pr-5">
                {provider_modules('add')}
              </NavLink>
            </div>
            <TableCard headers={[provider_modules('module'), provider_modules('filename'), provider_modules('num_of_tags'), provider_modules('action')]}>
              {moduleListLoading && <h5>{provider_modules('loading')}</h5>}

              {moduleList.Status &&
                moduleList['Data'].map((module) => (
                  <tr key={module.Id}>
                    <td>{module.Name}</td>
                    <td>{module.Filename}</td>
                    <td>{module.TagCount}</td>
                    <td>
                      <NavLink to={`edit/${module.Id}`} title={provider_modules('edit_module')} className="mr-5">
                        <i className="fa fa-edit"></i>
                      </NavLink>

                      <a href="#" onClick={() => deleteLanguageHandler(module.Id)}>
                        <i className="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                ))}

              {!moduleListLoading && !moduleList.Status && <h5>{provider_modules('something_went_wrong')}</h5>}
            </TableCard>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modules
