//import {  } from 'core';
//---------------------------------------------------------

export default function PageMessage({text, type='info', size='S'}) {//isError
  //isError = isError ?? false;
  let txCls;// = isError ? 'inL mTxCancel': 'inL mTxOK';
  switch(type.toLowerCase()) {
    case 'err': case 'error': txCls = 'mTxCancel'; break;
    case 'info': default: txCls = 'mTxOK'; break;
  }
  txCls = 'inL ' + txCls;
  /*size = (size ?? 'S').toUpperCase();
  switch(size) {
    case 'L': size = '1.2rem'; break;
    case 'M': size = '1rem'; break;
    case 'S': default: size = '0.8rem'; break;
  }
  return (
    <p align='center' style={{
        fontSize: size, textAlign: 'center', padding: '0.2rem',
        //color: stAjaxStep === -1 ? '#e92425' : '#78c931',
        color: isError ? '#e92425' : '#78c931',
      }}
    >{text}</p>
  );*/
  return (
    <p className={txCls}>
      {text}
    </p>
  );
}