import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import CardItem from '../../../components/cards/Card'
import LottieFailed from '../../../components/lottie/LottieFailed'
import RingLoading from '../../../components/lottie/RingLoading'
import { StatusTextInsurance2, StatusTextInsurance3 } from '../../../components/status/Status'
import { AWS_BUCKET, AWS_BUCKET_SERVICES } from '../../../constants'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useTranslation } from 'react-i18next'
//import { APP_URL } from '../../../constants';
//import imgLogo from '../../../assets/images/nu-health-verticle-logo.png';
import imgLogo from 'assets/images/niuHealthLogo.svg';
//---------------------------------------------------------

function Insurance({flgAsComponent=false}) {
  const navigate = useNavigate()
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const [errMsg, setErrMsg] = useState(null)
  const [list, setList] = useState([])
  const [patientID, setPatientID] = useState('')
  const [stHasNoInsurance, setStHasNoInsurance] = useState(0);
  const { t: patient_insurance } = useTranslation('patient_insurance')

  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    setIsLoading(true)
    async function getList() {
      await axiosPrivate
        .post(
          // getMyInsuranceBuckets
          'getMyInsuranceBuckets',
          { Email: auth.email },
          {
            signal: controller.signal,
          }
        )
        .then((res) => {
          console.log(res)
          const { Status, Data: data, Message } = res.data

          if (Status) {
            setIsLoading(false)
            setList(data.Buckets)
            setPatientID(res.data.Data.PatientId)
          } else {
            throw new Error(Message)
          }
        })
        .catch((err) => {
          setIsLoading(false)
          //console.error(err)
          setErrMsg(err.message)
        })
    }

    isMounted && getList()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const fSetNoInsurance = (e) => {
    const chkB = e.target;//document.getElementById('chkNoInsurance');
    if(chkB) {
      chkB?.checked ? setStHasNoInsurance(1) : setStHasNoInsurance(0);
    }
  };

  if(stHasNoInsurance === 1) {
    1 && Swal.fire({
      //icon: 'info', type: 'warning',
      //text: "Let's have subscription",
      imageUrl: imgLogo, imageHeight: 100,
      //html: '<div className="d-flex justify-content-center"><RingLoading size={200} /></div>',
      html: `${patient_insurance('msg_subscription_redirect')}`,
      showConfirmButton: false, showCancelButton: false,
      //allowOutsideClick: false
      //timer: 1500,
    });//.then();
    setTimeout(() => {
      setStHasNoInsurance(2);
      Swal.close();
      navigate('/patient/subscription');//, { replace: true });
    }, 3000);
  }
  const pgCtnCss = flgAsComponent ? {
    //maxHeight: '22rem'. //minHeight: '70vh',
    width: '100%',
    position: 'relative',
	  padding: '0 15px 60px 15px',
  } : {};
  

  return (//style={{marginTop: '-70px'}}
    <div className="page-wrapper">
      <div //className="page-content"
        {...(flgAsComponent && { style: pgCtnCss })}
        {...(!flgAsComponent && { className: 'page-content' })}
      >
        <div className="container-fluid">
          {/* {(list.length===0)?
          <div className='row figmaFirstBox'>
            <div className='col-sm-12'>
              <div
                className='alert alert-warning alert-warning-shadow mb-0 alert-dismissible fade show'
                role='alert'
              >
                <button
                  type='button'
                  className='close'
                  data-dismiss='alert'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>
                    <i className='mdi mdi-close'></i>
                  </span>
                </button>
                You currently have no active insurance documents submitted. Please upload a 
                document. If you don’t have insurance, please subscribe to
                <a href=''>our monthly plan</a>
              </div>
            </div>
          </div>:null
          } */}
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <h4 className="page-title">{patient_insurance('insurance')}</h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/*<h4 className="header-title mt-0 mb-3">{patient_insurance('insurance_folders')}</h4>*/}

                  <div className="file-box-content">
                    {list?.length === 0 ? (
                      <>
                        <CardItem>
                          {isLoading ? (
                            <div className="d-flex justify-content-center">
                              <RingLoading size={200} />
                            </div>
                          ) : (
                            <>
                              { patient_insurance('no_insurance_folders') }<br/>
                              {patient_insurance('msg_upload_insurance')}
                            </>
                          )}
                        </CardItem>
                      </>
                    ) : (
                      <></>
                    )}
                    {list?.map((item, index) => (
                      <Link
                        // className="btn-success waves"
                        key={index}
                        style={{ background: 'none', marginLeft: '2px' }}
                        to={`/patient/insurance/folders/${item.BucketId}`}
                        // onClick={()=>Swal.fire(
                        //   {html:
                        //     `
                        //       Front Page<br>
                        //       <img src='${AWS_BUCKET_SERVICES}insurance/${patientID}/${item.BucketName}/${item.FrontImage}'></img>
                        //       `
                        //   })}
                      >
                        <div className="file-box">
                          {/* <i className='dripicons-download file-download-icon'></i> */}

                          <div className="text-center">
                            <img width={'51px'} height={'66px'} style={{ objectFit: 'cover' }} src={`${item.image1}`}></img>
                            <i className={item.status == '1' ? 'far fa-folder text-success ml-3' : item.status == '2' ? 'far fa-folder text-danger ml-3' : 'far fa-folder text-purple ml-3'}></i>
                            <h6 className="text-truncate">{item.BucketName}</h6>
                            {/* <StatusTextInsurance3 status={item.archive}/><br/> */}
                            <StatusTextInsurance2 status={item.status} />
                            <br />
                            {/* <div className='virtualvisitbadge btn-round btn-purple m-1 p-1'>
                            
                          {item.status==="0"?"For Approval":(item.status==="1")?"Approved":"Rejected"}<br/>
                          </div> */}
                            <small className="text-muted">
                              {'Uploaded ' + moment(item.DateUploaded.replace(/-/g, '/').slice(0, 19)).format('hh:mm a MM/DD/YY')}
                              {/* 06 March 2022 / 5MB */}
                            </small>
                          </div>
                        </div>
                      </Link>
                    ))}

                    {/* <div className='file-box'>
                      <a href='#' className='download-icon-link'>
                        <i className='dripicons-download file-download-icon'></i>
                      </a>
                      <div className='text-center'>
                        <i className='far fa-file-code text-danger'></i>
                        <h6 className='text-truncate'>Insurance.pdf</h6>
                        <small className='text-muted'>
                          15 March 2022 / 8MB
                        </small>
                      </div>
                    </div> */}
                  </div>

                  <div className='padS dFlexC'>
                    <div className='xdInFlex'>
                    {/*<Link to="../profile">
                      <button type="button" className="btn btn-success btn-round waves-effect waves-light mt-2 m-2">
                        {patient_insurance('manage_id')}
                      </button>
                    </Link>*/}
                    {list?.length === 0 && (
                      <span className="checkbox checkbox-success">
                        <input id="chkNoInsurance" type="checkbox" checked={stHasNoInsurance > 0}
                          onChange={(e)=>fSetNoInsurance(e)} />
                        <label htmlFor="chkNoInsurance">
                          {patient_insurance('has_no_insurance')}
                        </label>
                      </span>
                    )}
                    </div>

                    <div className='dxInFlex'>
                      <Link to={flgAsComponent ? '/patient/insurance/upload': "upload"}>
                        <button type="button" className="float-right btn btn-success btn-round waves-effect waves-light mt-2 m-2">
                          {patient_insurance('new_insurance')}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer text-center text-sm-left">&copy; 2022 NIU Health</footer>
      </div>
    </div>
  )
}

export default Insurance
