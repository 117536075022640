const notifs = [
  {
    type: 'order',
    subject: 'Your order is placed.',
    body: 'Dummy text',
    timeReceived: 4,
  },
  {
    type: 'meeting',
    subject: 'Meeting With Designers',
    body: 'Lorem Ipsum',
    timeReceived: 10,
  },
  {
    type: 'task',
    subject: 'Task Complete',
    body: 'Dolor Sit Amet',
    timeReceived: 10,
  },
  {
    type: 'deliver',
    subject: 'It is a long established fact that a reader...',
    body: 'Dolor Sit Amet',
    timeReceived: 10,
  },
  {
    type: 'success',
    subject: 'Payment Successful',
    body: 'The Success is in the payment.',
    timeReceived: 10,
  },
]
export default notifs
