// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
.deletable-chip {
  display: inline-flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 16px;
  padding: 4px 8px;
  margin: 4px;
}

.deletable-chip span {
  margin-right: 8px;
}

.deletable-chip button.close-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 0;
  margin: 0;
  color: #888;
}

.deletable-chip button.close-icon:hover {
  color: #f00;
}
`, "",{"version":3,"sources":["webpack://./src/components/filterTag/filterTag.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,oBAAoB;EACpB,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,cAAc;EACd,UAAU;EACV,SAAS;EACT,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":["/* styles.css */\n.deletable-chip {\n  display: inline-flex;\n  align-items: center;\n  background-color: #f0f0f0;\n  border-radius: 16px;\n  padding: 4px 8px;\n  margin: 4px;\n}\n\n.deletable-chip span {\n  margin-right: 8px;\n}\n\n.deletable-chip button.close-icon {\n  background: none;\n  border: none;\n  cursor: pointer;\n  font-size: 16px;\n  line-height: 1;\n  padding: 0;\n  margin: 0;\n  color: #888;\n}\n\n.deletable-chip button.close-icon:hover {\n  color: #f00;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
