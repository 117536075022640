import * as yup from 'yup'
import {
  nameMinLengthError,
  requiredError,
  nameMaxLengthError,
  nameFormatError,
  emailError,
  emailMaxLengthError,
  emailMinLengthError,
  mobileError,
  addressMaxLengthError,
  cardFullNameError,
  cvvLengthError,
} from '../constants'

export const SubscriptionPaymentSchema = yup
  .object({
    FirstName: yup
      .string()
      .required(requiredError)
      .min(2, nameMinLengthError)
      .max(50, nameMaxLengthError)
      .matches(/^[A-Za-z\s]+$/, nameFormatError),
    LastName: yup
      .string()
      .required(requiredError)
      .min(2, nameMinLengthError)
      .max(50, nameMaxLengthError)
      .matches(/^[A-Za-z\s]+$/, nameFormatError),
    Address: yup.string().required(requiredError).max(255, addressMaxLengthError),
    City: yup.string().required(requiredError),
    Country: yup.string().required(requiredError),
    Email: yup.string().email(emailError).required(requiredError).max(255, emailMaxLengthError).min(6, emailMinLengthError),
    number: yup.string().required(requiredError),
    name: yup
      .string()
      .required(requiredError)
      .matches(/^[A-Za-z\s]+$/, cardFullNameError),
    expiry: yup.string().required(requiredError),
    cvc: yup
      .string()
      .required(requiredError)
      .matches(/^[0-9]{3,4}$/, cvvLengthError),
  })
  .required()

export default SubscriptionPaymentSchema
