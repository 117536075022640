import { useEffect, useRef, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import axios from '../api/axios'
import { AWS_COGNITO_HOSTUI_DOMAIN, USERTYPE, APP_URL } from '../constants'
import useAuth from '../hooks/useAuth'
import LottieFailed from './lottie/LottieFailed'
import GreenLock from './lottie/LottieGreenLock'
import Cookies from 'js-cookie'

export function loader({ request }) {
  const url = new URL(request.url)
  const code = url.searchParams.get('code')

  if (!code || code === null) {
    // window.location.replace(AWS_COGNITO_HOSTUI_DOMAIN)

    window.location.replace('login')
  }

  return { code }
}

function AWSCallback() {
  const { setAuth } = useAuth()
  const navigate = useNavigate()
  const { code } = useLoaderData()
  const [errMsg, setErrMsg] = useState()

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  // const effectRun = useRef(false);
  // const [effectRun, setEffectRun] = useState()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    async function auth() {
      await axios
        .post(
          'exchangeNew',
          { Code: code },
          {
            signal: controller.signal,
          }
        )
        .then((res) => {
          const { Status, StatusCode, Message, TransactionType, Tokens, UserType, Email, Name } = res.data

          return {
            ...Tokens,
            transactionType: TransactionType,
            statusCode: Status || StatusCode,
            msg: Message,
            userType: UserType,
            email: Email,
            name: Name,
          }
        })
        .then(({ access_token, id_token, refresh_token, token_type, transactionType, statusCode, msg, userType, email, name, has_insurance }) => {
          if (!statusCode) {
            Cookies.remove('access_token')
            Cookies.remove('id_token')
            Cookies.remove('refresh_token')
            Cookies.remove('token_type')
            Cookies.remove('userType')
            Cookies.remove('email')
            Cookies.remove('name')
            // Cookies.remove('isLoggedIn',  false)

            setAuth({})

            throw new Error(msg)
          }

          Cookies.set('access_token', access_token)
          Cookies.set('id_token', id_token)
          Cookies.set('refresh_token', refresh_token)
          Cookies.set('token_type', token_type)
          Cookies.set('transactionType', transactionType)
          Cookies.set('userType', userType)
          Cookies.set('email', email)
          Cookies.set('name', name)

          Cookies.set('has_insurance', has_insurance)
          Cookies.set('isLoggedIn', true)
          setIsLoggedIn(true)

          isMounted &&
            setAuth({
              access_token,
              id_token,
              refresh_token,
              token_type,
              transactionType,
              userType,
              email,
              name,
              has_insurance,
              isLoggedIn,
            })

          navigate('/', { replace: true })
        })
        .catch((err) => {
          console.error(err)
          setErrMsg(err.message)
        })
    }
    auth()

    return () => {
      isMounted = false

      controller.abort()
    }
  }, [])

  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '100vw', height: '100vh' }}>
      {errMsg ? <LottieFailed /> : <GreenLock />}
      {errMsg ? (
        <div className="text-center">
          {/* <h4>{errMsg}</h4> */}
          <p>
            <button
              onClick={() => {
                window.location.replace(APP_URL + '/login')
              }}
              type="button"
              className="btn btn-success btn-round py-2 waves-effect waves-light figmaBigButton"
            >
              Back to Login
            </button>
          </p>
        </div>
      ) : (
        <div>Authenticating...</div>
      )}
    </div>
  )
}

export default AWSCallback
