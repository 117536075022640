import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import './styles.css'
import useGetLanguageList from '../../hooks/api/useGetLanguageList'
import useAuth from '../../hooks/useAuth'
import Cookies from 'js-cookie'
import { APP_URL } from '../../constants'

const CustomOption = ({ innerProps, label, data }) => (
  <div {...innerProps} className="custom-option">
    {data.flagIcon && <img src={data.flagIcon} alt={label} className="flag-icon" />}
    <span className="option-text">{label}</span>
  </div>
)

// Custom component to display both the selected flag and label
const SelectedOption = ({ innerProps, label, data, selectProps }) => {
  const handleClick = () => {
    selectProps.onMenuOpen()
  }

  return (
    <div {...innerProps} className="selected-option" onClick={handleClick}>
      {data.flagIcon && <img src={data.flagIcon} alt={data.label} className="selected-flag-icon" />}
      <span className="selected-label">{data.label}</span>
    </div>
  )
}

// Custom Input component that renders nothing
const CustomInput = () => null

const LanguageDropdown = ({ isDisabled, onChange, error, dropup }) => {
  const { auth } = useAuth()
  const { data: languageList, getLanguageList, loading: languageListLoading } = useGetLanguageList()

  // Define languageOptions as a state variable
  const [languageOptions, setLanguageOptions] = useState([])
  const [defaultLanguageValue, setDefaultLanguageValue] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  /* Get the list of languages */
  useEffect(() => {
    ;(async () => {
      try {
        let languages = await getLanguageList({ Email: auth.email || Cookies.get('email'), IsActive: 1 })

        if (languages.Status) {
          const options = languages['Data'].map((language) => ({
            value: language.LanguageCodeIetf,
            label: language.Name,
            flagIcon: APP_URL + '/assets/images/country_flags/' + language.FlagIcon,
          }))
          // Update languageOptions state with the fetched data
          setLanguageOptions(options)

          // Get the value from the 'i18next' cookie and set it as the default selected option
          const cookieValue = Cookies.get('i18next')
          setDefaultLanguageValue(cookieValue)
        }
      } catch (error) {
        // Handle any errors that occur during the API call.
        console.error('Error fetching language data:', error)
      } finally {
        // Set the loading state to false when data fetching is complete
        setIsLoading(false)
      }
    })()
  }, [auth.email])

  // Render the Select component only when data has been loaded
  if (isLoading) {
    return <div className="text-center">Loading...</div>
  }

  const menuPlacement = dropup ? 'top' : 'bottom' // Conditionally set menu placement
  const arrowStyles = dropup ? { transform: 'rotate(180deg)' } : {}

  return (
    <Select
      isDisabled={isDisabled}
      options={languageOptions}
      defaultValue={languageOptions.find((option) => option.value === defaultLanguageValue)} // Set the default value
      className={`react-select-container ${error ? 'is-invalid' : ''}`}
      onChange={onChange}
      menuPlacement={menuPlacement} // Set the menu placement
      styles={{
        control: (provided) => ({
          ...provided,
          width: '100%',
        }),
        singleValue: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
        }),
        option: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
          margin: '8px',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          width: '32px',
          height: '32px',
          color: 'black',
          ...arrowStyles,
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none',
        }),
      }}
      components={{
        Option: CustomOption,
        SingleValue: SelectedOption,
        Input: CustomInput,
      }}
    />
  )
}

export default LanguageDropdown
