import { useState, useEffect, useRef, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { fGetAUserInf } from 'v2/src/lib/appUtils';
import { fGetTrimmedText, fGetUrlFileName, fGetFileExt } from 'v2/src/lib/strUtils';
import { fCallNHApi, fCalcProfileIdDocsInf } from './appProfileFx';
import { LottieSpinner } from 'v2/src/core';
import CardItem from 'components/cards/Card';
import {
  useNHDocumentTypesQuery, useUserDocumentListQuery,
  useAddUserDocumentMutation, useDeleteUserDocumentMutation,
} from 'v2/src/lib/services/nHDocumentApi';
import VeriffIntegration from "components/Veriff/VeriffIntegration";
//---------------------------------------------------------

export default function IdDocumentForm() {
  let aUData = fGetAUserInf();//console.log('aUData', aUData);

  //const { t: error_message } = useTranslation('error_message');
  const { t: comp_profile } = useTranslation('comp_profile');
  //const [stDisableForm, setStDisableForm] = useState(false);
  const [stPgMsg, setStPgMsg] = useState('');
  const [stProcessing, setStProcessing] = useState(false);
  const [stPgHasErr, setStPgHasErr] = useState(false);
  const [stPIdDocsInf, setStPIdDocsInf] = useState([]);
  const [stIViewInf, setStIViewInf] = useState({ isOpen: false, iUrls: [], curr: null });
  const flgEditableForm = true;//!stDdisableForm;
  const gArrIdDocFileTypes = ['pdf', 'webp', 'png', 'jpeg', 'jpg', 'jiff', 'jfif'];
  const gMaxPIdDoc = 3;
  const gDocType = 'identification';
  //const aUserId = aUData?.userIdNH;
  //const aUserType = aUData?.userType;
  const aUserProfileId = aUData?.details?.profileId;
  const arrValidDocTypeId = [];
  //---------------------------------------------------------

  const [fAddUserDocument, { reset: fResetUDoc }] = useAddUserDocumentMutation();
  //const [fSetUserDocument] =  useSetUserDocumentMutation();
  const [fDelUserDocument] = useDeleteUserDocumentMutation();

  let objNHDocTypesData = [];
  const { data: nHDocTypesApiData, //refetch: fRefetchDocTypes,
    isLoading: nHDocTypesWait, isFetching: nHDocTypesWait2,
    isError: nHDocTypesErr,//isSuccess: nHDocTypesOK,
  } = useNHDocumentTypesQuery(
    { userType: aUData?.userType, category: gDocType },
    //{refetchOnMountOrArgChange: true}
  );
  if (nHDocTypesApiData?.data?.length) {//.filter(dT=>dT?.id<7) || [];
    objNHDocTypesData = nHDocTypesApiData?.data || [];
    objNHDocTypesData.forEach(dTI => {
      dTI?.category === gDocType &&
        arrValidDocTypeId.push(dTI.id);
    });
  }
  //objNHDocTypesData && console.log('objNHDocTypesData', objNHDocTypesData);

  let objUserIdDocsData = [];
  const { data: userIdDocsApiData, refetch: fRefetchUserIdDocs,
    isLoading: userIdDocsWait, isFetching: userIdDocsWait2,
    isError: userIdDocsErr, error: userIdDocsErrData, isSuccess: userIdDocsOK,
  } = useUserDocumentListQuery({ profileId: aUserProfileId },
    { skip: aUserProfileId === null, refetchOnMountOrArgChange: true }
  );
  if (userIdDocsErrData && userIdDocsErrData?.status === false &&
    userIdDocsErrData?.message?.toLowerCase() === 'no documents found'
  ) {
    objUserIdDocsData = [];
  } else if (userIdDocsApiData?.data?.length) {
    objUserIdDocsData = userIdDocsApiData?.data.filter(uD =>
      arrValidDocTypeId.includes(uD.document_type_id));
  }//console.log('userIdDocsErrData', userIdDocsErrData);
  //objUserIdDocsData && console.log('userIdDocsData_'+userIdDocsErr, objUserIdDocsData);
  //console.log('arrValidDocTypeId', arrValidDocTypeId);

  useEffect(() => {
    /*if(userIdDocsErr) {
      setStPgMsg('Error fetching user identification documents');
      //setStPgMsg([<p>Error fetching user identification documents</p>, <p>Please try again later..</p>]);
      setStPgHasErr(true);
    } else if(userIdDocsOK) {*///userIdDocsApiData?.length) {
    const idDocsInf = fCalcProfileIdDocsInf(gMaxPIdDoc, objUserIdDocsData);
    setStPIdDocsInf(idDocsInf);
    //}
  }, [userIdDocsApiData, userIdDocsErrData]);
  const fInitVerrifInContextFlow2 = () => {
    document.getElementById('ctnVeriffDoc1').style.visibility = 'visible';
  };
  const isPageLoading = (
    nHDocTypesWait || nHDocTypesWait2 ||
    userIdDocsWait || userIdDocsWait2
  );
  const fResetProcessing = (flgClearPgMsg = false) => {
    setStProcessing(false);
    //if(flgClearPgMsg) {
    setStPgMsg(''); setStPgHasErr(false);
    //}
  };
  //---------------------------------------------------------

  const fOpenImgViewer = (cPDoc, uDocsInf = []) => {
    if (uDocsInf?.length) {//fGetUserCurrDocImgListInf
      let currIdx, i = 0, arrIUrls = [];
      uDocsInf.forEach((uD) => {
        if (uD.id === cPDoc.id) { currIdx = i; } i++;
        uD?.docApiData?.file_url && arrIUrls.push(uD.docApiData.file_url);
        uD?.hasView && uD?.docFormData?.docFile &&
          arrIUrls.push(URL.createObjectURL(uD.docFormData.docFile));
      });
      arrIUrls?.length && setStIViewInf({
        isOpen: true, iUrls: arrIUrls, curr: currIdx || 0
      });
    }
  };
  const fCloseImgViewer = () => {
    setStIViewInf({ isOpen: false, iUrls: [], curr: null });
  };
  const fAddNewIdDocBox = () => {
    if (stPIdDocsInf?.shownIdDocCount < gMaxPIdDoc) {
      setStPIdDocsInf(prevSt => {
        const newSt = structuredClone(prevSt);
        //const arrInfData = newSt?.arrIdDocs;
        for (let i = 0; i < newSt?.arrIdDocs?.length; i++) {
          const pIdDInf = newSt?.arrIdDocs[i];
          if (!pIdDInf?.hasData && !pIdDInf?.hasView) {
            newSt.arrIdDocs[i]['hasView'] = true;
            newSt.shownIdDocCount++;
            break;
          }
        }//console.log('fAddNewIdDocBox', newSt);
        return newSt;
      });
    }
  };
  const fRemoveIdDocBox = (pDoc) => {
    //setStPgMsg('');
    //console.log('remPDoc', {pDoc, stPIdDocsInf});
    const arrIdDocs = structuredClone(stPIdDocsInf?.arrIdDocs);
    let fFound = false;
    arrIdDocs.forEach((pDN, idx) => {
      if (pDN.id === pDoc.id) {
        fFound = true;
        arrIdDocs[idx]['hasView'] = false;
        arrIdDocs[idx]['hasData'] = false;
        arrIdDocs[idx]['errCode'] = null;
        arrIdDocs[idx]['arrErrMsg'] = [];
        arrIdDocs[idx]['docFormData']['docTypeId'] = null;
        arrIdDocs[idx]['docFormData']['docFile'] = null;
      }
    });
    if (fFound && stPIdDocsInf.shownIdDocCount > 1) {
      setStPIdDocsInf(prevSt => {
        return {
          ...prevSt,
          shownIdDocCount: prevSt.shownIdDocCount - 1,
          arrIdDocs: arrIdDocs
        }
      });
    }
  };
  //---------------------------------------------------------

  const fValidateIdDocUploads = (arrFrmVals, upIdDocStat) => {
    let resp = true, upFileSize = 0, upFileSizeErrCode = null, arrErrMsg = [];
    //console.log('fValidateIdDoc1', {arrFrmVals, upIdDocStat, });
    //setStPgMsg('');
    if (upIdDocStat?.length) {
      //id: 'err_'+i, errCode: null, hasErr: false, arrErrMsg: [],
      upIdDocStat.forEach((eIdObj, idx) => {
        if (eIdObj?.hasView && eIdObj?.docFormData) {
          let hasUpFile = false, hasSelDocType = false, fExt = null;
          eIdObj.errCode = null;
          eIdObj.hasErr = false;
          eIdObj.arrErrMsg = [];

          hasUpFile = eIdObj?.docFormData?.docFile;
          if (hasUpFile?.name) {
            fExt = fGetFileExt(hasUpFile.name);
            upFileSize += parseInt(hasUpFile?.size);

            //console.log('upF'+eIdObj.id, hasUpFile.name, fExt);
            if (fExt && gArrIdDocFileTypes.includes(fExt)) { }
            else {
              resp = false; eIdObj.hasErr = true; eIdObj.errCode = 'e_3';
              //eIdObj.arrErrMsg = idx+' A3';
              eIdObj.arrErrMsg.push('Only JPEG / PNG / PDF files are allowed');
              //arrErrMsg.push(idx+' A3');
            }
          } else {
            resp = false; eIdObj.hasErr = true; eIdObj.errCode = 'e_2';
            //eIdObj.arrErrMsg = idx+' A2';
            eIdObj.arrErrMsg.push('Document file is not selected');
            //arrErrMsg.push(idx+' A2');
          }
          //----------------

          hasSelDocType = parseInt(eIdObj?.docFormData?.docTypeId);
          if (hasSelDocType !== NaN && hasSelDocType > 0) { }
          else {
            resp = false; eIdObj.hasErr = true; eIdObj.errCode = 'e_1';
            //eIdObj.arrErrMsg = idx+' A1';
            eIdObj.arrErrMsg.push('Document type is not selected');
            //arrErrMsg.push(idx+' A1');
          }
        }
      });

      if (upFileSize && parseInt(upFileSize / 1048576) > 10) {
        resp = false;
        upFileSizeErrCode = 'e_4';
        //arrErrMsg.push('The total size of ID document files cannot exceed 10 MB.');
      }

      if (!resp) {
        resp = { result: false, upIdDocStat, upFileSize, upFileSizeErrCode };//arrErrMsg,
      } else { resp = { result: true }; }
    } console.log('fValidateIdDoc2', { resp, upFileSize, upIdDocStat });

    return resp;
  };
  // const fHandleIdDocSubmit = async () => {
  //   let formData, idDocSaveResp, respValidIdDocs;// = false;
  //   console.log('fHandleIdDocSubmitIn', stPIdDocsInf);
  //   const frmInputs = [];
  //   const arrIdDocs = stPIdDocsInf?.arrIdDocs;

  //   setStPgMsg('');
  //   if (arrIdDocs?.length) {
  //     arrIdDocs.forEach(pDoc => {
  //       if (pDoc?.hasView && pDoc?.docFormData) {
  //         frmInputs.push({ id: pDoc.id, docFormData: pDoc?.docFormData });
  //       }
  //     });//console.log('frmInputs', frmInputs);

  //     if (frmInputs?.length) {
  //       respValidIdDocs = fValidateIdDocUploads(frmInputs, structuredClone(arrIdDocs));
  //     }

  //     if (respValidIdDocs?.result) {
  //       idDocSaveResp = {};
  //       setStProcessing(true);
  //       frmInputs.forEach(async idDocData => {
  //         //profile_id, document_type_id, file
  //         formData = new FormData();
  //         formData.append('profile_id', aUserProfileId);
  //         formData.append('document_type_id', idDocData?.docFormData.docTypeId);
  //         formData.append('file', idDocData?.docFormData.docFile);

  //         idDocSaveResp[idDocData.id] = await fCallNHApi('idDocAdd', fAddUserDocument, { inParams: formData });
  //         //console.log('idDocSaveResp_'+idDocData.id, idDocSaveResp);
  //       });

  //       setTimeout(() => {
  //         let respAll = true;
  //         Object?.keys(idDocSaveResp)?.forEach(r => {
  //           respAll = respAll && idDocSaveResp[r]['result'];
  //         }); console.log('idDocSaveResp_' + respAll, idDocSaveResp);
  //         fRefetchUserIdDocs();
  //         if (respAll) {
  //           //fRefetchUserIdDocs();
  //           Swal.fire({ icon: 'success', text: comp_profile('profile_id_doc_update_success') });
  //         } else {
  //           Swal.fire({ icon: 'error', text: 'Error saving identification document' });
  //           //throw new Error(Message);
  //         }
  //       }, 3500);
  //     } else {//if(!hasValidInputs) {
  //       //setStPgMsg(comp_profile('Error saving identification document. Please try again..'));
  //       //setStPgHasErr(true);
  //       //idDocSaveResp = false;
  //       //console.log('errRespValidIdDocs', respValidIdDocs);
  //       let arrErr = [], errMsg = '';
  //       if (respValidIdDocs?.upFileSizeErrCode === 'e_4') {
  //         arrErr.push('The total size of ID document files cannot exceed 10 MB.');
  //       }

  //       if (respValidIdDocs?.upIdDocStat) {
  //         /*respValidIdDocs.upIdDocStat.forEach((eIdObj, idx) => {
  //           if(eIdObj.hasErr) {//isViewAdded
  //             switch(eIdObj.errCode) {
  //               case 'e_1': arrErr.push('Document type ('+(idx+1)+') is not selected'); break;
  //               case 'e_2': arrErr.push('Document file ('+(idx+1)+') is not selected'); break;
  //               case 'e_3': arrErr.push('Only JPEG / PNG / PDF files are allowed'); break;
  //               default:
  //                 arrErr.push('There is some error. Please refresh the page & try again..');
  //                 break;
  //             }
  //           }
  //         });*/

  //         arrErr = [...new Set(arrErr)];
  //         const arrErr2 = [];
  //         if (arrErr.length > 0) {
  //           arrErr.forEach((errS, idx) => {
  //             //arrErr2.push(<p style={{paddingLeft: '10px'}} key={'err_'+idx}>{errS}</p>);
  //             arrErr2.push(<p key={'errPg_' + idx}>{errS}</p>);
  //           });
  //           //arrErr2.unshift(<p key={'err_ttl'}>{'Please fix below errors to continue:'}</p>);
  //           arrErr = arrErr2;
  //         }
  //       }

  //       //setStPIdDocsInf(respValidIdDocs?.upIdDocStat);
  //       setStPIdDocsInf(prevSt => {
  //         //console.log('currStPIdDocsInf', stPIdDocsInf);
  //         const newSt = {
  //           ...prevSt,//arrIdDocs: ...prevSt.
  //           //arrIdDocs: {...respValidIdDocs?.upIdDocStat}
  //           arrIdDocs: respValidIdDocs?.upIdDocStat
  //         };//console.log('newStPIdDocsInf', newSt);
  //         return newSt;
  //       });
  //       arrErr?.length && setStPgMsg(arrErr); //setStPgMsg(respValidIdDocs?.arrErrMsg);
  //     } console.log('idDocSaveResp', idDocSaveResp);
  //   }

  //   setTimeout(fResetProcessing, 3000);//setStProcessing(false);
  //   return idDocSaveResp;
  // };
  const fHandleDelIdDocSubmit = async (id) => {
    let idDocDelResp;
    if (id) {
      setStProcessing(true);
      idDocDelResp = await fDelUserDocument({ docId: id });
      //console.log('idDocDelResp', idDocDelResp);
      if (idDocDelResp?.data?.status) {
        fRefetchUserIdDocs();
        setStPgMsg(idDocDelResp?.data?.message);
      } else {
        //setStPgMsg(comp_profile('Error deleting identification document. Please try again..'));
        //setStPgHasErr(true);
      }
    } else {
      //setStPgHasErr(true);
      idDocDelResp = false;
    } console.log('idDocDelResp', idDocDelResp);
    setTimeout(fResetProcessing, 3000);//setStProcessing(false);
    return idDocDelResp;
  };
  //---------------------------------------------------------

  const errIdDocCss = { border: '2px dashed #f14b4b' };
  const btnClassName = 'btn btn-danger btn-round waves-effect waves-light image-close-icon';
  const fUploadCss = {
    width: '100%', textOverflow: 'clip',//'ellipsis',
    textSizeAdjust: '90%', //whiteSpace: 'nowrap',
    fontSize: '83%',//'calc(1vw + 1vh)',
  };

  const fGetSavedIdDocBoxJsx = (pDoc) => {
    const savedPIdDocId = pDoc?.id;
    const fName = pDoc?.docApiData?.file_url;
    let docTypeData = objNHDocTypesData?.filter(dT =>
      dT.id === pDoc?.docApiData?.document_type_id);
    docTypeData = docTypeData?.length ? docTypeData[0] : {};
    let fImgTitle = docTypeData?.name;
    if (!fImgTitle && fName) { fImgTitle = fGetUrlFileName(fName); }
    fImgTitle = fGetTrimmedText(fImgTitle, 20);
    //console.log('savedIdDocBoxData', {docTypeData, pDoc, fImgTitle, fName});

    return (//savedPIdDocId ?
      <div key={savedPIdDocId} id={'sIdDoc_' + savedPIdDocId}
        className="id-container card col-md-3"
      >
        <div className="document-type-dropdown">
          <label>Document Type : </label>
          <label className="image-close-icon float-right">
            <button type="button" className={btnClassName}
              onClick={() => { fHandleDelIdDocSubmit(savedPIdDocId?.replace('uIdDoc_', '')) }}
            ><FaTimes /></button>
          </label><br />
          <div className="mb-2">{docTypeData?.name}</div>
        </div>
        <div>
          <div className="image-preview mb-2"
            onClick={() => fOpenImgViewer(pDoc, stPIdDocsInf.arrIdDocs)}>
            <img className="lnkPt id-image" src={fName} alt={fImgTitle} />
          </div>
        </div>
      </div>
    )
  };

  const fGetNewIdDocBoxJsx = (pDoc, shownDocCount) => {
    const newPIdDocId = pDoc?.id;
    //console.log('fNewIdDocBoxInf_'+newPIdDocId, pDoc);
    const hasErr = pDoc?.hasOwnProperty('hasErr') && pDoc['hasErr'];
    const elDErr = hasErr && pDoc['arrErrMsg']?.length ? pDoc['arrErrMsg'] : [];
    const errJsx = [];
    if (elDErr?.length) {
      elDErr.reverse();
      elDErr.forEach((errS, idx) => {
        errJsx.push(<p className="m-1" key={'pIdDocErr_' + newPIdDocId + '_' + idx}>{errS}</p>);
      });
    }

    return (
      <div key={'kNewPIdDoc_' + newPIdDocId} id={'ctnNewPIdDoc_' + newPIdDocId}
        className="id-container card col-md-3"
      >
        <div className="padS" {...(hasErr && { style: errIdDocCss })}>
          <div className="document-type-dropdown">
            <label>Document Type : </label>
            {shownDocCount > 1 && //newPIdDocId !== 'new_0' &&
              <label className="image-close-icon float-right">
                <button type="button" className={btnClassName}
                  onClick={() => fRemoveIdDocBox(pDoc)}
                ><FaTimes /></button>
              </label>
            }
            {
              <select id='' name='' className="form-control mb-2"
                value={pDoc?.docFormData?.docTypeId || ''}
                onChange={(e) => {
                  //e.target.value
                  setStPIdDocsInf(prevSt => {
                    const newSt = structuredClone(prevSt);
                    for (let i = 0; i < newSt?.arrIdDocs?.length; i++) {
                      const pIdDInf = newSt?.arrIdDocs[i];
                      if (pIdDInf.id === newPIdDocId) {
                        newSt.arrIdDocs[i]['docFormData']['docTypeId'] = e.target.value;
                        break;
                      }
                    }//console.log('fChangeIdDocType_'+newPIdDocId, newSt);
                    return newSt;
                  })
                }}
              >
                <option value="" >
                  {comp_profile('select_document_type')}
                </option>
                {objNHDocTypesData.map((nHDType) => (
                  <option key={nHDType.id} value={nHDType.id}>
                    {nHDType.name}
                  </option>
                ))}
              </select>
            }
          </div>

          <div className="xtBdr2" style={{ minHeight: '7.5rem' }}>
            <div className="image-preview mb-2"
              onClick={() => fOpenImgViewer(pDoc, stPIdDocsInf.arrIdDocs)}
            >
              {pDoc?.docFormData?.docFile &&
                <img className="id-image lnkPt" alt={'IdDocImage-' + newPIdDocId}
                  src={URL.createObjectURL(pDoc.docFormData.docFile)} />
              }
            </div>
            <div>
              <input type="file" className="mb-2" style={fUploadCss}
                name={'idDocImg_' + newPIdDocId} id={'idDocImg_' + newPIdDocId}
                accept="image/*,.pdf" //capture=
                onChange={(e) => {
                  const [selFile] = e.target.files;//[0]
                  setStPgMsg('');
                  //if(typeof selFile === 'object') {
                  setStPIdDocsInf(prevSt => {
                    const newSt = structuredClone(prevSt);
                    for (let i = 0; i < newSt?.arrIdDocs?.length; i++) {
                      const pIdDInf = newSt?.arrIdDocs[i];
                      if (pIdDInf.id === newPIdDocId) {
                        newSt.arrIdDocs[i]['docFormData']['docFile'] = selFile;//selFile;
                        //newSt.arrIdDocs[i]['docFormData']['docFile'] = URL.createObjectURL(file);
                        break;
                      }
                    }//console.log('fChangeIdDocImg_'+newPIdDocId, newSt);
                    return newSt;
                  })
                  //}
                }}
              />
            </div>
          </div>
        </div>
        <div className="text-danger xtBdr2">{hasErr && errJsx}</div>
      </div>
    );
  };

  const loaderJsx = (
    <div className="d-flex justify-content-center">
      <LottieSpinner size={200} />
    </div>
  );
  //---------------------------------------------------------

  //console.log('profPgN', {stPIdDocsInf});
  let idDocsJsx = [], flgDisableSubmit = false;
  //let flgDisableSubmit = (stPIdDocsInf?.savedDocCount);

  if (stPIdDocsInf?.arrIdDocs?.length) {
    //console.log('stPIdDocsInfJsx', stPIdDocsInf);
    stPIdDocsInf?.arrIdDocs.forEach((pIdDocInf, idx) => {
      if (pIdDocInf?.hasData && pIdDocInf?.docApiData) {
        idDocsJsx.push(fGetSavedIdDocBoxJsx(pIdDocInf));
      } else if (pIdDocInf?.hasView && pIdDocInf?.hasData === false) {
        //idDocsJsx.push(fGetNewIdDocBoxJsx(pIdDocInf, stPIdDocsInf.shownIdDocCount));
      }
    });
  }

  return (isPageLoading && !stProcessing ? loaderJsx :
    <div id="ctnProfIdDoc">
      <form>
        <div className="file-box-content">
          <CardItem>
            {comp_profile('upload_id_doc_msg2')}
            <button type="button" disabled={!flgEditableForm}
              className="btn btn-success btn-round waves-effect waves-light mb-2"
              //onClick={fAddNewIdDocBox}
              //onClick={fInitVerrifInContextFlow}
              onClick={fInitVerrifInContextFlow2}
              {...(stPIdDocsInf?.shownIdDocCount >= gMaxPIdDoc && {
                disabled: true, style: { cursor: 'not-allowed' }
              })}
            >
              {comp_profile('add_id_document_button')}
            </button>
          </CardItem>
        </div>

        {/*<div className="xtBdr2 txAC">
          {stPgMsg && <label className="text-danger">{stPgMsg}</label>}
        </div>*/}
        <div className="row" style={{ minHeight: '100px' }}
        >
          <div className="form-group row wA"
            style={{ justifyContent: 'center', minHeight: '168px' }}
          >
            {idDocsJsx}
            <div id="ctnVeriffDoc1" style={{ visibility: 'hidden', marginTop: "20px" }}>
              <VeriffIntegration profileId={aUserProfileId} />
            </div>
            {/* verrifContextFormJsx*/}
            {stIViewInf.isOpen &&
              <ImageViewer src={stIViewInf.iUrls}
                currentIndex={stIViewInf.curr} onClose={fCloseImgViewer} />
            }
          </div>
        </div>
        <div className="row">
          <div className="col text-left xtBdr2">
            {stPgMsg && <div className="text-danger">{stPgMsg}</div>}
          </div>
        </div>
      </form>
    </div>
  );
};
//---------------------------------------------------------