import axios from "axios"
import { API_URL } from '../constants';

//const API_URL = API_URL;

export default axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
})

export const axiosPrivate = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
})

export const fCallAxApi = async (aUrl) => {
  console.log('aUrl', aUrl);
  let resp;
  try{
    resp = await axios.get( aUrl,
      { headers: {'Content-Type': 'application/json' }, }
    );console.log('axResp', resp);//response?.data?.result);
    //return resp;//?.data?.result?.data;
  } catch(error) { console.log("axRespErr", error); /*return null;*/ }
  return resp;
};
