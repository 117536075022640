import { axiosPrivate } from '../api/axios'
import { useEffect } from 'react'
import useRefreshToken from './useRefreshToken'
import useAuth from './useAuth'
import { useNavigate } from 'react-router-dom'

const useAxiosPrivate = () => {
  // const refresh = useRefreshToken()
  const { auth } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `${auth?.accessToken}`
        }

        return config
      },
      (error) => {
        Promise.reject(error)
      }
    )

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => {
        if (response?.data?.Status === false && (response?.data?.Message?.startsWith('Invalid token') || response?.data?.Message?.startsWith('Access Token has'))) {
          navigate('/login')
        }
        return response
      },
      async (error) => {
        const prevRequest = error?.config
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true
          const accessToken = auth.accessToken
          prevRequest.headers['Authorization'] = `${accessToken}`
          return axiosPrivate(prevRequest)
        }
        return Promise.reject(error)
      }
    )

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept)
      axiosPrivate.interceptors.response.eject(responseIntercept)
    }
  }, [auth])

  return axiosPrivate
}

export default useAxiosPrivate
