import Footer from '../../components/Footer'
import React, { useEffect, useState } from 'react'
import RingLoading from '../../components/lottie/RingLoading'
import { useTranslation } from 'react-i18next'
import { DR_COCO_AI_URL } from '../../constants'
import i18n from '../../i18n'

function AskDrCoco() {
  const [isLoading, setIsLoading] = useState(true)
  const { t: patient_drcoco_ai } = useTranslation('patient_drcoco_ai')

  let iframeUrlParams = []
  if (i18n.language) {
    iframeUrlParams['lng'] = i18n.language
  }
  const query = Object.keys(iframeUrlParams)
    .map((key) => `${key}=${encodeURIComponent(iframeUrlParams[key])}`)
    .join('&')

  const iframeSrc = `${DR_COCO_AI_URL}?${query}`

  const handleLoadStart = () => {
    setIsLoading(true)
  }

  const handleLoad = () => {
    setIsLoading(false)
  }

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <h4 className="page-title">{patient_drcoco_ai('drcoco_ai')}</h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div class="d-flex justify-content-center">{isLoading && <RingLoading size={200} />}</div>
              <div className={`iframe-wrapper ${isLoading ? 'hide' : ''}`}>
                <iframe src={iframeSrc} width="100%" height="100%" style={{ minHeight: '200vh', border: 'none' }} onLoadStart={handleLoadStart} onLoad={handleLoad} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default AskDrCoco
