import { Outlet } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Nav, Navbar, Container, Button } from 'react-bootstrap'
import VerticleLogo from '../../components/VerticleLogo'
import { useTranslation } from 'react-i18next'

function DefaultLayout() {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card shadow-none vw-100 mb-0">
            <div className="card-body p-0">
              {/* <div className="container">
                <Link to="/" className="logo">
                  <VerticleLogo style={{ height: '70px' }} />
                </Link>
              </div> */}
              <Navbar bg="transparent" expand="lg">
                <Container>
                  <Navbar.Brand href="/" className="logo">
                    <VerticleLogo style={{ width: '90px' }} />
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="navbar-nav" />
                  <Navbar.Collapse id="navbar-nav">
                    <Nav className="ml-auto">
                      <Nav.Link href="https://niuhealth.com/about-us/" className="menu-item ml-1 mr-1">
                        {patient_marketplace('about_us')}
                      </Nav.Link>
                      <Nav.Link href="https://niuhealth.com/global-care/" className="menu-item ml-1 mr-1">
                        {patient_marketplace('global_care')}
                      </Nav.Link>
                      <Nav.Link href="https://niuhealth.com/urgent-care/" className="menu-item ml-1 mr-1">
                        {patient_marketplace('urgent_care')}
                      </Nav.Link>
                      <Nav.Link href="https://niuhealth.com/niu-health-coin/" className="menu-item ml-1 mr-1">
                        {patient_marketplace('niu_health_coin')}
                      </Nav.Link>
                      <Nav.Link href="https://niuhealth.com/niu-social/" className="menu-item ml-1 mr-1">
                        {patient_marketplace('niu_social')}
                      </Nav.Link>
                      <Nav.Link href="https://niuhealth.com/blogs/" className="menu-item ml-1 mr-1">
                        {patient_marketplace('blogs')}
                      </Nav.Link>
                      <Nav.Link href="https://niuhealth.com/contact-us/" className="menu-item ml-1 mr-1">
                        {patient_marketplace('locations')}
                      </Nav.Link>
                    </Nav>

                    <Link to="/register" className="logo">
                      <Button variant="primary btn-theme-primary shadow-none py-2 px-4 ml-md-3 mr-3">{patient_marketplace('sign_up')}</Button>
                    </Link>

                    <Link to="/login" className="logo">
                      <Button variant="outline-primary btn-theme-secondary shadow-none py-2 px-4">{patient_marketplace('login')}</Button>
                    </Link>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div
            style={{
              overflowY: 'scroll',
            }}
            className="vh-100 w-100 pt-4"
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default DefaultLayout
