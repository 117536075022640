import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Footer from '../../../components/Footer'
import { AWS_BUCKET, AWS_BUCKET_SERVICES } from '../../../constants'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { yupResolver } from '@hookform/resolvers/yup'
import MarketplacePaymentSchema from '../../../validation/marketplacePayment'
import PhoneInput from 'react-phone-number-input'
import { getProvinceFromCountryId } from '../../../utils'
import { useTranslation } from 'react-i18next'

export default function Checkout() {
  const cardRef = useRef()
  const { state: selectedService } = useLocation()
  const { t: error_message } = useTranslation('error_message')
  const { t: patient_marketplace } = useTranslation('patient_marketplace')

  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const [countries, setCountries] = useState([])

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    resolver: yupResolver(MarketplacePaymentSchema),
  })
  const [Country] = watch(['Country'])

  const [countryProvinceList, setCountryProvinceList] = useState([])
  const [apiProvinceList, setApiProvinceList] = useState([])

  const [show, setShow] = useState(false)
  const [totalAmount, setTotalAmount] = useState(selectedService?.selectedProvider?.cost_price)
  const [clinic] = useState(selectedService?.clinic_obj)
  const handleClose = () => setShow(false)

  const onSubmit = async (data) => {
    try {
      const serviceApi = data.isBookingPaid === true ? 'servicePayment' : 'servicePaymentBookingOnly'
      const paymentType = data.isBookingPaid === true ? '3' : '5'
      const Exp = data.isBookingPaid === true ? '12 / 34' : data?.expiry

      const ExpMonth = Exp.toString().split('/')[0] ? Exp.toString().split('/')[0].trim() : Exp.toString().split('/')[0]
      const ExpYear = Exp.toString().split('/')[1] ? Exp.toString().split('/')[1].trim() : Exp.toString().split('/')[1]

      const billing = {
        CardNumber: data?.number,
        ExpMonth,
        Expyear: ExpYear,
        CVC: data?.cvc,
        Email: auth?.email,
        ServiceID: selectedService.selectedProvider.service_id,
        PaymentType: paymentType,
        ClinicID: clinic.clinic_id,
        ProviderID: selectedService.provider.provider_id,
        Date: selectedService.timeSlot.dateX,
        Time: selectedService.timeSlot.timeX,
      }

      await axiosPrivate
        .post(serviceApi, billing)
        .then((res) => {
          const { Status, Message } = res.data
          if (Status) {
            navigate('../success', { replace: true })
          } else {
            alert(Message)
          }
        })
        .catch((err) => {
          console.error(err)
          alert(err.message)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const hanldePayNow = async () => {
    await trigger().then(() => {
      if (isValid) setShow(true)
    })
  }

  useEffect(() => {
    if (cardRef.current) {
      new window.Card({
        form: document.querySelector('.bill-form'),
        container: '.card-wrapper',
      })
    }
  }, [])

  function dateFormat(date) {
    return moment(date).format('YYYY-MM-DD') // moment can initialize passing empty params
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    async function getProfileDetails() {
      await axiosPrivate
        .post(
          'getPatientDetails',
          { Email: auth?.email },
          {
            signal: controller.signal,
          }
        )
        .then((res) => {
          const { Status, Data: data = [], Message } = res.data

          if (Status) {
            setProfile(res.data.Data[0])
          } else {
            throw new Error(Message)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
    async function getCountries() {
      await axiosPrivate
        .post(
          'getCountries',
          { Email: auth?.email },
          {
            signal: controller.signal,
          }
        )
        .then((res) => {
          const { Status, Data: data = [], Message } = res.data

          if (Status) {
            setCountries(res.data.Data)
          } else {
            throw new Error(Message)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
    async function getProvince() {
      await axiosPrivate
        .post(
          'getProvince',
          { Email: auth?.email },
          {
            signal: controller.signal,
          }
        )
        .then((res) => {
          const { Status, Data: data = [], Message } = res.data

          if (Status) {
            isMounted && setApiProvinceList(data)
          } else {
            throw new Error(Message)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
    getProfileDetails()
    getCountries()
    getProvince()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (Country != '0' && apiProvinceList.length > 0) {
      const countryProvince = getProvinceFromCountryId(apiProvinceList, Country)
      setCountryProvinceList(countryProvince)
      if (countryProvince.length == 0) {
        setValue('Province', '')
      }
    }
  }, [Country, apiProvinceList])

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <div className="float-right">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/patient/marketplace">{patient_marketplace('marketplace')}</Link>
                    </li>
                    <li className="breadcrumb-item active">{patient_marketplace('checkout')}</li>
                  </ol>
                </div>
                <h4 className="page-title">{patient_marketplace('checkout')}</h4>
              </div>
            </div>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="col-lg-5">
                <div className="card">
                  <div className="card-body">
                    <h4 className="header-title mt-0 mb-3">{patient_marketplace('order_summary')}</h4>
                    <div className="table-responsive shopping-cart">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th>{patient_marketplace('service')}</th>
                            <th>{patient_marketplace('appointment_date')}</th>
                            <th>{patient_marketplace('total')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <img src={`${AWS_BUCKET_SERVICES}${selectedService?.selectedProvider?.images}`} alt="" height="52" />
                              <p className="d-inline-block align-middle mb-0 product-name">
                                {selectedService?.selectedProvider?.provider_name} - {selectedService?.selectedProvider?.service_name}
                              </p>
                            </td>
                            <td>{moment(selectedService?.timeSlot).format('MM/DD/YYYY h:mm a')}</td>
                            <td>${selectedService?.selectedProvider?.cost_price}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="total-payment">
                      <table className="table mb-0">
                        <tbody>
                          <tr>
                            <td className="payment-title">{patient_marketplace('subtotal')}</td>
                            <td>${selectedService?.selectedProvider?.cost_price}</td>
                          </tr>
                          <tr>
                            <td className="payment-title">{patient_marketplace('promo_code')}</td>
                            <td>-$0.00</td>
                          </tr>
                          <tr>
                            <td className="payment-title">{patient_marketplace('total')}</td>
                            <td className="text-dark">
                              {selectedService?.selectedProvider?.cost_price < 1 ? (
                                <span>
                                  <strong>{patient_marketplace('contact_provider_for_total')}</strong>
                                  <br />
                                  {patient_marketplace('bring_identification_for_appointment')}
                                </span>
                              ) : (
                                <span>
                                  <strong>${totalAmount}</strong>
                                </span>
                              )}
                              <input type="hidden" {...register('isBookingPaid', { value: selectedService?.selectedProvider?.cost_price < 1 ? false : true })} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {/* <div className='card'>
                  <div
                    className='col-md-12 align-self-center'
                    style={{ padding: '30px 20px' }}
                  >
                    <div className='text-center'>
                      <h4 className=''>Have a promo code ?</h4>
                      <p className='font-13'>
                        If you have a promocode, You can take discount !
                      </p>
                      <div className='input-group w-75 mx-auto'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Use Promo code'
                          aria-describedby='button-addon2'
                          {...register('coupon')}
                        />
                        <div className='input-group-append'>
                          <button
                            className='btn btn-gradient-success'
                            type='button'
                            id='button-addon2'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className={`col-lg-7 ${selectedService?.selectedProvider?.cost_price >= 1 ? 'hide' : null}`}>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <button type="button" className="btn btn-success px-3" onClick={hanldePayNow}>
                        {patient_marketplace('confirm_appointment')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7">
                {/*
                    <div className="card">
                    <div className="card-body">
                    <h4 className="header-title mt-0 mb-3">Billing Address</h4>

                    <div className="row">
                        <div className="col-md-6">
                        <div className="form-group">
                            <label>
                            First Name <small className="text-danger font-13">*</small>
                            </label>
                            <input type="text" {...register('FirstName', { onBlur: () => trigger('FirstName') })} className={`form-control ${Boolean(errors.FirstName) ? 'is-invalid' : ''}`} />
                            {errors.FirstName && <label className="text-danger">{error_message(errors.FirstName.message)}</label>}
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                            <label>
                            Last Name <small className="text-danger font-13">*</small>
                            </label>
                            <input type="text" {...register('LastName', { onBlur: () => trigger('LastName') })} className={`form-control ${Boolean(errors.LastName) ? 'is-invalid' : ''}`} />
                            {errors.LastName && <label className="text-danger">{error_message(errors.LastName.message)}</label>}
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        <div className="form-group">
                            <label>
                            Address <small className="text-danger font-13">*</small>
                            </label>
                            <input type="text" {...register('Address', { onBlur: () => trigger('Address') })} className={`form-control ${Boolean(errors.Address) ? 'is-invalid' : ''}`} />
                            {errors.Address && <label className="text-danger">{error_message(errors.Address.message)}</label>}
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label pt-0 pb-1">
                            Country <small className="text-danger font-13">*</small>
                            </label>
                            <select
                            {...register('Country', { onBlur: () => trigger('Country') })}
                            className={`form-control ${Boolean(errors.Country) ? 'is-invalid' : ''}`}
                            onChange={(e) => {
                                setValue('Country', e.target.value)
                            }}
                            >
                            <option value={''}>Select</option>
                            {countries.map((country, index) => (
                              <option value={country.country_id} key={index}>
                                {country.description}
                              </option>
                            ))}
                          </select>
                          {errors.Country && <label className="text-danger">{error_message(errors.Country.message)}</label>}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            City <small className="text-danger font-13">*</small>
                          </label>
                          <input type="text" {...register('City', { onBlur: () => trigger('City') })} className={`form-control ${Boolean(errors.City) ? 'is-invalid' : ''}`} />
                          {errors.City && <label className="text-danger">{error_message(errors.City.message)}</label>}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                        <div className="form-group">
                            <label>
                            Email Address <small className="text-danger font-13">*</small>
                            </label>
                            <input type="email" {...register('Email', { onBlur: () => trigger('Email') })} className={`form-control ${Boolean(errors.Email) ? 'is-invalid' : ''}`} />
                            {errors.Email && <label className="text-danger">{error_message(errors.Email.message)}</label>}
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                            <label>
                            Mobile No <small className="text-danger font-13">*</small>
                          </label>
                          <input type="text" {...register('Mobile', { onBlur: () => trigger('Mobile') })} className={`form-control ${Boolean(errors.Mobile) ? 'is-invalid' : ''}`} />
                          {errors.Mobile && <label className="text-danger">{error_message(errors.Mobile.message)}</label>}
                        </div>
                        </div>
                    </div>
                    </div>
                                      </div>
                        */}
                <div className={`card  ${selectedService?.selectedProvider?.cost_price < 1 ? 'hide' : null}`}>
                  <div className="card-body">
                    <h4 className="header-title mt-0 mb-3">{patient_marketplace('payment_details')}</h4>
                    <div className="billing-nav">
                      {/* <ul
                        className='nav nav-pills justify-content-center text-center mb-3'
                        id='pills-tab'
                        role='tablist'
                        >
                        <li className='nav-item'>
                            <a
                            className='nav-link active'
                            id='pills-credit-card-tab'
                            data-toggle='pill'
                            href='#pills-credit-card'
                            >
                            <i className='mdi mdi-credit-card d-block mx-auto text-danger font-18'></i>
                            Credit-Card
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a
                            className='nav-link'
                            id='pills-paypal-tab'
                            data-toggle='pill'
                            href='#pills-paypal'
                            >
                            <i className='mdi mdi-paypal d-block mx-auto text-secondary font-18'></i>
                            Paypal
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a
                            className='nav-link'
                            id='pills-bitcoin-tab'
                            data-toggle='pill'
                            href='#pills-bitcoin'
                            >
                            <i className='mdi mdi-bitcoin d-block mx-auto text-warning font-18'></i>
                            Bitcoin
                            </a>
                        </li>
                        </ul> */}
                      <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-credit-card">
                          <div className="demo-container w-100 h-100">
                            <div className="card-wrapper mb-4"></div>
                            <div className="form-container">
                              <div ref={cardRef} className="bill-form">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <input
                                        placeholder="Card number"
                                        className={`form-control ${errors.number ? 'is-invalid' : null}`}
                                        type="tel"
                                        {...register('number', {
                                          onBlur: () => trigger('number'),
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <input
                                        placeholder="Full name"
                                        className={`form-control ${errors.name ? 'is-invalid' : null}`}
                                        type="text"
                                        {...register('name', {
                                          onBlur: () => trigger('name'),
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <input
                                        placeholder="MM/YY"
                                        className={`form-control ${errors.expiry ? 'is-invalid' : null}`}
                                        type="tel"
                                        {...register('expiry', {
                                          onBlur: () => trigger('expiry'),
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <input
                                        placeholder="CVC"
                                        className={`form-control ${errors.cvc ? 'is-invalid' : ''}`}
                                        type="number"
                                        {...register('cvc', {
                                          onBlur: () => trigger('cvc'),
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <button type="button" className="btn btn-success px-3" onClick={hanldePayNow}>
                                  {patient_marketplace('pay_now')}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*
                        <div className="tab-pane fade" id="pills-paypal">
                            <div className="row">
                            <div className="col-md-12 col-lg-6 offset-lg-3">
                                <div className="card border">
                                <div className="card-body">
                                    <h4 className="title-text">
                                    <i className="mdi mdi-paypal d-block mx-auto text-secondary font-18"></i>
                                    Add Paypal Form
                                    </h4>
                                    <p className="mb-0 text-muted">
                                    There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words.
                                    </p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-bitcoin">
                            <div className="row">
                            <div className="col-md-12 col-lg-6 offset-lg-3">
                                <div className="card border">
                                <div className="card-body">
                                    <h4 className="title-text">
                                    <i className="mdi mdi-bitcoin d-block mx-auto text-warning font-18"></i>
                                    Add Bitcoin Form
                                    </h4>
                                    <p className="mb-0 text-muted">
                                    There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words.
                                    </p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ConfirmModal show={show} handleClose={handleClose} handleSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} patient_marketplace={patient_marketplace} />
          </form>

          <Footer />
        </div>
      </div>
    </div>
  )
}

const ConfirmModal = ({ show, handleClose, handleSubmit, isSubmitting, patient_marketplace }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header>
      <Modal.Title>{patient_marketplace('proceed_confirmation')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{patient_marketplace('proceed_confirmation_msg')}</Modal.Body>
    <Modal.Footer>
      <button type="submit" className={`btn btn-success px-3 ${isSubmitting ? 'disabled' : ''}`} onClick={handleSubmit} disabled={isSubmitting}>
        {isSubmitting ? patient_marketplace('please_wait') : patient_marketplace('confirm')}
      </button>
      <Button variant="outline-dark" onClick={handleClose}>
        {patient_marketplace('cancel')}
      </Button>
    </Modal.Footer>
  </Modal>
)
