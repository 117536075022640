import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice, current } from "@reduxjs/toolkit";
import { NH_API_BASE_URL_V2, }  from 'v2/src/config/appConfig';
//import { rSetUserCredentials, rSetLogOut } from './auth/authSlice';
import Cookies from "js-cookie";
//---------------------------------------------------------

const baseQuery = fetchBaseQuery({
	baseUrl: NH_API_BASE_URL_V2,//(args, { dispatch, getState, extra }, extraOptions)
	prepareHeaders: (headers, {getState, endpoint, extraOptions}) => {
		headers.set('Connection', 'keep-alive');
		headers.set('Accept', 'application/json');
		let lAT, authToken;
		authToken = lAT ?? getState()?.auth?.authTokenNH;
		authToken = authToken || Cookies.get('accessTokenNH');
		if(authToken) {//console.log('authToken', authToken);
			//headers.set('Authorization', `Bearer ${authToken}`);
			headers.set('Authorization', 'Bearer '+authToken);
		}
		return headers;
	},
	//timeout: 10000, //jsonContentType: true,
});

const baseQueryWithReAuth = async(args, api, extraOptions) => {
	let resp = await baseQuery(args, api, extraOptions);
	//console.log('nHBaseQWithReA', {args, api, extraOptions, resp});

	/*if(resp?.error?.originalStatus === 403) {
		const refRes = await baseQuery('/refresh', api, extraOptions);
		if(refRes?.data) {
			const user = api.getState().auth.user;
			api.dispatch(rSetUserCredentials({...refRes?.data, user}));
			resp = await baseQuery(args, api, extraOptions);
		} else { api.dispatch(rSetLogOut()); }
	} else*/ if (!resp.data && !resp.error) {
		return { data: null, meta: resp.meta, };
	} else if(resp.data) {
		//resp.data = resp.data?.data;
		//resp = resp.data;
		//if(resp.data?.status === true) {}
	} else if(resp.error) {
		//resp.error = resp.error?.data;
	}//console.log('finalResp', resp);
	return resp;
};

//export const sSApiBaseQuery = baseQueryWithReAuth;
export const nHApi = createApi({
	//reducerPath: 'api',
	baseQuery: baseQueryWithReAuth,
	endpoints: builder => ({}),
	//endpoints: () => ({}),
	//tagTypes: ['user', ],
	//overrideExisting: false,
});
//---------------------------------------------------------

const iniAppStat = {
  isAppLoaded: false,
  notifications: {},//'profileIncomplete': {msg: '', status: 1}, 'appInitMsgShown': {status: 0}
};

const nHAppSlice = createSlice({
	name: 'nHApp',
	initialState: iniAppStat,
	reducers: {
    rInitApp: (state, action) => {
      //console.log('rInitAppAct', action?.payload);
      //if(action?.payload?.summaryData) {
        //state.summaryData = action.payload.summaryData;
        //return state;
      //}//else { console.log('rInitAppNoUps'); }
    },
    rSetAppLoaded: (state, action) => {
      return {...state, isAppLoaded: action?.payload?.status || false}
    },
    rResetApp: (state, action) => {
      //return {...state, isAppLoaded: xx};
    },
    rAddNotification: (state, action) => {
    },
    rRemoveNotification: (state, action) => {
    },
    rSetNotificationStat: (state, action) => {
      return {...state, isAppLoaded: action?.payload?.status || false}
    },
	},
});

//const { actions, reducer } = nHAppSlice;
//export const { rInitApp, rSetAppLoaded, } = actions;
//export default reducer;
//export const nHAppReducer = nHAppSlice.reducer;
export default nHAppSlice.reducer;

export const {
  rInitApp, rSetAppLoaded, //rResetApp,
  rAddNotification, rRemoveNotification, rSetNotificationStat
} = nHAppSlice.actions;
export const rGetAppLoadedStat  = state => state.nHApp.isAppLoaded;
//---------------------------------------------------------

/*const slNameSlice = createSlice({
	name: 'slName',
	initialState: iniAppStat,
	reducers: {},
});

export const slNameReducer = slNameSlice.reducer;
export const {
  //
  //rAddNotification, rRemoveNotification, rSetNotificationStat
} = slNameSlice.actions;*/
 //---------------------------------------------------------