import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Footer from '../../../components/Footer'
import { UploadOneImage } from '../../../components/form/UploadImage'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { yupResolver } from '@hookform/resolvers/yup'
import InsuranceSchema from '../../../validation/insurance'
import { useTranslation } from 'react-i18next'
//---------------------------------------------------------

function Upload() {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const formRef = useRef()
  const [errMsg, setErrMsg] = useState()
  const [isSuccess, setIsSuccess] = useState(false)
  const navigate = useNavigate()
  const [insuranceCoverage, setInsuranceCoverage] = useState('')
  const [selectedCoverage, setSelectedCoverage] = useState([])
  const [imageList, setImageList] = useState([{ path: '' }, { path: '' }]);
  //const [imageList, setImageList] = useState([{ path: 'Default.png', path: 'Default.png' }])
  const { t: error_message } = useTranslation('error_message')
  const { t: patient_insurance } = useTranslation('patient_insurance')
  const maxDocImg = 2;

  const {
    register, handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setValue, trigger,
  } = useForm({ resolver: yupResolver(InsuranceSchema), })

  const handleUpload = async (data) => {
    setIsSuccess(false)
    setErrMsg(null)

    const additional_formdata = imageList.reduce((formData, image, index) => {
      formData['Image' + (index + 1)] = image.file
      return formData
    }, {})

    // delete data['images']
    const formdata = { ...data }
    if (data?.StartDate) {
      formdata['StartDate'] = moment(data.StartDate.replace(/-/g, '/')).format('YYYY-MM-DD')
    }
    if (data?.EndDate) {
      formdata['EndDate'] = moment(data.EndDate.replace(/-/g, '/')).format('YYYY-MM-DD')
    }

    try {
      const res = await axiosPrivate.post(
        'uploadInsuranceBucket',
        {
          ...formdata,
          ...additional_formdata,
          Email: auth?.email,
        },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )

      const { Message, Status, Data } = res.data

      if (Status) {
        setIsSuccess(true)
      } else {
        setIsSuccess(false)
        Swal.fire({
          icon: 'warning',
          html: `${patient_insurance('insurance_' + Data.StatusCode)}`,
        })
        throw new Error(Message)
      }
    } catch (error) {
      setErrMsg(error.message)
      console.error(error)
    }
  }

  const handleImageUpload = (img, index) => {
    //console.log('AA', {img, index});
    const newImageList = [...imageList]
    newImageList[index] = img
    setImageList(newImageList)
    setValue('images', newImageList.length)
    trigger('images')
  }

  const submitButtonText = isSubmitting ?
    patient_insurance('please_wait') :
    patient_insurance('submit_documents');
  const goBackButton = isSuccess ? (
    <button type="button"
      className="btn btn-success btn-round waves-effect waves-light"
      onClick={() => {//navigate(-1);
        navigate('/patient/profile');
      }}
    >
      {patient_insurance('go_back')}{/*patient_insurance('back')*/}
    </button>
  ) : (
    <div className='dFlexC'>
      <button type="submit" className={`btn btn-success btn-round waves-effect waves-light ${isSubmitting ? 'disabled' : ''}`} disabled={isSubmitting}>
        {submitButtonText}
      </button>
      <Link to="/patient/profile">{/* to=".." */}
        <button type="button" className="float-right btn btn-danger btn-round waves-effect waves-light xmt-2">
          {patient_insurance('back')}
        </button>
      </Link>
    </div>
  );

  const ctnInsImgCss = {
    padding: 5, margin: 5,
    height: 240, width: 250, maxWidth: 260,
    border: '1px solid #f1f5fa', borderRadius: '5px',
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <h4 className="page-title">{patient_insurance('insurance')}</h4>
              </div>
            </div>
          </div>
          <form ref={formRef} onSubmit={handleSubmit(handleUpload)} id="formUpload" encType="multipart/form-data" noValidate>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="header-title mt-0 mb-3">{patient_insurance('upload_insurance_document')}</h4>
                    <label>
                      {patient_insurance('insurance_title')} <small className="text-danger font-13">*</small>
                    </label>
                    <input type="text" name="BucketName" disabled={isSuccess} {...register('BucketName')} className={`form-control ${Boolean(errors.BucketName) ? 'is-invalid' : ''}`} />
                    {errors.BucketName && <label className="text-danger">{error_message(errors.BucketName.message)}</label>}
                    {/* <div className="mb-4 m-1">
                      <label>Start Date</label>
                      <input className="form-control" type="date" placeholder={'mm-dd-yyyy'} name="StartDate" disabled={isSuccess} {...register('StartDate')} />
                      <br />
                      <label>End Date</label>
                      <input className="form-control" type="date" placeholder={'mm-dd-yyyy'} disabled={isSuccess} name="EndDate" {...register('EndDate')} />
                    </div> */}
                    <div className="row">
                      <div className="form-group row-lg-12 mb-4 xmt-4 m-1">
                        <div className="col-md-12">
                          <label htmlFor="insurance" className="col-form-label text-right">
                            {patient_insurance('insurance_files')}
                          </label>
                          <p>{patient_insurance('insurance_files_note')}</p>
                          <label className="text-danger">{errors.images && error_message(errors.images.message)}</label>
                        </div>
                        <div className="row xpadS xtBdr2" //style={{ maxWidth: 510, }}
                        >
                          {imageList.map((image, index) => {
                            return (//col-lg-4 col-md-4 xcol-lg-12 xmr-1
                              <div key={'ctnInsImg_' + index}
                                className="col-xs-12 p-4 xw-100 xdInFlex xtBdr "
                                style={ctnInsImgCss}
                              >
                                <UploadOneImage
                                  inName={'inFl_' + index}
                                  disabled={isSuccess}
                                  image={image} previewImage={image}
                                  setImage={(img) => handleImageUpload(img, index)} />
                              </div>
                            )
                          })}
                        </div>
                        <div className="d-flex justify-content-center text-center">
                          {/*imageList.length < maxDocImg && imageList[imageList.length - 1].path != 'Default.png' ? (
                            <button
                              className="btn btn-gradient-success waves-effect waves-light"
                              style={{ minWidth: '80px', height: '60px' }}
                              disabled={isSuccess}
                              onClick={(e) => {
                                e.preventDefault()
                                imageList.length < maxDocImg
                                {
                                  setImageList([...imageList, { path: 'Default.png' }])
                                }
                              }}
                            >
                              {patient_insurance('add_file')}
                            </button>
                          ) : null*/}
                        </div>
                      </div>
                    </div>
                    <p>
                      <small className="text-muted">{patient_insurance('insurance_note')}</small>
                    </p>
                    <p>{patient_insurance('insurance_note_2')}</p>

                    {errMsg && (
                      <div className="alert alert-danger" role="alert">
                        {patient_insurance('upload_failed')} {errMsg}
                      </div>
                    )}

                    {isSuccess && (
                      <div className="alert alert-success" role="alert">
                        {patient_insurance('upload_success')}
                      </div>
                    )}
                    {goBackButton}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Upload
