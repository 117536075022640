import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { NavLink } from 'react-router-dom'

const validationSchema = yup.object().shape({
  Name: yup.string().required('name_required'),
  FileName: yup.string().required('filename_required'),
  Description: yup.string().required('description_required'),
  SortOrder: yup.number().typeError('sort_order_number_error').optional(),
})

const ModuleForm = ({ onSubmit, initialData, isLoading, provider_modules }) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  // Function to set initial values when data is available
  const setInitialValues = () => {
    if (initialData) {
      setValue('Name', initialData.Name)
      setValue('FileName', initialData.FileName)
      setValue('Description', initialData.Description)
      setValue('SortOrder', initialData.SortOrder)
    }
  }

  // Call the function to set initial values when data is available
  setInitialValues()

  return (
    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <div className="row mb-3">
                    <div className="col-4">{provider_modules('name')}</div>
                    <div className="col-8">
                      <input
                        type="text"
                        className={`form-control ${errors.Name ? 'is-invalid' : ''} ${isLoading == true ? 'disabled' : ''}`}
                        {...register('Name')}
                        placeholder={provider_modules('name')}
                      />
                      {errors.Name && <label className="text-danger">{provider_modules(errors.Name.message)}</label>}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-4">{provider_modules('filename')}</div>
                    <div className="col-8">
                      <input
                        type="text"
                        className={`form-control ${errors.FileName ? 'is-invalid' : ''} ${isLoading == true ? 'disabled' : ''}`}
                        {...register('FileName')}
                        placeholder={provider_modules('filename')}
                      />
                      <span className="text-muted" style={{ fontSize: '10px' }}>
                        Notice: Changes to this can completely break things. Don't make changes unless you're 100% certain
                      </span>
                      {errors.FileName && <label className="text-danger">{provider_modules(errors.FileName.message)}</label>}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row mb-3">
                    <div className="col-4">{provider_modules('description')}</div>
                    <div className="col-8">
                      <input
                        type="text"
                        className={`form-control ${errors.Description ? 'is-invalid' : ''} ${isLoading == true ? 'disabled' : ''}`}
                        {...register('Description')}
                        placeholder={provider_modules('description')}
                      />
                      {errors.Description && <label className="text-danger">{provider_modules(errors.Description.message)}</label>}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-4">{provider_modules('sort_order')}</div>
                    <div className="col-8">
                      <input
                        type="number"
                        className={`form-control ${errors.SortOrder ? 'is-invalid' : ''} ${isLoading == true ? 'disabled' : ''}`}
                        {...register('SortOrder')}
                        placeholder={provider_modules('sort_order')}
                      />
                      {errors.SortOrder && <label className="text-danger">{provider_modules(errors.SortOrder.message)}</label>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-between">
        <NavLink to="/provider/translations" className={`btn btn-danger btn-round waves-effect waves-light mt-2 m-2 ${isLoading == true ? 'disabled' : ''}`}>
          Back
        </NavLink>
        <button type="submit" className={`btn btn-success btn-round waves-effect waves-light mt-2 m-2 ${isLoading == true ? 'disabled' : ''}`}>
          {isLoading == true ? provider_modules('loading') : provider_modules('submit')}
        </button>
      </div>
    </form>
  )
}

export default ModuleForm
