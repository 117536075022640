import { useEffect, useState } from "react";
//---------------------------------------------------------

const Flasher = props => {
  const [visible, setVisible] = useState(true);
  const mDelay = props?.delay || 3000;

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      props?.resetCallback && props?.resetCallback()
    }, mDelay);
    return () => clearTimeout(timer);
  }, [mDelay]);

  return visible ?
    <div className="vAM xflash1">{props.children}</div> : <div />;
};

export default Flasher;