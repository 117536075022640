import { useState, useEffect } from 'react'
import useAxiosPrivate from '../useAxiosPrivate'

const useCreateModule = () => {
  const axiosPrivate = useAxiosPrivate()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const controller = new AbortController()
  let apiParams = {}

  const createModule = async (apiParams) => {
    setLoading(true)
    try {
      const response = await axiosPrivate.post('module/create', apiParams, { signal: controller.signal, headers: { 'Content-Type': 'multipart/form-data' } })
      setData(response.data)
      return response.data
    } catch (err) {
      setError(error)
    } finally {
      controller.abort()
    }
    setLoading(false)
  }

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return { data, loading, error, createModule }
}

export default useCreateModule
