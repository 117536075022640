import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import SideNavLogo from '../../components/SideNavLogo'
import { AWS_BUCKET, AWS_BUCKET_SERVICES, AWS_BUCKET_PROFILES, USERTYPE } from '../../constants'
import useAuth from '../../hooks/useAuth'
import SAMPLENOTIF from '../../mocks/topbarNotifs'

import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useLogout from '../../hooks/useLogout'
import Swal from 'sweetalert2'

import useGetPatientDetails from '../../hooks/api/useGetPatientDetails'
import Cookies from 'js-cookie'
import useUpdateUser from '../../hooks/api/useUpdateUser'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import useGetProviderDetails from '../../hooks/api/useGetProviderDetails'
import { fInvalidateAuthCookie } from 'v2/src/lib/appUtils';
import { useLogoutMutation } from 'v2/src/lib/services/auth/authApi';
import { aSGetAuthUserData, rSetLogOut } from 'v2/src/lib/services/auth/authSlice';
//---------------------------------------------------------

const NotifIconSwitch = ({ icontype }) => {
  const iconsGen = {
    order: {
      bg: 'bg-primary',
      icon: 'la-cart-arrow-down',
    },
    meeting: {
      bg: 'bg-success',
      icon: 'la-group',
    },
    success: {
      bg: 'bg-pink',
      icon: 'la-list-alt',
    },
    deliver: {
      bg: 'bg-warning',
      icon: 'la-truck',
    },
    task: {
      bg: 'bg-info',
      icon: 'la-check-circle',
    },
  }
  return (
    <div className={`avatar-md ${iconsGen[icontype].bg}`}>
      <i className={`la ${iconsGen[icontype].icon} text-white`}></i>
    </div>
  )
}
function NotifLink({ type, subject, body, timeReceived }) {
  // console.log(notifItem)
  // console.log("body"+notifItem['body  '])
  const timeNow = 20
  const timeSince = timeNow - timeReceived + ' minutes ago'
  return (
    <Link to="" className="dropdown-item py-3">
      <small className="float-right text-muted pl-2">{timeSince}</small>
      <div className="media">
        {/* <NotifIconSwitch icontype={ntype}/> */}

        <NotifIconSwitch icontype={type} />
        <div className="media-body align-self-center ml-2 text-truncate">
          <h6 className="my-0 font-weight-normal text-dark">{subject}</h6>
          <small className="text-muted mb-0">{body}</small>
        </div>
      </div>
    </Link>
  )
}

export function TopBar({ menuClick, homeAddress, removePfp }) {
  const logout = useLogout()
  const [notifs, setNotifs] = useState(SAMPLENOTIF)
  const navigate = useNavigate()
  const axiosPrivate = useAxiosPrivate()
  const { auth, setAuth } = useAuth()
  const fDispatch = useDispatch();
  const { t: patient_common } = useTranslation('patient_common')

  //let aUData = useSelector(aSGetAuthUserData);
  let aUData = useSelector(state => state.auth);
  //console.log('aUDataTB', {aUData });

  const [subscribed, setSubscribed] = useState()
  const [profile, setProfile] = useState({})
  //notif badge number
  const ntfBadgeNum = notifs.length
  // console.log(ntfBadgeNum)
  // console.log(notifs[0].type)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const social_tokens = queryParams.get('social_tokens') || null

  const patientStateData = useSelector((state) => state.patient)

  const { getPatientDetails, data: patientDetails } = useGetPatientDetails()
  const { getProviderDetails, data: providerDetails } = useGetProviderDetails()
  const { updateUser, data: userUpdateData } = useUpdateUser()
  const [fLogout, { isLoading: isSigningOut}] = useLogoutMutation();

  useEffect(() => {
    if (social_tokens != null) {
      updateUser({ Email: auth.email || Cookies.get('email'), SocialTokens: social_tokens })
    }
  }, [])

  useEffect(() => {
    //console.log('AAAauth', {auth, profile});
    if (auth.userType === USERTYPE.provider) {
      getProviderDetails({ apiParams: { Email: auth.email || Cookies.get('email') } })
    } else {
      getPatientDetails({ apiParams: { Email: auth.email || Cookies.get('email') } })
    }
  }, [auth])

  useEffect(() => {
    if (providerDetails.Status) {
      /*If the Language is not empty then take the value and set change the language for i18next*/
      if (providerDetails.Data[0].language) {
        i18n.changeLanguage(providerDetails.Data[0].language)
        setProfile(providerDetails.Data[0])
      }
    }
  }, [providerDetails])

  useEffect(() => {
    if (patientDetails.Status) {
      /*If the Language is not empty then take the value and set change the language for i18next*/
      if (patientDetails.Data[0]['language']) {
        i18n.changeLanguage(patientDetails.Data[0]['language'])
      }
    }
  }, [patientDetails])

  useEffect(() => {
    if (Object.keys(patientStateData).length) {
      setProfile(patientStateData?.Data[0])
      Cookies.set('has_insurance', patientStateData?.Data[0].has_insurance)
      Cookies.set('has_subscribed', patientStateData?.Data[0].subscription_plan === '1')
    }
  }, [patientStateData])

  async function logoutCurrentUser() {
    fDispatch(rSetLogOut());
    //const logOutResp = await fLogout();console.log('logOutResp', logOutResp);
    await axiosPrivate.post('signOut', { Email: auth.email }).then((res) => {
      console.log(res)
    });
    fInvalidateAuthCookie();
  }

  function handleLogout(e) {
    e.preventDefault()
    Swal.fire({
      icon: 'question',
      html: `${patient_common('logout_confirmation')}`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: patient_common('ok'),
      cancelButtonText: patient_common('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        logout()
        logoutCurrentUser()
        navigate('/login', { replace: true })
      } else {
        return
      }
    })
  }

  return (
    <div className="dev-top-bar">
      <div style={{ minWidth: '240px' }}>
        <button onClick={menuClick} className="nav-link button-menu-mobile waves-effect waves-light btn-lg" type={'button'}>
          <i className="ti-menu nav-icon"></i>
        </button>
        <Link to={homeAddress} className="logo" style={{ maxHeight: '100px', height: 'auto', width: '100%', maxWidth: '170px' }}>
          {/*<SideNavLogo style={{ maxHeight: '100px', height: 'auto', width: '100%', maxWidth: '170px' }} />*/}
          <SideNavLogo style={{ height: '45px', width: 'auto', maxHeight: '100px', maxWidth: '100px', marginLeft: '-8px' }} />
        </Link>
      </div>

      <ul className="list-unstyled dev-top-bar-right mb-0">
        {/* <li>
        <NotifLink />
        </li> */}
        <li className="dropdown">
          {removePfp ? (
            <></>
          ) : (
            <Link className="nav-link dropdown-toggle waves-effect waves-light nav-user" data-toggle="dropdown" to="#" role="button" aria-haspopup="false" aria-expanded="false">
              {profile ? (
                <>
                  <span className="ml-1 nav-user-name hidden-sm m-4">
                    {auth.userType === USERTYPE.patient ? profile.first_name : auth.userType === USERTYPE.provider ? profile.provider_name : null}
                    {/*  <i className='mdi mdi-chevron-down'></i>{' '} */}
                  </span>
                  {/*<img src={`${profile?.picture}`} alt="profile-user" className="rounded-circle" style={{ objectFit: 'cover', width: 50, height: 50 }} />*/}
                  <img src={`${auth?.picture}`} alt="profile-user" className="rounded-circle" style={{ objectFit: 'cover', width: 50, height: 50 }} />
                </>
              ) : null}
            </Link>
          )}
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to="profile">
              <i className="ti-user text-muted mr-2"></i> {patient_common('profile')}
            </Link>
            <Link className="dropdown-item" to="about">
              <i className="ti-info text-muted mr-2"></i> {patient_common('about')}
            </Link>
            <a href="https://niuhealth.com/privacy-policy/" target="_blank" className="dropdown-item">
              <i className="ti-lock text-muted mr-2"></i> {patient_common('privacy')}
            </a>

            <div className="dropdown-divider mb-0"></div>
            <NavLink onClick={handleLogout.bind(this)} className="dropdown-item">
              <i className="ti-power-off text-muted mr-2"></i> {patient_common('logout')}
            </NavLink>
            {/* <div className='logoutDiv'> */}
            {/* <Link className='ti-power-off text-muted mr-2' onClick={handleLogout.bind(this)}>Logout</Link> */}
            {/* </div> */}
          </div>
        </li>
      </ul>
    </div>
  )
}

export default TopBar
