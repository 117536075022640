import { NavLink, useNavigate } from 'react-router-dom'
import useAuth from '../../../../hooks/useAuth'
import LanguageForm from '../../../../components/languageForm/LanguageForm'
import useCreateLanguage from '../../../../hooks/api/useCreateLanguage'
import { useTranslation } from 'react-i18next'

const AddLanguage = (props) => {
  const { auth } = useAuth()
  const navigate = useNavigate()
  const { t: provider_languages } = useTranslation('provider_languages')

  const { data: createLanguageData, createLanguage, loading: createLanguageIsLoading } = useCreateLanguage()

  const handleSubmit = (data) => {
    const formData = new FormData()

    // Append other form fields
    formData.append('Name', data.Name)
    formData.append('LocalName', data.LocalName)
    formData.append('LanguageCodeIetf', data.LanguageCodeIetf)
    formData.append('SortOrder', data.SortOrder)
    formData.append('IsActive', data.IsActive == '1' ? 1 : 0)
    formData.append('FlagIcon', data.FlagIcon[0])
    formData.append('Email', auth.email || Cookies.get('email'))

    createLanguage(formData)
  }

  /*Once the status is success show the redirect to the language page*/
  if (createLanguageData.Status) {
    navigate('/provider/languages')
  }

  return (
    <div className="container-fluid pt-5">
      <div className="tab-content detail-list" id="pills-tabContent">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                  <li className="nav-item">
                    <NavLink to="/provider/translations" className="nav-link">
                      {provider_languages('translations')}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" id="languages_tab" data-toggle="pill" href="#languages">
                      {provider_languages('languages')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-pane fade show active" id="languages">
          <LanguageForm onSubmit={handleSubmit} isLoading={createLanguageIsLoading} provider_languages={provider_languages} />
        </div>
      </div>
    </div>
  )
}

export default AddLanguage
