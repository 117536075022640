import React from 'react'
import './filterTag.css'

const FilterTag = ({ text, onRemove }) => {
  return (
    <div className="deletable-chip">
      <span>{text}</span>
      <button className="close-icon" onClick={onRemove}>
        &times;
      </button>
    </div>
  )
}

export default FilterTag
