import { createSlice, current } from "@reduxjs/toolkit";
import { NS_DEF_ORG_ID, DEF_LANGUAGE_CODE, USER_TYPES } from 'v2/src/config/appConfig';
//---------------------------------------------------------

const iniUserDetails = {
  firstName: null, middleName: null, lastName: null,
  dispName: null, eMail: null, contactNum: null, profilePic: null,
  profileId: null, profiles: null, languageId: DEF_LANGUAGE_CODE, //notifications: {},
};
const iniAuthState = {
  userIdNH: null, userIdNS: null, eMail: null, orgId: null,
  userType: null, authTokenNH: null, authTokenNS: null,
  details: iniUserDetails, // awsAuthData: null}
};
const fDetectUserType = (uProfiles) => {
  let userType, profileId;
  if(uProfiles) {
    if(uProfiles?.provider?.profile_id) {
      userType = USER_TYPES.provider;
      profileId = uProfiles.provider.profile_id;
    } else if(uProfiles?.support?.profile_id) {
      userType = USER_TYPES?.support || null;
      profileId = uProfiles.support.profile_id;
    } else if(uProfiles?.driver?.profile_id) {
      userType = USER_TYPES?.driver || null;
      profileId = uProfiles.driver.profile_id;
    } else if(uProfiles?.admin?.profile_id) {
      userType = USER_TYPES?.admin || null;
      profileId = uProfiles.admin.profile_id;
    } else {
      userType = USER_TYPES.patient;
      profileId = uProfiles?.patient.profile_id;
    }
    userType = userType ?? USER_TYPES.patient;

    return {userType, profileId};
  }
};
const fPopulateUserDetails = (uData, profileId) => {
  const uDetails = structuredClone(iniUserDetails);
  uDetails.firstName  =  uData?.user?.first_name;
  uDetails.middleName =  uData?.user?.middle_name;
  uDetails.lastName   =  uData?.user?.last_name;
  uDetails.dispName   =  uData?.user?.first_name;
  uDetails.eMail      =  uData?.user?.email;
  uDetails.contactNum =  uData?.user?.phone;
  uDetails.profilePic =  null;
  uDetails.profileId  =  profileId;
  uDetails.profiles   =  uData?.profiles;// ?? null;
  return uDetails;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: structuredClone(iniAuthState),
  reducers: {
    rSetUserCredentials:(state, action) => {
      const aData = action.payload;
      if(aData?.user?.id) {
        const newSt = {...current(state)};
        const {profileId, userType} = fDetectUserType(aData?.profiles);
        const uDatails = fPopulateUserDetails(aData, profileId) || {};
        //console.log('newStUCred', {newSt, aData, profileId, userType, uDatails});
        //fSetAuthCookie2(aData);

        newSt.userIdNH = aData?.user?.id;
        newSt.userIdNS = aData?.user?.external_user_id;
        newSt.eMail = aData?.user?.email;
        newSt.orgId = NS_DEF_ORG_ID;
        newSt.userType = userType;
        newSt.authTokenNH = aData?.tokens?.health?.token;
        newSt.authTokenNS = aData?.tokens?.social?.token;
        newSt.details = uDatails;
        console.log('rsNewStUCred', newSt);
        return newSt;
      }
    },
    rSetUserDetails:(state, action) => {
      const pData = action.payload;
      //console.log('rsProfData', pData);
      if(pData?.id) {
        const newSt = structuredClone({...current(state)});
        //console.log('newStUPD', {newSt, pData});
        newSt.details['profilePic'] = pData?.profile_pic;
        newSt['details']['dob'] = pData?.date_of_birth;
        newSt['details']['gender'] = pData?.gender;
        //newSt['details']['tzId'] = pData?.timezone;
        newSt['details']['addresses'] = pData?.addresses;
        console.log('newStUPD', newSt);
        return newSt;
      }
    },
    rSetProfileData:(state, action) => {
      //
    },
    rSetProfilePic:(state, action) => {
      //
    },
    rSetLogOut: (state, action) => {
      //const newSt = {...current(state)};
      const newSt = structuredClone(iniAuthState);
      //fResetApiStat();
      return newSt;
      //return null;
    },
    rInvalidateUserCredentials:(state, action) => {
      return null;
    }
  },
});

export default authSlice.reducer;
export const { rSetUserCredentials, rSetUserDetails, rSetProfileData,
  rSetProfilePic, rSetLogOut, rInvalidateUserCredentials,
} = authSlice.actions;
export const aSGetAuthUserId = state => state.auth.userIdNH;
export const aSGetAuthUserEmail = state => state.auth.eMail;
//export const aSGetAuthTokenNH  = state => state.auth.authTokenNH;
export const aSGetAuthTokenNS  = state => state.auth.authTokenNS;
export const aSGetUserType  = state => state.auth.userType;
//export const aSGetUserRole  = state => state.auth.userType;
//export const aSGetUserOrgId  = state => state.auth.orgId;
export const aSGetAuthUserData = state => {
  //return state.auth;
  console.log('aaa', state.auth);
  return state.auth?.userIdNH ? state.auth : null;
};
//export const checkIsAdmin  = state => state.auth.email === SA_TEMP_DEF_ADMIN_EMAIL;
//export const checkIsAdmin  = state => SA_TEMP_ADMINS_FOR_FB.includes(state.auth.email);
//export const aSGetAuthUserAwsData = state => state.auth.awsAuthData;
