import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CharOK, CharErr } from 'v2/src/config/uiConfig';
import { fCheckPassCondition } from 'v2/src/lib/appUtils';
//---------------------------------------------------------

export default function PasswordValidationList ({ password, errors }) {
  const { t: authentication } = useTranslation('authentication');
  
  const [hasLowercase, setHasLowercase] = useState(CharErr);
  const [hasUppercase, setHasUppercase] = useState(CharErr);
  const [hasNumber, setHasNumber] = useState(CharErr);
  const [has8chars, setHas8chars] = useState(CharErr);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(CharErr);
  const [hasSpaceOnEnd, setHasSpaceOnEnd] = useState(CharErr);

  useEffect(() => {
    setHasLowercase(fCheckPassCondition(password?.match(/[a-z]/)?.length > 0))
    setHasUppercase(fCheckPassCondition(password?.match(/[A-Z]/)?.length > 0))
    setHasNumber(fCheckPassCondition(password?.match(/[0-9]/)?.length > 0))
    setHas8chars(fCheckPassCondition(password?.length >= 8))
    setHasSpecialCharacter(fCheckPassCondition(password?.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)?.length > 0))
    setHasSpaceOnEnd(fCheckPassCondition(!(password?.charAt(0) === ' ' || password?.charAt(password?.length - 1) === ' ')))
  }, [password]);

  return (
    <>
      {password?.length > 0 ? (
        <>
          <div className={hasLowercase === CharOK ? 'text-success' : 'text-danger'}>
            {`${hasLowercase}`} {authentication('password_must_contain_lowercase')}
            <br />
          </div>
          <div className={hasUppercase === CharOK ? 'text-success' : 'text-danger'}>
            {`${hasUppercase}`} {authentication('password_must_contain_uppercase')}
            <br />
          </div>
          <div className={hasNumber === CharOK ? 'text-success' : 'text-danger'}>
            {hasNumber} {authentication('password_must_contain_number')}
            <br />
          </div>
          <div className={has8chars === CharOK ? 'text-success' : 'text-danger'}>
            {has8chars} {authentication('password_must_contain_8_characters')}
            <br />
          </div>
          <div className={hasSpecialCharacter === CharOK ? 'text-success' : 'text-danger'}>
            {hasSpecialCharacter} {authentication('password_must_contain_special_character')}
            <br />
          </div>
          <div className={hasSpaceOnEnd === CharOK ? 'text-success' : 'text-danger'}>
            {hasSpaceOnEnd} {authentication('password_must_not_contain_space_at_end')}
            <br />
          </div>
        </>
      ) : null}
    </>
  )
};