import { createSlice } from "@reduxjs/toolkit";
//---------------------------------------------------------

export const patientSlice = createSlice({
  name: 'patient',
  initialState: {},
  reducers: {
    updatePatientState: (state, action) => {
      const data = action.payload
      if(Object.keys(data).length > 0) {
        return { ...state, ...data };
      } else {
        return {};
      }
    },
  },
});

export const { updatePatientState } = patientSlice.actions;
export default patientSlice.reducer;
