import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { NavLink } from 'react-router-dom'

const validationSchema = yup.object().shape({
  Name: yup.string().required('name_required'),
  LocalName: yup.string().required('local_name_required'),
  LanguageCodeIetf: yup.string().required('language_code_required'),
  FlagIcon: yup.mixed().required('flag_icon_required'),
  SortOrder: yup.number().typeError('sort_order_number_error').optional(),
  IsActive: yup.boolean().required('is_active_required'),
})

const LanguageForm = ({ onSubmit, initialData, isLoading, provider_languages }) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  // Function to set initial values when data is available
  const setInitialValues = () => {
    if (initialData) {
      setValue('Name', initialData.Name)
      setValue('LocalName', initialData.LocalName)
      setValue('LanguageCodeIetf', initialData.LanguageCodeIetf)
      setValue('SortOrder', initialData.SortOrder)
      setValue('IsActive', initialData.IsActive == 1 ? 1 : 0)
    }
  }

  // Call the function to set initial values when data is available
  setInitialValues()

  return (
    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <div className="row mb-3">
                    <div className="col-4">{provider_languages('name')}</div>
                    <div className="col-8">
                      <input
                        type="text"
                        className={`form-control ${errors.Name ? 'is-invalid' : ''} ${isLoading == true ? 'disabled' : ''}`}
                        {...register('Name')}
                        placeholder={provider_languages('name')}
                      />
                      {errors.Name && <label className="text-danger">{provider_languages(errors.Name.message)}</label>}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-4">{provider_languages('local_name')}</div>
                    <div className="col-8">
                      <input
                        type="text"
                        className={`form-control ${errors.LocalName ? 'is-invalid' : ''} ${isLoading == true ? 'disabled' : ''}`}
                        {...register('LocalName')}
                        placeholder={provider_languages('local_name')}
                      />
                      {errors.LocalName && <label className="text-danger">{provider_languages(errors.LocalName.message)}</label>}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-4">{provider_languages('language_code_ietf')}</div>
                    <div className="col-8">
                      <input
                        type="text"
                        className={`form-control ${errors.LanguageCodeIetf ? 'is-invalid' : ''} ${isLoading == true ? 'disabled' : ''}`}
                        {...register('LanguageCodeIetf')}
                        placeholder={provider_languages('language_code_ietf')}
                      />
                      {errors.LanguageCodeIetf && <label className="text-danger">{provider_languages(errors.LanguageCodeIetf.message)}</label>}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row mb-3">
                    <div className="col-4">{provider_languages('flag_icon')}</div>
                    <div className="col-8">
                      <input type="file" className={`${errors.FlagIcon ? 'is-invalid' : ''} ${isLoading == true ? 'disabled' : ''}`} {...register('FlagIcon')} />
                      {errors.FlagIcon && <label className="text-danger">{provider_languages(errors.FlagIcon.message)}</label>}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-4">{provider_languages('sort_order')}</div>
                    <div className="col-8">
                      <input
                        type="number"
                        className={`form-control ${errors.SortOrder ? 'is-invalid' : ''} ${isLoading == true ? 'disabled' : ''}`}
                        {...register('SortOrder')}
                        placeholder={provider_languages('sort_order')}
                      />
                      {errors.SortOrder && <label className="text-danger">{provider_languages(errors.SortOrder.message)}</label>}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-4">{provider_languages('is_active')}</div>
                    <div className="col-8">
                      <input type="checkbox" className={`${errors.IsActive ? 'is-invalid' : ''} ${isLoading == true ? 'disabled' : ''}`} {...register('IsActive')} />
                      {errors.IsActive && <label className="text-danger">{provider_languages(errors.IsActive.message)}</label>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-between">
        <NavLink to="/provider/languages" className={`btn btn-danger btn-round waves-effect waves-light mt-2 m-2 ${isLoading == true ? 'disabled' : ''}`}>
          Back
        </NavLink>
        <button type="submit" className={`btn btn-success btn-round waves-effect waves-light mt-2 m-2 ${isLoading == true ? 'disabled' : ''}`}>
          {isLoading == true ? 'Loading...' : 'Submit'}
        </button>
      </div>
    </form>
  )
}

export default LanguageForm
