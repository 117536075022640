// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-option {
  display: flex;
  align-items: center;
}

.flag-icon {
  margin-right: 10px; /* Adjust the margin as needed to control the spacing */
  margin-left: 10px; /* Adjust the margin as needed to control the spacing */
}

.option-text {
  flex-grow: 1; /* This will make the text fill the remaining space */
}

.custom-option:hover {
  cursor: pointer;
}

.selected-flag-icon {
  margin-right: 8px; /* Add spacing between the flag and label */
}
`, "",{"version":3,"sources":["webpack://./src/v2/src/core/LanguageDropdown/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB,EAAE,uDAAuD;EAC3E,iBAAiB,EAAE,uDAAuD;AAC5E;;AAEA;EACE,YAAY,EAAE,qDAAqD;AACrE;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB,EAAE,2CAA2C;AAChE","sourcesContent":[".custom-option {\n  display: flex;\n  align-items: center;\n}\n\n.flag-icon {\n  margin-right: 10px; /* Adjust the margin as needed to control the spacing */\n  margin-left: 10px; /* Adjust the margin as needed to control the spacing */\n}\n\n.option-text {\n  flex-grow: 1; /* This will make the text fill the remaining space */\n}\n\n.custom-option:hover {\n  cursor: pointer;\n}\n\n.selected-flag-icon {\n  margin-right: 8px; /* Add spacing between the flag and label */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
