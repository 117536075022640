import * as yup from 'yup';
import { requiredError, emailError, emailMaxLengthError, emailMinLengthError,
	passwordMinLengthError, passwordMaxLengthError, passwordFormatError
} from 'constants';

export const LoginSchema = yup
  .object({
    Email: yup.string().email(emailError).required(requiredError).min(6, emailMinLengthError).max(255, emailMaxLengthError),
    Password: yup
      .string()
      .required('required_error')
      .min(8, 'password_min_length_error')
      .max(50, 'password_max_length_error')
      .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-={}|;:'\",.<>?]).{8,}$/, 'password_format_error'),
  })
  .required();

export default LoginSchema;
