import { useEffect, useState } from 'react'
import useAxiosPrivate from '../useAxiosPrivate'

const useGetServiceCategories = () => {
  const axiosPrivate = useAxiosPrivate()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const controller = new AbortController()

  const getServiceCategories = async () => {
    try {
      const response = await axiosPrivate.get('categories', {}, { signal: controller.signal })
      setData(response.data)
      setLoading(false)
      return response.data
    } catch (error) {
      setError(error)
      setLoading(false)
    } finally {
      controller.abort()
    }
  }

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return { data, loading, error, getServiceCategories }
}

export default useGetServiceCategories
