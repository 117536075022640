// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-pic {
  width: 100px;
  max-height: 100px;
  display: inline-block;
  margin-top: 10px;
  margin-left: 20px;
}

/* .file-upload { display: none; } */
.circle {
  border-radius: 100% !important;
  overflow: hidden;
  width: 128px;
  height: 128px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 72px;
}
.p-image {
  position: absolute;
  top: 167px;
  right: 30px;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.p-image:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.upload-button {
  font-size: 1.2em;
}

.upload-button:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: #999;
}

.id-container {
  padding: 1rem;
  margin: 1rem;
  background-color: #f6f6f6;
}

.image-close-icon {
  height: 25px;
  padding: 0;
  width: 25px;
}

.id-image {
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/profile/icon.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,qBAAqB;EACrB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA,oCAAoC;AACpC;EACE,8BAA8B;EAC9B,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,0CAA0C;EAC1C,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,cAAc;EACd,4DAA4D;AAC9D;AACA;EACE,4DAA4D;AAC9D;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,4DAA4D;EAC5D,WAAW;AACb;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".profile-pic {\n  width: 100px;\n  max-height: 100px;\n  display: inline-block;\n  margin-top: 10px;\n  margin-left: 20px;\n}\n\n/* .file-upload { display: none; } */\n.circle {\n  border-radius: 100% !important;\n  overflow: hidden;\n  width: 128px;\n  height: 128px;\n  border: 2px solid rgba(255, 255, 255, 0.2);\n  position: absolute;\n  top: 72px;\n}\n.p-image {\n  position: absolute;\n  top: 167px;\n  right: 30px;\n  color: #666666;\n  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);\n}\n.p-image:hover {\n  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);\n}\n.upload-button {\n  font-size: 1.2em;\n}\n\n.upload-button:hover {\n  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);\n  color: #999;\n}\n\n.id-container {\n  padding: 1rem;\n  margin: 1rem;\n  background-color: #f6f6f6;\n}\n\n.image-close-icon {\n  height: 25px;\n  padding: 0;\n  width: 25px;\n}\n\n.id-image {\n  width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
