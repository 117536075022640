import { createContext, useState } from 'react'
import Cookies from 'js-cookie'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    email: Cookies.get('email') || '',
    accessToken: Cookies.get('accessToken') || '',
    userId: Cookies.get('userId') || '',
    userType: Cookies.get('userType') || '',
    isLoggedIn: Cookies.get('isLoggedIn') || '',
    // id_token: Cookies.get('id_token') || '',
    // refresh_token: Cookies.get('refresh_token') || '',
    // token_type: Cookies.get('token_type') || '',
    // transactionType: Cookies.get('transactionType') || '',
    // email_verified: Cookies.get('email_verified') || '',
    // sub: Cookies.get('sub') || '',
    // username: Cookies.get('username') || '',
  })
  const [persist, setPersist] = useState(JSON.parse(localStorage.getItem('persist')) || true)

  return <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>{children}</AuthContext.Provider>
}

export default AuthContext
