import { useEffect, useState, useRef } from "react";
//import moment from "moment";
import ImageViewer from "react-simple-image-viewer";
import { FaTimes } from "react-icons/fa";
import Swal from 'sweetalert2';
//import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
//import i18n from '../../i18n';
import { USERTYPE } from '../../constants';
import { fGetTrimmedText, fGetUrlFileName, fGetFileExt} from '../../utils';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
//import useAuth from '../../hooks/useAuth';
import useGetIdentificationDocumentList from '../../hooks/api/useGetIdentificationDocumentList';
import useDeleteDocument from '../../hooks/api/useDeleteDocument';
//import PatientProfileSchema from '../../validation/patientProfile';
import { ContainerFluid, PageWrapper, TableTitle } from '../../components/table/Tables';
import CardItem from '../../components/cards/Card';
//---------------------------------------------------------

export default function ProfileIdDocument({compData, compMethods}) {
  //const flgEnableAddIdDoc = compData.flgEnableAddIdDoc;
  const disableForm = compData.disableForm;
  const auth = compData.auth;
  const profile = compData.profile;
  const fGetProfileDetails = compMethods.getProfileDetails;
  const arrIdDocFileTypes = ['pdf', 'webp', 'png', 'jpeg', 'jpg', 'jiff', 'jfif'];
  const maxIdDoc = 3;
  let currShownIdDocCount = 0, flgEnableAddIdDoc = false;

  const axiosPrivate = useAxiosPrivate();
  const { t: error_message } = useTranslation('error_message');
  const { t: comp_profile } = useTranslation('comp_profile');

  const [stCurrProfileDocs, setStCurrProfileDocs] = useState([]);
  const [stDocTypeOptions, setStDocTypeOptions] = useState([]);
  const [stImages, setStImages] = useState([]);
  const [stDocTypes, setStDocTypes] = useState([]);  
  const [stProfileDocsInf, setStProfileDocsInf] = useState([]);
  //const [stIdUploaded, setStIdUploaded] = useState(null);
  const [stCurrImg, setStCurrImg] = useState(0);
  const [stIsViewerOpen, setStIsViewerOpen] = useState(false);
  const [stPgMsg, setStPgMsg] = useState('');
  //const [stAjaxStep, setStAjaxStep] = useState(0);//-1:Err, 0: Nop, 1:wait, 2: OK

  currShownIdDocCount = (stCurrProfileDocs?.length || 0) + (stImages?.length || 0);
  flgEnableAddIdDoc = currShownIdDocCount < maxIdDoc;
  let remainMaxIdDoc = maxIdDoc - (stCurrProfileDocs?.length || 0);

  const { data: identificationDocuments,
    getIdentificationDocumentList } = useGetIdentificationDocumentList();
  const { data: deleteDocumentData, deleteDocument } = useDeleteDocument();

  const {
    /*register: PatientRegister,
    trigger: PatientProfileTrigger,*/
    setValue: profIdDocSetValue,
    getValues: profIdDocGetValues,
    control: ProfIdDocsControl,
    //formState: { errors: PatientProfileError, isValid: PatientProfileIsValid },
  } = useForm({
    //resolver: yupResolver(PatientProfileSchema),
  });
  const fCalcProfileDocsInf = () => {
    setTimeout(() => {
      const maxNewIdDoc = maxIdDoc - (stCurrProfileDocs.length || 0);
      const arrIdDocInf = [];
      if(maxNewIdDoc > 0) {
        for(let i=0; i<maxNewIdDoc; i++ ) {
          arrIdDocInf[i] = {
            id: 'err_'+i, errCode: null, hasErr: false, arrErrMsg: [], isViewAdded: false
          };
        }
        setStProfileDocsInf(arrIdDocInf);
        //console.log('arrIdDocInfCalc', {maxNewIdDoc, stCurrProfileDocs, arrIdDocInf});
      }
      setStPgMsg('');
    }, 1000);
  };
  //---------------------------------------------------------

  useEffect(() => {
    if(auth.userType === USERTYPE.patient) {
      getIdentificationDocumentList({ Email: auth.email })
    }
  }, []);
  
  useEffect(() => {
    if(profile?.documents) {
      if(profile?.documents.length) {
        setStCurrProfileDocs(profile.documents);
      }
      fCalcProfileDocsInf();
    }
  }, [profile, setStProfileDocsInf]);//, stProfileDocsInf

  useEffect(() => {
    if(identificationDocuments) {
      let docTypeOpts = [];
      if(identificationDocuments.Status) {
        identificationDocuments.Data.map((documentData) => {
          docTypeOpts.push({ label: documentData.name, value: documentData.id });
        })
      }
      setStDocTypeOptions(docTypeOpts);
    }
  }, [identificationDocuments]);

  useEffect(()=> {fCalcProfileDocsInf()}, [stCurrProfileDocs]);
  //---------------------------------------------------------

  const fOpenImageViewer = (index) => {
    setStCurrImg(index); setStIsViewerOpen(true);
  };
  const fCloseImageViewer = () => {
    setStCurrImg(0); setStIsViewerOpen(false);
  };
  const fAddImageBox = () => {
    console.log('fAddImgs', {stDocTypes, stImages, stCurrImg, stCurrProfileDocs, currShownIdDocCount});
    if(flgEnableAddIdDoc) {
      const currProfDocInf = structuredClone(stProfileDocsInf);
      let currIdx = stImages.length - 1;
      if(currIdx < 0) { currIdx = 0; }//console.log('AAA', {currIdx, stImages});
      currProfDocInf[currIdx]['isViewAdded'] = true;
      setStProfileDocsInf(currProfDocInf);

      setStImages([...stImages, undefined]);
      // Reset the dropdown to an empty string
      setStDocTypes([...stDocTypes, '']);
    }
  };
  const fRemoveImage = (index) => {
    const updatedImages = stImages.filter((_, i) => i !== index);
    const updatedDocumentTypes = stDocTypes.filter((_, i) => i !== index);
    setStImages(updatedImages);
    setStDocTypes(updatedDocumentTypes);
    fCalcProfileDocsInf();
    /*const currProfDocInf = structuredClone(stProfileDocsInf);
    currProfDocInf[index]['isViewAdded'] = false;
    setStProfileDocsInf(currProfDocInf);*/
  };
  //---------------------------------------------------------

  const fValidateIdDocUploads = (objFrmVals, upIdDocStat) => {
    let resp = true, upFileSize = 0, upFileSizeErrCode = null, arrErrMsg = [];
    //console.log('objFrmVals', objFrmVals);
    console.log('fValidateIdDoc1', {upIdDocStat, objFrmVals});
    //setStPgMsg('');
    if(upIdDocStat?.length) {
      //id: 'err_'+i, errCode: null, hasErr: false, arrErrMsg: [],
      upIdDocStat.forEach((eIdObj, idx) => {//console.log('aaaa', idx);
        //if(eIdObj?.isViewAdded) {
          let hasUpFile = false, hasSelDocType = false, fExt = null;
          hasUpFile = 'documentImages['+idx+']';
          if(objFrmVals.hasOwnProperty(hasUpFile)) {
            hasUpFile = objFrmVals[hasUpFile];
            console.log('upF'+idx, hasUpFile);
            if(hasUpFile && hasUpFile !== 'undefined') {
              //const objFIn = document.getElementById('idDocImg_'+idx);
              //if(objFIn?.value) {
                upFileSize += parseInt(hasUpFile?.size)
                //fExt = fGetFileExt(objFIn.value);
                fExt = fGetFileExt(hasUpFile?.name);
                console.log('objFIn_'+idx, hasUpFile.name, fExt);
                if(fExt && arrIdDocFileTypes.includes(fExt)) {}
                else {
                  resp = false; eIdObj.hasErr = true; eIdObj.errCode = 'e_3';
                  //eIdObj.arrErrMsg = idx+' A3';
                  eIdObj.arrErrMsg.push('Only JPEG / PNG / PDF files are allowed');
                  //arrErrMsg.push(idx+' A3');
                }
              //}
            } else {
              resp = false; eIdObj.hasErr = true; eIdObj.errCode = 'e_2';
              //eIdObj.arrErrMsg = idx+' A2';
              eIdObj.arrErrMsg.push('Document file is not selected');
              //arrErrMsg.push(idx+' A2');
            }
          }// else { hasUpFile = null; }
          //----------------

          hasSelDocType = 'documentTypes['+idx+']';
          if(objFrmVals.hasOwnProperty(hasSelDocType)) {
            hasSelDocType = parseInt(objFrmVals[hasSelDocType]);
            if(hasSelDocType !== NaN && hasSelDocType > 0) {}
            else {
              resp = false; eIdObj.hasErr = true; eIdObj.errCode = 'e_1';
              //eIdObj.arrErrMsg = idx+' A1';
              eIdObj.arrErrMsg.push('Document type is not selected');
              //arrErrMsg.push(idx+' A1');
            }
          }// else { hasSelDocType = null; }
        //}
      });

      if(upFileSize && parseInt(upFileSize/1048576) > 10) {
        resp = false;
        upFileSizeErrCode = 'e_4';
        //arrErrMsg.push('The total size of ID document files cannot exceed 10 MB.');
      }

      if(!resp) {
        resp = { result: false, upIdDocStat, upFileSize, upFileSizeErrCode};//arrErrMsg,
      } else { resp = { result: true};}
    }console.log('fValidateIdDoc2', {resp, upFileSize, upIdDocStat});

    return resp;
  };
  const fHandleSubmit = async () => {
    const formData = new FormData();
    let frmVals;

    if(auth.userType === USERTYPE.patient) {
      {
      //ReqFields: Email, FirstName, LastName, ContactInfo,
      // Address1, Address2, ZIPCode, Province, DateOfBirth,
      // LocalTimeZone, CountryID, City
      formData.append('Email', auth.email);
      formData.append('FirstName', auth.first_name);
      formData.append('LastName', auth.last_name);
      formData.append('ContactInfo', auth.contact_info);
      formData.append('Address1', auth.address_line_1);
      formData.append('Address2', auth.address_line_2);
      formData.append('ZIPCode', auth.zip_code);
      formData.append('Province', auth.province);
      formData.append('DateOfBirth', auth.date_of_birth);
      formData.append('LocalTimeZone', auth.local_time_zone);
      formData.append('CountryID', auth.country_id);
      formData.append('City', auth.city);
      }
      //if(stIdUploaded == false) { return false; }

      stImages?.forEach((img, index) => {
        formData.append('documentImages[' + index + ']', img);
      });
      profIdDocGetValues('documentTypes')?.forEach((idDocType, index) => {
        formData.append('documentTypes[' + index + ']', idDocType);
      });

      frmVals = Object.fromEntries(formData);
      console.log('formData', frmVals);
      const profDocInf = structuredClone(stProfileDocsInf);
      profDocInf.forEach((idDObj)=> {
        idDObj.errCode = null; idDObj.hasErr = false; idDObj.arrErrMsg = [];
      });//console.log('Damn', profDocInf);//return;
      const respValidIdDocs = fValidateIdDocUploads(frmVals, profDocInf);
      setStPgMsg('');
      console.log('respValidIdDocs', respValidIdDocs);
      if(respValidIdDocs?.result) {
        await axiosPrivate
        .post('updatePatientDetails', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          //onUploadProgress: function (ProgressEvent) { },
        })
        .then((res) => {
          const { Status, Data: data = [], Message } = res.data;
          if(Status) {
            compMethods.setAuth((prev) => ({ ...prev, ...profile }));
            compMethods.setOldProfile(profile);
            Swal.fire({ icon: 'success', text: comp_profile('profile_id_doc_update_success') });
            //!flgEditable && setDisableForm(!disableForm)

            setStImages([]);
            fGetProfileDetails();
            //setStCurrProfileDocs([]);
          } else {
            Swal.fire({ icon: 'error', text: Message });
            //throw new Error(Message);
          }
        })
        .catch((err) => { console.error(err); })
        fCalcProfileDocsInf();//setStPgMsg('');
      } else {
        let arrErr = [], errMsg='';
        if(respValidIdDocs?.upFileSizeErrCode === 'e_4') {
          arrErr.push('The total size of ID document files cannot exceed 10 MB.');
        }

        if(respValidIdDocs?.upIdDocStat) {
          /*respValidIdDocs.upIdDocStat.forEach((eIdObj, idx) => {
            if(eIdObj.hasErr) {//isViewAdded
              switch(eIdObj.errCode) {
                case 'e_1': arrErr.push('Document type ('+(idx+1)+') is not selected'); break;
                case 'e_2': arrErr.push('Document file ('+(idx+1)+') is not selected'); break;
                case 'e_3': arrErr.push('Only JPEG / PNG / PDF files are allowed'); break;
                default:
                  arrErr.push('There is some error. Please refresh the page & try again..');
                  break;
              }
            }
          });*/

          arrErr = [...new Set(arrErr)];
          const arrErr2 = [];
          if(arrErr.length > 0) {
            arrErr.forEach((errS, idx)=> {
              //arrErr2.push(<p style={{paddingLeft: '10px'}} key={'err_'+idx}>{errS}</p>);
              arrErr2.push(<p key={'errPg_'+idx}>{errS}</p>);
            });
            //arrErr2.unshift(<p key={'err_ttl'}>{'Please fix below errors to continue:'}</p>);
            arrErr = arrErr2;
          }
        }
        setStProfileDocsInf(respValidIdDocs?.upIdDocStat);
        arrErr?.length && setStPgMsg(arrErr); //setStPgMsg(respValidIdDocs?.arrErrMsg);
      }
    }
  };
  const fDelDocumentHandler = async (id) => {
    const deletedApiRes = await deleteDocument({ Id: id, Email: auth.email })
    if(deletedApiRes?.Status) {
      fGetProfileDetails();
      const newProfileDocs = stCurrProfileDocs.filter((item) => item.Id !== id);
      setStCurrProfileDocs(newProfileDocs);
      fCalcProfileDocsInf();//setStPgMsg('');
    }
  };
  //---------------------------------------------------------

  //let profileDocsJsx;
  //let flgDisableSubmit = !flgEnableAddIdDoc;
  let flgDisableSubmit = !(remainMaxIdDoc > 0);
  //console.log('profPg3', {auth, profile, stCurrProfileDocs, identificationDocuments, stDocTypeOptions, stProfileDocsInf, stImages});
  //---------------------------------------------------------

  const errIdDocCss = {border: '2px dashed #f14b4b'};
  const btnClassName = 'btn btn-danger btn-round waves-effect waves-light image-close-icon';
  const fGetCurrIdDocBoxJsx = (pDoc, idx) => {
    const fName = pDoc?.FileName;
    let fImgTitle = pDoc?.DocumentTypeName;
    if(!fImgTitle) { fImgTitle = fGetUrlFileName(fName); }
    fImgTitle = fGetTrimmedText(fImgTitle, 20);

    return (
      <div key={idx} className="id-container card col-md-3">
        <div className="document-type-dropdown">
          <label>Document Type : </label>
          <label className="image-close-icon float-right">
            <button type="button" className={btnClassName}
              onClick={() => { fDelDocumentHandler(pDoc.Id) }}
            ><FaTimes /></button>
          </label><br />
          <div className="mb-2">{pDoc.DocumentTypeName}</div>
        </div>
        <div>
          <div className="image-preview mb-2">
            <img className="id-image" src={fName} alt={fImgTitle} />
          </div>
        </div>
      </div>
    )
  };

  const fUploadCss = {
    width: '100%', textOverflow: 'clip',//'ellipsis',
    textSizeAdjust: '90%', //whiteSpace: 'nowrap',
    fontSize: '83%',//'calc(1vw + 1vh)',
  };
  const fGetNewIdDocBoxJsx = (idx) => {
    const elDocInf = stProfileDocsInf[idx];console.log('elDocInf_'+idx, elDocInf);
    const hasErr = elDocInf?.hasOwnProperty('hasErr') && elDocInf['hasErr'];
    const elDErr = hasErr && elDocInf['arrErrMsg']?.length ?
      elDocInf['arrErrMsg'] : [];
    const errJsx = [];
    if(elDErr?.length) {
      elDErr.reverse();
      elDErr.forEach((errS, idx)=> {
        errJsx.push(<p className="m-1" key={'err_'+idx+'_'+elDocInf?.id}>{errS}</p>);
      });
    }
    return (
      <div key={idx} id={'ctnprofIdDoc_'+idx}
        className="id-container card col-md-3"
        {...(hasErr && { style: errIdDocCss } )}
      >
        <div className="document-type-dropdown">
          <label>Document Type : </label>
          <label className="image-close-icon float-right">
            <button type="button" className={btnClassName}
              onClick={() => fRemoveImage(idx)}
            ><FaTimes /></button>
          </label>
          <Controller name={`documentTypes[${idx}]`}
            control={ProfIdDocsControl} //defaultValue=""
            render={({ field }) => (
              <select {...field} className="form-control mb-2" defaultValue="">
                <option value="" disabled>
                  {comp_profile('select_document_type')}
                </option>
                {stDocTypeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            )}
          />
        </div>

        <div className="xtBdr2" style={{minHeight: '7.5rem'}}>
          <div className="image-preview mb-2"
            onClick={() => fOpenImageViewer(idx)}
          >
            {stImages[idx] && typeof stImages[idx] !== 'string' &&
              <img className="id-image" alt={`Image ${idx}`}
                src={URL.createObjectURL(stImages[idx])} />
            }
          </div>
          <div>
            <input type="file" className="mb-2" style={fUploadCss}
              name={'idDocImg_'+idx} id={'idDocImg_'+idx}
              accept="image/*,.pdf" //capture=
              onChange={(e) => {
                const selectedFile = e.target.files[0];
                setStImages((prevImages) => {
                  const updatedImages = [...prevImages];
                  updatedImages[idx] = selectedFile;
                  profIdDocSetValue('images', updatedImages.length)
                  //PatientProfileTrigger('images')
                  return updatedImages;
                })
              }}
            />
          </div>
        </div>
        <div className="text-danger xtBdr2">
          {hasErr && errJsx}
        </div>
      </div>
    )
  };
  //---------------------------------------------------------

  return (//auth.userType === USERTYPE.patient && (
    <>
      <form>
        <PageWrapper>
          <ContainerFluid>
            <TableTitle title="Identification Document" />
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="file-box-content">
                      <CardItem>
                        {comp_profile('upload_id_doc_msg2')}<br/>
                      </CardItem>
                    </div>
                    <div className='padS dFlex'>
                        {/*<div>&nbsp;</div>*/}
                        <div className="col-sm-12 text-right mb-2">
                          <button type="button" disabled={disableForm}
                            className="btn btn-success btn-round waves-effect waves-light mb-2"
                            onClick={fAddImageBox}
                            {...(!flgEnableAddIdDoc && {
                              disabled: true, style: {cursor: 'not-allowed'}
                            })}
                          >
                            {comp_profile('add_id_document_button')}
                          </button>
                          {/*!disableForm && stIdUploaded == false && <p className="text-danger">{comp_profile('upload_id_doc_msg')}</p>*/}
                        </div>
                    </div>
                    {/*<div className="xtBdr2 txAC">
                      {stPgMsg && <label className="text-danger">{stPgMsg}</label>}
                    </div>*/}
                    <div className="row"
                      //style={{minHeight: '200px'}}
                    >
                      <div className="form-group row wA" style={{justifyContent: 'center'}}>
                        {stCurrProfileDocs &&
                          stCurrProfileDocs.map((pDoc, index) => {
                            return fGetCurrIdDocBoxJsx(pDoc, index);
                          })
                        }
                        {stImages.map((_, index) => {
                          return fGetNewIdDocBoxJsx(index);
                        })}
                        {stIsViewerOpen && 
                          <ImageViewer src={stImages.map((img) => URL.createObjectURL(img))}
                            currentIndex={stCurrImg} onClose={fCloseImageViewer} />
                        }
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-left xtBdr2">
                        {stPgMsg && <div className="text-danger">{stPgMsg}</div>}
                      </div>
                      <div className="col text-right">
                        <button type="button"
                          className="btn btn-success btn-round waves-effect waves-light"
                          style={{marginRight: '10px'}}
                          onClick={fHandleSubmit}
                          disabled={flgDisableSubmit}
                        >{comp_profile('submit_button')}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ContainerFluid>
        </PageWrapper>
      </form>
    </>
  );
}