//import { createSlice, current } from "@reduxjs/toolkit";
import { nHApi } from 'v2/src/lib/services/public/nHApi';
import { USER_TYPES } from 'v2/src/config/appConfig';
//---------------------------------------------------------

export const nHDocumentApi = nHApi.injectEndpoints({ endpoints: builder => ({
  nHDocumentTypes: builder.query({
    query: (params) => {
      let userRole = USER_TYPES.patient;
      if(params?.userType) {
        userRole = params?.userType;
      }
      //userRole = '?user_role=' + userRole;
      let aUrl = '/document-types?user_role=' + userRole;

      return { url: aUrl, };
    },
    keepUnusedDataFor: 1200,
  }),
  userDocumentList: builder.query({
    query: (params) => {
      if(params?.profileId) {
        userRole = params?.profileId;
      }
      let aUrl = '/documents?profile_id=' + profileId;

      return { url: aUrl, };
    },
  }),
  userDocumentDetail: builder.query({
    query: (params) => {
      if(params?.profileId) {
        userRole = params?.profileId;
      }
      const docId = params.docId;
      let aUrl = '/documents/' + docId;

      return { url: aUrl, };
    },
  }),
  addUserDocument: builder.mutation({
    query: (params) => {
      return {
        //profile_id, document_type_id, file
        url: '/document',
        method: 'POST',
        body: params,
        //validateStaus: (response, result) =>
        //  response.status === 200 && !result.isError,
      }
    },
  }),
  setUserDocument: builder.mutation({
    query: (params) => {
      return {
        //document_type_id,status, file, veridfied_at, expires_at
        url: '/document/'+params.docId,
        method: 'POST',
        body: params,
        //validateStaus: (response, result) =>
        //  response.status === 200 && !result.isError,
      }
    },
  }),
  deleteUserDocument: builder.mutation({
    query: (params) => {
      return {
        url: '/document/'+params.docId,
        method: 'DELETE',
      };
    },
  }),
	
}), });

export const { useNHDocumentTypesQuery,
  useUserDocumentListQuery, useUserDocumentDetailQuery,
  useAddUserDocumentMutation, useSetUserDocumentMutation,
  useDeleteUserDocumentMutation
} = nHDocumentApi;
//---------------------------------------------------------

