import { NavLink, useNavigate } from 'react-router-dom'
import useAuth from '../../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import ModuleForm from '../../../../components/moduleForm/ModuleForm'
import useCreateModule from '../../../../hooks/api/useCreateModule'

const AddModule = (props) => {
  const { auth } = useAuth()
  const navigate = useNavigate()
  const { t: provider_modules } = useTranslation('provider_modules')

  const { data: createModuleData, createModule, loading: createModuleIsLoading } = useCreateModule()

  const handleSubmit = (data) => {
    const formData = new FormData()

    // Append other form fields
    formData.append('Name', data.Name)
    formData.append('FileName', data.FileName)
    formData.append('Description', data.Description)
    formData.append('SortOrder', data.SortOrder)
    formData.append('Email', auth.email || Cookies.get('email'))

    createModule(formData)
  }

  /*Once the status is success show the redirect to the Module page*/
  if (createModuleData.Status) {
    navigate('/provider/translations')
  }

  return (
    <div className="container-fluid pt-5">
      <div className="tab-content detail-list" id="pills-tabContent">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="translations_tab" data-toggle="pill" href="#translations">
                      Translations
                    </a>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/provider/languages" className="nav-link">
                      Languages
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-pane fade show active" id="translations">
          <ModuleForm onSubmit={handleSubmit} isLoading={createModuleIsLoading} provider_modules={provider_modules} />
        </div>
      </div>
    </div>
  )
}

export default AddModule
