import * as yup from 'yup'

export const MarketplacePaymentSchema = yup
  .object({
    isBookingPaid: yup.boolean(),
    number: yup.string().when('isBookingPaid', {
      is: true,
      then: () => yup.string().required('required_error'),
    }),
    name: yup.string().when('isBookingPaid', {
      is: true,
      then: () =>
        yup
          .string()
          .required('required_error')
          .matches(/^[A-Za-z\s]+$/, 'card_full_name_error'),
    }),
    expiry: yup.string().when('isBookingPaid', {
      is: true,
      then: () => yup.string().required('required_error'),
    }),
    cvc: yup.string().when('isBookingPaid', {
      is: true,
      then: () =>
        yup
          .string()
          .required('required_error')
          .matches(/^[0-9]{3,4}$/, 'cvv_length_error'),
    }),
  })
  .required()

export default MarketplacePaymentSchema
