import * as yup from 'yup'

const validateImage = (file) => {
  if (!file) {
    return yup.mixed().notRequired()
  }

  return yup.mixed().required('required_error')
}

export const InsuranceSchema = yup
  .object({
    BucketName: yup.string().required('required_error').min(1, 'name_min_length_error').max(255, 'name_max_length_error'),
    images: yup.number().required('upload_front_back_image').min(2, 'upload_two_images').max(2, 'upload_only_two_images'),
  })
  .required()

export default InsuranceSchema
