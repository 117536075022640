import { useEffect, useState } from 'react'
import useAxiosPrivate from '../useAxiosPrivate'
import { useDispatch } from 'react-redux'
//import { updatePatientState } from '../../redux/slice/patient'
import { updatePatientState } from 'v2/src/lib/services/patient/patientSlice';
import { useSelector } from 'react-redux'

const useGetValidateCoupon = () => {
  const axiosPrivate = useAxiosPrivate()
  const controller = new AbortController()
  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)

  const validateCoupon = async (apiParams) => {
    setLoading(true)
    try {
      const response = await axiosPrivate.post('validateCoupon', apiParams, { signal: controller.signal })
      setData(response.data)
      return response.data
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
      controller.abort()
    }
  }

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return { data, loading, error, validateCoupon }
}

export default useGetValidateCoupon
