import moment from 'moment'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import CardItem from '../../../components/cards/Card'
import Footer from '../../../components/Footer'
import RingLoading from '../../../components/lottie/RingLoading'
import TableCard from '../../../components/table/Tables'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useGetUserPayment from '../../../hooks/api/useGetUserPayment'
import useGetUserSubscriptionDetails from '../../../hooks/api/useGetUserSubscriptionDetails'
import useGetSubscriptionPlan from '../../../hooks/api/useGetSubscriptionPlan'
import useCancelUserSubscription from '../../../hooks/api/useCancelUserSubscription'
import { isSubsUnderGracePeriod, dateFormat, getInsuranceStatus, getSubscriptionStatus, getUserSubscriptionDetailsStatusList } from '../../../utils'
import { APP_BASE_URL, AWS_BUCKET } from '../../../constants'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useTranslation } from 'react-i18next'
//import imgLogo from 'assets/images/nu-health-verticle-logo.png'
import imgLogo from 'assets/images/niuHealthLogo.svg';
//---------------------------------------------------------

function NewSubscription() {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const { t: patient_subscription } = useTranslation('patient_subscription')
  const flgShowPlansConcise = 10;
  const defaultSubsPlanId = 6;//12Months

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingPayments, setIsLoadingPayments] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)

  const [subscriptionPlans, setSubscriptionPlans] = useState([])
  const [paymentHistory, setPaymentHistory] = useState([])

  const [showSubPlan, setShowSubPlan] = useState(false)
  const [subIsActive, setSubIsActive] = useState(false)
  const [showCancelBtn, setShowCancelBtn] = useState(false)
  const [disableCancelBtn, setDisableCancelBtn] = useState({ status: false, message: '' })
  const [showWaitMsg, setShowWaitMsg] = useState(false)
  const [stSelSubsPlan, setStSelSubsPlan] = useState(defaultSubsPlanId);
  const [subs, setSubs] = useState({ subsStart: '', subsEnd: '' })

  /* Call the API and get the data */
  const { getSubscriptionPlan } = useGetSubscriptionPlan()
  const { getUserPayment } = useGetUserPayment()
  const { getUserSubscriptionDetails } = useGetUserSubscriptionDetails()
  const { cancelUserSubscription } = useCancelUserSubscription()

  useEffect(() => {
    // setIsLoading(false)

    ;(async function () {
      let [subscriptionPlanApiResp, userSubscriptionDetailsApiResp, paymentHistoryApiResp] = await Promise.all([
        getSubscriptionPlan(),
        getUserSubscriptionDetails({ Email: auth.email }),
        getUserPayment({ Email: auth.email }),
      ])

      let subPlan = subscriptionPlanApiResp?.Data
      setSubscriptionPlans(subPlan)

      setSubs({
        subsStart: userSubscriptionDetailsApiResp?.Data[0]?.subscription_start,
        subsEnd: userSubscriptionDetailsApiResp?.Data[0]?.subscription_end,
      })

      const [showSubscriptionPlans, subscriptionIsActive, showCancelButton, showWaitingMessage] = getUserSubscriptionDetailsStatusList(userSubscriptionDetailsApiResp)
      setShowSubPlan(showSubscriptionPlans)
      setSubIsActive(subscriptionIsActive)
      setShowCancelBtn(showCancelButton)
      setShowWaitMsg(showWaitingMessage)

      let payHist = paymentHistoryApiResp?.Data
      setPaymentHistory(payHist)

      /*Check if the cancel button is disabled*/
      const subsUnderGracePeriod = isSubsUnderGracePeriod(userSubscriptionDetailsApiResp, 14)
      if (subsUnderGracePeriod) {
        setDisableCancelBtn({ status: true, message: patient_subscription('cancel_subscription_grace_period_message') })
      }

      setIsLoading(false)
      setIsLoadingPayments(false)
      setIsSuccess(true)
    })()
  }, [])

  const cancelUserSubscriptionHandler = async () => {
    let cancelUserSubscriptionApiResp = await cancelUserSubscription({ Email: auth.email })
    if (cancelUserSubscriptionApiResp.Status) {
      Swal.fire({ icon: 'success', html: `${patient_subscription('subscription_cancelled_success_msg')}` })
      setShowCancelBtn(false)
    } else {
      Swal.fire({ icon: 'error', html: `${patient_subscription('subscription_cancel_' + cancelUserSubscriptionApiResp.Data.StatusCode)}` })
    }
  }

  const handleSubscribe = () => {
    setIsLoading(true)
  }

  let subsOptionsJsx = [];
  //console.log('subscriptionPlans', subscriptionPlans);

  //if(flgShowPlansConcise) {
    const fSelSubsPlanHandler = (e) => {
      const selPlanId = parseInt(e.target.value);//console.log('selPlan', selPlanId);
      selPlanId && setStSelSubsPlan(selPlanId);
    };
    const fCheckoutHandler = async () => {
      if(stSelSubsPlan) {
        let selPlanParams = subscriptionPlans.filter(sP => sP.subscription_plan == stSelSubsPlan);
        //console.log('sss', selPlanParams);
        checkout(selPlanParams[0]);
      } else { console.log('SubsPlanErr'); }
    };

    subscriptionPlans.forEach((sOpt) => {
      const plId = parseInt(sOpt.subscription_plan);
      if(plId !== 5) {
        const plTitle = sOpt.plan_name.toLowerCase() === '12 months plan' ?
          'Yearly Plan' : sOpt.plan_name;
        subsOptionsJsx.push(
          <div key={'subsPl_'+plId} className='txAL'>
            <input type="radio" autoComplete="off"
              className={true ? 'niuHRdo3 rdo' : 'niuHRdo2 rdo'}
              name="rdoSubsPlan" id={'rdoSubsPlan_'+plId} value={plId}
              onChange={fSelSubsPlanHandler} checked = {plId === stSelSubsPlan} />
            <label className="Hd2"
              htmlFor="rdoSubsPlan1">{plTitle} - ${sOpt.amount}</label>
            {/*<div>
              <small className="font-12 text-muted">/{sOpt?.description}</small>
            </div>*/}
          </div>
        );
      }
    });
  //}

  const checkout = async (plan) => {//console.log('asas', plan);
    Swal.fire({
      imageUrl: imgLogo,
      imageHeight: 100,
      html: `${patient_subscription('subscription_payment_redirect_msg')}`,
      showConfirmButton: false,
      showCancelButton: false,
    })
    try {
      const response = await axiosPrivate.post('createCheckoutSession', {
        Email: auth.email,
        SubscriptionPlan: plan.subscription_plan,
        SuccessUrl: APP_BASE_URL + '/patient/subscription',
        CancelUrl: APP_BASE_URL + '/patient/subscription',
        Metadata: { payment_type: 3, },
      })

      const { Status, Data: URL } = response.data

      if (Status) {
        window.location.href = URL
      } else {
        setIsLoading(false)
        Swal.fire({ icon: 'error', html: message })
      }
    } catch (error) {
      setIsLoading(false)
      Swal.fire({ icon: 'error', html: patient_subscription('subscription_error') })
    }
  }
  //---------------------------------------------------------

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <h4 className="page-title">{patient_subscription('subscription_payment')}</h4>
                <h5>
                  {patient_subscription('subscription_payment_msg')} {''} <Link to={'/patient/insurance'}>{patient_subscription('insurance')}</Link>.{' '}
                </h5>
              </div>
            </div>
          </div>

          {showSubPlan && (
            <div className="row justify-content-center">
              {flgShowPlansConcise ? (
                <div className="col-lg-4 xtBdr2">
                  <div className="card">
                    <div className="card-body">
                      <div className="pricingTable1 text-center">
                        <img src={`${AWS_BUCKET}/assets/images/widgets/p-5.svg`} alt="" className="" height="100" />
                        {<h6 className="title1 xpy-3 xmt-2 mb-0">
                          {/*item.plan_name*/}
                          <small className="text-muted">&nbsp;</small>
                        </h6>}
                        <ul className="list-unstyled pricing-content-2 pb-3">
                          <li className='txAL'>{patient_subscription('subscription_plan_desc_1')}</li>
                          <li className='txAL'>{patient_subscription('subscription_plan_desc_2')}</li>
                          <li className='txAL'>{patient_subscription('subscription_plan_desc_3')}</li>
                          <li className='txAL'>{patient_subscription('subscription_plan_desc_4')}</li>
                          <li className='txAL'>{patient_subscription('subscription_plan_desc_5')}</li>
                          {/*item?.PromotionalText ? <li>{item?.PromotionalText}</li> : <></>*/}
                        </ul>
                        <div className="text-center">
                          <h3 className="amount">
                            {/*${item.amount}
                            <small className="font-12 text-muted">/{item.description}</small>*/}
                          </h3>
                        </div>
                        <div className="xd-flex justify-content-center xtBdr2">
                          <div className="xbtn-group xniuHRdo" role="group" aria-label="Subscription Plans">
                            {subsOptionsJsx}
                          </div>
                          <div>
                            {true ? (
                              <button type="button"
                                className="btn btn-success btn-round waves-effect waves-light mt-3"
                                style={{
                                  padding: '14px 26px', fontSize: '14px',
                                  fontWeight: '700', letterSpacing: '0.32px',
                                  textTransform: 'uppercase',
                                }}
                                onClick={() => { fCheckoutHandler() }}
                              >{patient_subscription('subscribe_now')}</button>
                            ) : (
                              <Link className="pricingTable-signup mt-3 "
                                onClick={() => { fCheckoutHandler() }}
                              >
                                {patient_subscription('subscribe_now')}
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : subscriptionPlans.map((item) => (
                <div className="col-lg-4" key={item?.subscription_plan}>
                  <div className="card">
                    <div className="card-body">
                      <div className="pricingTable1 text-center">
                        <img src={`${AWS_BUCKET}/assets/images/widgets/p-5.svg`} alt="" className="" height="100" />
                        <h6 className="title1 py-3 mt-2 mb-0">
                          {item.plan_name}
                          <small className="text-muted"></small>
                        </h6>
                        <ul className="list-unstyled pricing-content-2 pb-3">
                          <li className='txAL'>{patient_subscription('subscription_plan_desc_1')}</li>
                          <li className='txAL'>{patient_subscription('subscription_plan_desc_2')}</li>
                          <li className='txAL'>{patient_subscription('subscription_plan_desc_3')}</li>
                          <li className='txAL'>{patient_subscription('subscription_plan_desc_4')}</li>
                          <li className='txAL'>{patient_subscription('subscription_plan_desc_5')}</li>
                          {item?.PromotionalText ? <li>{item?.PromotionalText}</li> : <></>}
                        </ul>
                        <div className="text-center">
                          <h3 className="amount">
                            ${item.amount}
                            <small className="font-12 text-muted">/{item.description}</small>
                          </h3>
                        </div>
                        <div className="d-flex justify-content-center">
                          <Link
                            className="pricingTable-signup mt-3 "
                            onClick={() => {
                              checkout(item)
                            }}
                          >
                            {patient_subscription('subscribe_now')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {!isLoading ? (
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="total-payment">
                      <h5 className="m-2">{patient_subscription('subscription')}</h5>
                      <table className="table mb-0">
                        <tbody>
                          <tr>
                            <td className="payment-title">{patient_subscription('start_date')}</td>
                            <td>{subs.subsStart ? moment(subs.subsStart).format('hh:mm a MMM DD, YYYY') : '-'}</td>
                          </tr>
                          <tr>
                            <td className="payment-title">{patient_subscription('end_date')}</td>
                            <td>{subs.subsEnd ? moment(subs.subsEnd).format('hh:mm a MMM DD, YYYY') : '-'}</td>
                          </tr>
                        </tbody>
                      </table>

                      {showCancelBtn &&
                        (disableCancelBtn?.status ? (
                          <div className={`d-flex flex-column flex-md-row float-right m-4`}>
                            <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }} overlay={<Tooltip id="tooltip-disabled">{disableCancelBtn?.message}</Tooltip>}>
                              <span className="d-inline-block">
                                <button
                                  className={`btn btn-round btn-outline-danger waves-effect waves-light mt-1 mt-md-0 ml-0 ml-md-1 ${disableCancelBtn?.status ? 'pointer-events-none' : ''}`}
                                  onClick={() =>
                                    Swal.fire({
                                      icon: 'question',
                                      html: `${patient_subscription('cancel_subscription_msg')}`,
                                      showConfirmButton: true,
                                      showCancelButton: true,
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        cancelUserSubscriptionHandler()
                                      }
                                    })
                                  }
                                  disabled={disableCancelBtn?.status}
                                >
                                  {patient_subscription('cancel_subscription')}
                                </button>
                              </span>
                            </OverlayTrigger>
                          </div>
                        ) : (
                          <div className={`d-flex flex-column flex-md-row float-right m-4`}>
                            <span className="d-inline-block">
                              <button
                                className={`btn btn-round btn-outline-danger waves-effect waves-light mt-1 mt-md-0 ml-0 ml-md-1 ${disableCancelBtn?.status ? 'pointer-events-none' : ''}`}
                                onClick={() =>
                                  Swal.fire({
                                    icon: 'question',
                                    html: `${patient_subscription('cancel_subscription_msg')}`,
                                    showConfirmButton: true,
                                    showCancelButton: true,
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      cancelUserSubscriptionHandler()
                                    }
                                  })
                                }
                                disabled={disableCancelBtn?.status}
                              >
                                {patient_subscription('cancel_subscription')}
                              </button>
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CardItem>
              <div className="d-flex justify-content-center">
                <RingLoading size={200} />
              </div>
            </CardItem>
          )}

          <div className="row-lg-12">
            {paymentHistory?.length > 0 ? (
              <TableCard headers={[patient_subscription('description'), patient_subscription('payment_time'), patient_subscription('amount')]}>
                {paymentHistory?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.amount > 0 ? (
                        <NavLink
                          onClick={async () => {
                            await axiosPrivate.post('getStripeReceipt', { Email: auth.email, ChargeID: item.trans_id }).then((res) => {
                              console.log(res)
                              const receipt_link = res.data.Data
                              Swal.fire({
                                html: `${patient_subscription('view_receipt')}`,
                                title: patient_subscription('patient_receipt'),
                                showConfirmButton: true,
                                showCancelButton: true,
                              }).then((response) => {
                                if (response.isConfirmed) {
                                  openInNewTab(receipt_link)
                                }
                              })
                            })
                          }}
                        >
                          {patient_subscription('subscription_' + item.status_code)}
                          {item.promo_code && `(${patient_subscription('coupon')} ${item.promo_code})`}
                        </NavLink>
                      ) : (
                        <>
                          {patient_subscription('subscription_' + item.status_code)}
                          {item.promo_code && `(${patient_subscription('coupon')} ${item.promo_code})`}
                        </>
                      )}
                    </td>
                    <td>{moment(item.payment_date_time).format('hh:mm a MMM DD, YYYY')}</td>
                    <td>
                      {item.amount > 0 ? '$' : '-'} {item.amount}
                    </td>
                  </tr>
                ))}
              </TableCard>
            ) : (
              <>
                <CardItem className={'col-lg-12'}>
                  {isLoadingPayments ? (
                    <div className="d-flex justify-content-center">
                      <RingLoading size={200} />
                    </div>
                  ) : (
                    patient_subscription('no_payment_history')
                  )}
                </CardItem>
              </>
            )}
          </div>
        </div>

        <Footer />
      </div>
    </div>
  )
}

function openInNewTab(url) {
  var win = window.open(url, '_blank')
  win.focus()
}

export default NewSubscription
